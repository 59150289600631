import React, { Component } from "react";
import { notification, Button, Table, Tag, Drawer } from "antd";
import moment from "moment";
import ajax from "../../../services/ajax";
import UserAction from "./UserAction";
import { urlPath } from "../../../services/urlPath";
import { USER_TYPE } from "../../../services/constants";
import SearchBox from "../../_common/SearchBox";

class UserComponent extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Name",
        width: 200,
        dataIndex: "name",
        key: "name",
        render: (text, record) => `${record.firstName} ${record.lastName}`,
      },
      {
        title: "Phone",
        width: 100,
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "City",
        width: 100,
        dataIndex: "city",
        key: "city",
        render: (text, record) => record.city && `${record.city.name}`,
      },
      {
        title: "Status",
        width: 100,
        dataIndex: "visible",
        key: "visible",
        render: (text) =>
          text ? (
            <Tag color={"#34b7e3"}>ACTIVE</Tag>
          ) : (
            <Tag color={"#ff443f"}>BLOCKED</Tag>
          ),
      },
      {
        title: "Last Seen",
        dataIndex: "onlineAt",
        key: "onlineAt",
        width: 200,
        render: (text) => moment(text).format("DD-MM-YYYY"),
      },
      {
        title: "Created on",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        render: (text) => moment(text).format("DD-MM-YYYY"),
      },
      {
        title: "Action",
        key: "operation",
        width: 250,
        render: (text, record) => (
          <Button
            onClick={() => {
              this.showUserDetails(record);
            }}
          >
            View
          </Button>
        ),
      },
    ];

    this.state = {
      list: [],
      viewUser: false,
      loading: true,
      selectedUser: {},
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }

  componentWillMount() {
    this.getUsers();
  }

  showUserDetails = (user) => {
    this.setState({
      viewUser: true,
      selectedUser: user,
    });
  };

  closeViewMode = () => {
    this.setState({
      viewUser: false,
      selectedUser: {},
    });
  };

  getUsers = () => {
    let { pagination } = this.state;
    ajax("get", urlPath.user, { page: pagination.current }).then(
      ({ result }) => {
        pagination.total = result.count;
        this.setState({
          viewUser: false,
          pagination: pagination,
          list: result.list,
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          viewUser: false,
          list: [],
          loading: false,
        });
      }
    );
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
      this.getUsers
    );
  };

  handleCallback = (searchList) => {
    this.setState({ list: searchList });
  };
  render() {
    const { list, loading, pagination, viewUser, selectedUser } = this.state;
    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>Users</div>
        </div>
        <SearchBox
          pagination={pagination}
          userType={USER_TYPE.CUSTOMER}
          searchBoxCallback={this.handleCallback}
        />
        <Table
          loading={loading}
          rowKey={"id"}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />

        {viewUser && (
          <Drawer width={520} visible={viewUser} onClose={this.closeViewMode}>
            <UserAction user={selectedUser} updateUserList={this.getUsers} />
          </Drawer>
        )}
      </div>
    );
  }
}

export default UserComponent;
