import React, {Component} from 'react';
import {Button, Row, Col, Divider, notification} from 'antd';
import DescriptionItem from '../../_common/DescriptionItem';
import moment from 'moment';
import ajax from '../../../services/ajax';
import {fileBaseURL} from '../../../config';
import { urlPath } from '../../../services/urlPath';

class PostAction extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleDelete = () => {

    this.setState({
      loading: true
    });

    ajax('delete', `${urlPath.post}/${this.props.post.id}`).then((res) => {
      this.setState({
        loading: false
      });
      notification.success({
        message: 'Success',
        description: 'Post Deleted!'
      });
      this.props.updatePostList();
    }, ({msg}) => {
      this.setState({
        loading: false
      });
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  render() {

    const { loading } = this.state;
    const { post } = this.props;


    let media = '';

    switch (post.type) {
      case 'PHOTO':
        media = <img src={`${fileBaseURL}/${post.media}`} alt={'image'}/>;
        break;
      case 'VIDEO':
        media = <video src={`${fileBaseURL}/${post.media}`} controls/>;
        break;
      default:

    }

    return (
      <div>
        <h2>Post Details</h2>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={'Business'}
              content={post.business && post.business.name}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'Likes'} content={post.totalLikes} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title={'Type'} content={post.type} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'Time'} content={moment(post.createdAt).format('DD MMM YYYY HH:SS')} />
          </Col>
        </Row>
        <Divider />
        {
          media
        }
        <p className={'post-text'}>{post.text}</p>

        <div className={'btn-grp opposite drawer-footer'}>
          <Button
            size={'large'}
            onClick={this.handleDelete}
            type={'danger'}
            loading={loading}
          >Delete Post</Button>
        </div>
      </div>
    )
  }
}

export default PostAction