import React, {Component} from 'react';
import {DatePicker, Card, notification} from 'antd';
import moment from 'moment';
import ajax from '../../../../../services/ajax';
import {Line} from "react-chartjs-2";
import { color } from "../../../../../config";
import { urlLinkPath } from '../../../../../services/urlPath';

const { RangePicker } = DatePicker;

const options = {
  responsive: true,
  animation: false,
  elements: {
    line: {
      tension: 0
    }
  },
  tooltips: {
    mode: 'index',
      intersect: false,
  },
  hover: {
    mode: 'nearest',
      intersect: true
  },
  scales: {
    xAxes: [{
      ticks: {
        padding: 12,
        maxRotation: 0,
        minRotation: 0,
      },
      gridLines: {
        color: "#F5F5F5",
        drawBorder: false,
        drawTicks: false
      }
    }],
    yAxes: [{
      type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
      display: true,
      position: 'left',
      ticks: {
        padding: 12,
        precision: 0
      },
      gridLines: {
        color: "#F5F5F5",
        drawBorder: false,
        drawTicks: false
      }

    }],
  },
  plugins: {
    datalabels: false
  }
};

const getData = (labels, dataset1, dataset2, dataset3) => {
  return {
    labels: labels,
    datasets: [{
      label: 'SUCCESS',
      backgroundColor: color.primary,
      borderColor: color.primary,
      data: dataset1,
      fill: false,
    }, {
      label: 'FAILED',
      fill: false,
      backgroundColor: color.danger,
      borderColor: color.danger,
      data: dataset2,
    }, {
      label: 'CANCELLED',
      fill: false,
      backgroundColor: color.disabled,
      borderColor: color.disabled,
      data: dataset3,
    }]
  }
};

const getSpecificData = (labels, dataset1, dataset2, dataset3) => {
  return {
    labels: labels,
    datasets: [{
      label: 'APP',
      backgroundColor: color.accentDark,
      borderColor: color.accentDark,
      data: dataset1,
      fill: false,
    }, {
      label: 'REFERRAL',
      fill: false,
      backgroundColor: color.accent,
      borderColor: color.accent,
      data: dataset2,
    }, {
      label: 'RENEWAL',
      fill: false,
      backgroundColor: color.success,
      borderColor: color.success,
      data: dataset3,
    }]
  }
};

class Txn extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      dataSuccess: [],
      dataFailure: [],
      labels: [],
      dateRange: [moment().subtract(6,'d'), moment()]
    }
  }


  componentWillMount() {
    this.getTxns();
  }

  dateRangeChange = (range) => {
    if(!(range[0] && range[1])){
      range = [moment().subtract(7,'days'), moment()];
    }
    this.setState({
      dateRange: range
    }, this.getTxns)
  };

  getTxns = () => {

    const { dateRange } = this.state;

    const currDate = dateRange[0].startOf('day').clone();
    const lastDate = dateRange[1].startOf('day').clone();

    let dates = [];
    let labels = [];
    let dataSuccess = [];
    let dataFailure = [];
    let dataCancelled = [];

    let viaAppData = [];
    let viaReferralData = [];
    let renewalData = [];
    do{
      labels.push(currDate.clone().format('DD MMM'));
      dates.push(currDate.clone().format('YYYY-MM-DD'));
      dataSuccess.push(0);
      dataFailure.push(0);
      dataCancelled.push(0);
      viaAppData.push(0);
      viaReferralData.push(0);
      renewalData.push(0);
    }while(currDate.add(1, 'days').diff(lastDate) <= 0);

    if(labels.length > 31){
      notification.warn({
        message: 'Range Warning',
        description: 'Please select date range less than or equal to 31 days!'
      });
      return;
    }

    const params = {
      startDate: dateRange[0].format('YYYY-MM-DD'),
      endDate: dateRange[1].format('YYYY-MM-DD'),
    };

    ajax('post', urlLinkPath.dashboardTxn, params).then(({result}) => {
      const { success, failure, cancelled, viaApp, viaReferral, renewal} = result;
      for(let i = 0; i < success.length; i++){
        const data = success[i];
        const index = dates.indexOf(data.txnDate);
        if(index !== -1 ){
          dataSuccess[index] = data.count;
        }
      }
      for(let i = 0; i < failure.length; i++){
        const data = failure[i];
        const index = dates.indexOf(data.txnDate);
        if(index !== -1 ){
          dataFailure[index] = data.count;
        }
      }
      for(let i = 0; i < cancelled.length; i++){
        const data = cancelled[i];
        const index = dates.indexOf(data.txnDate);
        if(index !== -1 ){
          dataCancelled[index] = data.count;
        }
      }

      for(let i = 0; i < viaApp.length; i++){
        const data = viaApp[i];
        const index = dates.indexOf(data.txnDate);
        if(index !== -1 ){
          viaAppData[index] = data.count;
        }
      }

      for(let i = 0; i < viaReferral.length; i++){
        const data = viaReferral[i];
        const index = dates.indexOf(data.txnDate);
        if(index !== -1 ){
          viaReferralData[index] = data.count;
        }
      }

      for(let i = 0; i < renewal.length; i++){
        const data = renewal[i];
        const index = dates.indexOf(data.txnDate);
        if(index !== -1 ){
          renewalData[index] = data.count;
        }
      }

      this.setState({
        loading: false,
        dataSuccess: dataSuccess,
        dataFailure: dataFailure,
        dataCancelled: dataCancelled,
        viaAppData: viaAppData,
        viaReferralData: viaReferralData,
        renewalData: renewalData,
        labels: labels,
      })
    }, (err) => {
      notification.error({
        message: 'Error',
        description: err && err.msg
      });
    })
  }

  render() {

    const { dateRange, labels, dataSuccess, dataFailure, dataCancelled, viaAppData, viaReferralData, renewalData } = this.state;

    const data = getData(labels,dataSuccess,dataFailure, dataCancelled);
    const specificData = getSpecificData(labels,viaAppData,viaReferralData, renewalData);

    return (
      <div className={'txn'} >
        <Card className={'txn-card'} title={'Visual Report'} extra={(
          <RangePicker
            value={dateRange}
            format={'DD/MM/YYYY'}
            separator={'to'}
            onChange={this.dateRangeChange}
          />
        )}>
          <h2>All Transactions</h2>
          <Line key={'allTxn'} width={1200} height={420} data={data} options={options}/>

          <h2>Specific Transactions</h2>
          <Line key={'specificTxn'} width={1200} height={420} data={specificData} options={options}/>

        </Card>
      </div>
    )
  }
}

export default Txn;