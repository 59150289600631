import React, { useState } from "react";
import {
  MAX_FT_HIERARCHY_LEVEL,
  MIN_FT_HIERARCHY_LEVEL,
} from "../../../../services/constants";
const FtTreeHOC = (WrapperComponent) => {
  return function Comp(props) {
    const { getDescendants } = props;

    const [descendants, setDescendants] = useState([]);
    const [showNoData, setShowNoData] = useState(false);
    const [reachedMaxLevel, setReachedMaxLevel] = useState(false);
    const onExpand = async (expandedKeys) => {
      if (expandedKeys.length == 0) return;
      const ancestorId = expandedKeys[0];
      const { user, descendent } = await getDescendants(ancestorId);
      const isUserMaxLevel =
        descendent.every((child) => {
          return child.hierarchyLevel == MIN_FT_HIERARCHY_LEVEL;
        }) && user.hierarchyLevel == MAX_FT_HIERARCHY_LEVEL;
      setReachedMaxLevel(isUserMaxLevel);
      if (!descendent || descendent.length == 0 || isUserMaxLevel) {
        setShowNoData(true);
        return;
      }
      setDescendants(descendent);
    };

    const customProps = {
      ...props,
      descendants,
      onExpand,
      showNoData,
      reachedMaxLevel,
    };
    return <WrapperComponent {...customProps} />;
  };
};

export default FtTreeHOC;
