export const urlPath = {
    auth: '/auth',
    admin:'/admin',
    user:'/user',
    ad:'/ad',
    dashboard:'/dashboard',
    marketDashboard:'/market-dashboard',
    businessCategory:'/business-category',
    masterBusinessCategory: '/master-business-category',
    addBusiness:'/add-business',
    addFtMember:'/add-ft-member',
    topupPlan:'/topup-plan',
    walletTransfer:'/wallet-transfer',
    configuration:'/configuration',
    subscriptionPlan:'/subscription-plan',
    counterEnlist:'/counter-enlist',
    shoppingLink:'/shopping-link',
    earningLink:'/earning-link',
    service:'/service',
    business: '/business',
    advisor:'/advisor',
    chiefPromoter: '/chief-promoter',
    chiefCoordinator:'/chief-coordinator',
    seniorCoordinator:'/senior-coordinator',
    coordinator:'/coordinator',
    executives:'/executives',
    marketingExec:'/marketing-exec',
    marketReport:'/market-report',
    module:'/module',
    cityState:'/city-state',
    city:'/city',
    visibility:'/visibility',
    list:'/list',
    log:'/log',
    referral:'/referral',
    pay:'/pay',
    payouts:'/payouts',
    appVersion:'/app-version',
    transaction:'/transaction',
    role:'/role',
    suggestion:'/suggestion',
    review:'/review',
    pushNotification:'/push-notification',
    post:'/post',
    referralScheme:'/referral-scheme',
    ftMemberLevelReward: '/ft-member-level-reward',
    adPricing:'/ad-pricing',
    ftPayUser: '/ft-pay-user', 
    inquiries:'/inquiries',
    ftChannelLevelReward: '/ft-channel-level-reward',
    transactionNotice:'/termination-notice',
    request: '/user-request',
    invites:'/invites',
    ftChannelTree:'ft-channel-tree'
  };

  export const urlLinkPath = {
    login: `${urlPath.auth}/login`,
    resetPassword: `${urlPath.auth}/reset-password`,
    verifyOtp: `${urlPath.auth}/verify-otp`,
    adminVisibility:`${urlPath.admin}${urlPath.visibility}`,
    adminList:`${urlPath.admin}${urlPath.list}`,
    adminListByRoles:`${urlPath.admin}/list-by-roles`,
    userVisibility:`${urlPath.user}${urlPath.visibility}`,
    userMembers:`${urlPath.user}/members`,
    referrers:`${urlPath.user}/referrers`,
    registerBusiness: `${urlPath.addBusiness}/register-business`,
    updateCustomer:`${urlPath.addBusiness}/update-customer`,
    processPayment:`${urlPath.addBusiness}/process-payment`,
    verifyBusinessOtp:`${urlPath.addBusiness}/verify-otp`,
    sendSms:`${urlPath.addFtMember}/send-sms`,
    adBusinesses:`${urlPath.ad}/businesses`,
    adState:`${urlPath.ad}/state`,
    adCity:`${urlPath.ad}${urlPath.city}`,
    adList:`${urlPath.ad}${urlPath.list}`,
    marketDashboardReferrals:`${urlPath.marketDashboard}/referrals`,
    dashboardTxn:`${urlPath.dashboard}/txn`,
    topupPlanVisibility:`${urlPath.topupPlan} ${urlPath.visibility}`,
    topupPlanList:`${urlPath.topupPlan}${urlPath.list}`,
    shoppingLinkVisibility:`${urlPath.shoppingLink}${urlPath.visibility}`,
    earningLinkVisibility:`${urlPath.earningLink}${urlPath.visibility}`,
    serviceVisibility:`${urlPath.service}${urlPath.visibility}`,
    businessList:`${urlPath.business}${urlPath.list}`,
    businessVisibility:`${urlPath.business}${urlPath.visibility}`,
    businessCategorySearch:`${urlPath.businessCategory}/search`,
    businessCategoryFeatured:`${urlPath.businessCategory}/featured`,
    businessCategoryVisibility:`${urlPath.businessCategory}${urlPath.visibility}`,
    counterEnlistApprove:`${urlPath.counterEnlist}/approve`,
    counterEnlistReject:`${urlPath.counterEnlist}/reject`,
    subscriptionPlanVisibility:`${urlPath.subscriptionPlan}${urlPath.visibility}`,
    subscriptionPlanList:`${urlPath.subscriptionPlan}${urlPath.list}`,
    subscriptionPlanStates:`${urlPath.subscriptionPlan}/states`,
    moduleList:`${urlPath.module}${urlPath.list}`,
    advisorVisibility:`${urlPath.advisor}${urlPath.visibility}`,
    advisorList:`${urlPath.advisor}${urlPath.list}`,
    advisorChiefPromoter:`${urlPath.advisor}${urlPath.chiefPromoter}`,
    advisorChiefCoordinator:`${urlPath.advisor}${urlPath.chiefCoordinator}`,
    advisorSeniorCoordinator:`${urlPath.advisor}${urlPath.seniorCoordinator}`,
    advisorCoordinator:`${urlPath.advisor}${urlPath.coordinator}`,
    advisorExecutives:`${urlPath.advisor}${urlPath.executives}`,
    chiefPromoterVisibility:`${urlPath.chiefPromoter}${urlPath.visibility}`,
    chiefPromoterList:`${urlPath.chiefPromoter}${urlPath.list}`,
    chiefPromoterChiefCoordinator:`${urlPath.chiefPromoter}${urlPath.chiefCoordinator}`,
    chiefPromoterSeniorCoordinator:`${urlPath.chiefPromoter}${urlPath.seniorCoordinator}`,
    chiefPromoterCoordinator:`${urlPath.chiefPromoter}${urlPath.coordinator}`,
    chiefPromoterExecutives:`${urlPath.chiefPromoter}${urlPath.executives}`,
    chiefCoordinatorVisibility:`${urlPath.chiefCoordinator}${urlPath.visibility}`,
    chiefCoordinatorList:`${urlPath.chiefCoordinator}${urlPath.list}`,
    chiefCoordinatorSeniorCoordinator:`${urlPath.chiefCoordinator}${urlPath.seniorCoordinator}`,
    chiefCoordinatorCoordinator:`${urlPath.chiefCoordinator}${urlPath.coordinator}`,
    chiefCoordinatorExecutives:`${urlPath.chiefCoordinator}${urlPath.executives}`,
    seniorCoordinatorVisibility:`${urlPath.seniorCoordinator}${urlPath.visibility}`,
    seniorCoordinatorList:`${urlPath.seniorCoordinator}${urlPath.list}`,
    seniorCoordinatorCoordinator:`${urlPath.seniorCoordinator}${urlPath.coordinator}`,
    seniorCoordinatorExecutives:`${urlPath.seniorCoordinator}${urlPath.executives}`,
    coordinatorVisibility:`${urlPath.coordinator}${urlPath.visibility}`,
    coordinatorList:`${urlPath.coordinator}${urlPath.list}`,
    coordinatorExecutives:`${urlPath.coordinator}${urlPath.executives}`,
    payFundAccount:`${urlPath.pay}/fund-accounts`,
    paylinkBank:`${urlPath.pay}/link-bank`,
    marketingExecVisibility:`${urlPath.marketingExec}${urlPath.visibility}`,
    marketingExecList:`${urlPath.marketingExec}${urlPath.list}`,
    appVersionMakeLive:`${urlPath.appVersion}/make-live`,
    roleList:`${urlPath.role}${urlPath.list}`,
    pushNotificationPlain:`${urlPath.pushNotification}/plain`,
    financialReport:`${urlPath.transaction}/financial-report`,
    invoiceReport:`${urlPath.payouts}/invoice-clearence`,
    creditWalletAmount:`${urlPath.transaction}/credit-wallet`,
    referralSchemesList:`${urlPath.referralScheme}/referral-list`,
    referrerPayouts:`${urlPath.payouts}/referrer-payouts`,
    sendPayouts:`${urlPath.payouts}/send-payouts`,
    statewiseAdPrice:`${urlPath.adPricing}/state-ad-price`,
    ftPayUserList:`${urlPath.user}/ft-pay-user`,
    searchUsers:`${urlPath.user}/search`,
    sendResponse:`${urlPath.inquiries}/send-response`,
    channelPayouts:`${urlPath.payouts}/channel-payouts`,
    payoutUpdate:`${urlPath.payouts}/payout-update`,
    transactionNoticeList:`${urlPath.transactionNotice}${urlPath.list}`,
    stopTermination:`${urlPath.transactionNotice}/stop-termination`,
    searchInvite:`${urlPath.invites}/search`,
    ftChannelDashboard:`${urlPath.dashboard}/ft-channel-dashboard`,
    ftChannelRes:`${urlPath.dashboard}/ft-channel-registrations`,
    ftChannelUserChildren:`${urlPath.ftChannelTree}/children`,
    ftChannelUserDescendent:`${urlPath.ftChannelTree}/descendent`,
    activateMember:`${urlPath.user}/activate-member`
  };