import React, {Component} from 'react';
import {Form, Input, Button, notification, Select} from 'antd';

import ajax from '../../../../services/ajax';
import { urlLinkPath, urlPath } from '../../../../services/urlPath';

class CategoryAction extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      DELETE_KEY: ''
    }
  }

  handleSuccess = (msg) => {
    this.setState({
      loading: false
    });
    notification.success(msg);
    this.props.updateCategoryList();
  }

  handleError = (msg) => {
    this.setState({
      loading: false
    });
    notification.error({
      message: 'Error',
      description: msg
    });
  }

  submitForm = (ev) => {
    ev.preventDefault();
    const action = {
      ADD: this.addCategory,
      EDIT: this.updateCategory
    }[this.props.mode];
    action();
  };

  addCategory = () => {
    this.props.form.validateFields((err, values) => {
      if(err) return;
      this.setState({
        loading: true
      });
      ajax('post', urlPath.businessCategory,values).then((res) => {
        this.handleSuccess({
          message: 'Success',
          description: 'Business Category added!'
        })
      }, ({msg}) => {
        this.handleError(msg);
      })
    })
  }

  updateCategory = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;

      this.setState({
        loading: true
      });
      values.id = this.props.category.id;
      ajax('put',urlPath.businessCategory, values).then((res) => {
        this.handleSuccess({
          message: 'Success',
          description: 'Business Category updated!'
        });
      }, ({msg}) => {
        this.handleError(msg);
      })
    });
  };

  handleFeatured = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.category.id,
      status: !this.props.category.featured
    };
    ajax('post', urlLinkPath.businessCategoryFeatured,params).then((res) => {
      this.handleSuccess({
        message: 'Success',
        description: 'Admin status updated!'
      });
    }, ({msg}) => {
      this.handleError(msg);
    })
  }

  handleVisibility = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.category.id,
      status: !this.props.category.visible
    };
    ajax('post', urlLinkPath.businessCategoryVisibility,params).then((res) => {
      this.handleSuccess({
        message: 'Success',
        description: 'Admin status updated!'
      });
    }, ({msg}) => {
      this.handleError(msg);
    })
  }

  render() {

    const { loading } = this.state;
    const { category, masterCategories, mode } = this.props;
    const { getFieldDecorator } = this.props.form;

    const masterBusinessCategory = masterCategories.map((masterCategory, index) => {
      return (
        <Select.Option key={index} value={masterCategory.id}>{masterCategory.name}</Select.Option>
      )
    });

    return (
      <Form onSubmit={this.submitForm}>
        <h2>Business Category Details</h2>
        <Form.Item label={'Name'} colon={false}>
          {
            getFieldDecorator('name', {
              initialValue: category && category.name,
              rules: [{
                required: true,
                message: 'Please input name for Business Category!'
              }]
            })(<Input size={'large'}/>)
          }
        </Form.Item>
        <Form.Item label={'Master Business Category'} colon={false}>
          {
            getFieldDecorator('masterBusinessCategoryId', {
              initialValue: category && category.masterBusinessCategory && category.masterBusinessCategory.id,
              rules: [{
                required: true,
                message: 'Please select Master Business Category!'
              }]
            })(
              <Select size={'large'}>
                {masterBusinessCategory}
              </Select>
            )
          }
        </Form.Item>
        <div>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !=='ADD'}
            loading={loading}
          >
            Add Business Category
          </Button>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !== 'EDIT'}
          >
            Update Business Category
          </Button>
        </div>

        <div className={'btn-grp opposite drawer-footer'} hidden={mode==='ADD'}>
          <Button
            size={'large'}
            loading={loading}
            type={category && category.featured ? 'danger': 'primary'}
            onClick={this.handleFeatured}
          >
            {category && category.featured ? 'Remove Featured' : 'Mark Featured'}
          </Button>
          <Button
            size={'large'}
            loading={loading}
            type={category && category.visible ? 'danger': 'primary'}
            onClick={this.handleVisibility}
          >
            {category && category.visible ? 'Block Category' : 'Activate Category'}
          </Button>
        </div>
      </Form>
    )
  }
}

export default Form.create()(CategoryAction)