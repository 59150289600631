import React, {Component} from 'react';
import {notification, Button, Table, Tag, Drawer} from 'antd';
import moment from 'moment';
import ajax from '../../../services/ajax';
import {color} from '../../../config';
import { urlPath } from '../../../services/urlPath';
// import ReferralAction from "./ReferralAction";

class AdminReferralComponent extends Component{
  constructor(props){
    super(props);

    this.columns = [
      {
        title: 'Admin',
        dataIndex: 'admin',
        key: 'admin',
        render: (text, record) => record.admin && `${record.admin.firstName} ${record.admin.lastName}`
      },
      {
        title: 'Business',
        dataIndex: 'business',
        key: 'business',
        render: (text, record) => record.business && record.business.name
      },
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        render: (text, record) => record.user && `${record.user.firstName} ${record.user.lastName}`
      },
      {
        title: 'Type',
        dataIndex: 'referralType',
        key: 'referralType',
        render: (text) => ({
          DASHBOARD: <Tag color={color.primary}>DASHBOARD</Tag>,
          REFERRAL_CODE: <Tag color={color.accentDark}>REFERRAL CODE</Tag>,
        }[text])
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: 'Created on',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) =>  moment(text).format('DD-MM-YYYY')
      },
      // {
      //   title: 'Action',
      //   key: 'operation',
      //   render: (text, record) => <Button onClick={() => { this.showReferralDetails(record)}} >View</Button>,
      // },
    ];

    this.state = {
      list: [],
      loading: true,
      viewReferral: false,
      selectedReferral: {},
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    };
  }

  componentWillMount() {
    this.getReferrals();
  }

  showReferralDetails = (business) => {
    this.setState({
      viewReferral: true,
      selectedReferral: business
    })
  };

  closeViewMode = () => {
    this.setState({
      viewReferral: false,
      selectedReferral: {}
    })
  }

  getReferrals = () => {
    let { pagination } = this.state;
    ajax('get', urlPath.referral,{ page: pagination.current}).then(({result}) => {
      pagination.total = result.count;
      this.setState({
        viewReferral: false,
        pagination: pagination,
        list: result.list,
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        viewReferral: false,
        list: [],
        loading: false
      })
    })
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getReferrals)
  };

  render(){

    const {list, loading, pagination, viewReferral, selectedReferral} = this.state;

    return(
      <div>
        <div className={'header'}>
          <div className={'page-title'}>Referral</div>
        </div>
        <Table
          rowKey={'id'}
          loading={loading}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />

        {
          viewReferral && (
            <Drawer
              width={520}
              visible={viewReferral}
              onClose={this.closeViewMode}
            >
              {/*<ReferralAction business={selectedReferral} updateReferralList={this.getReferrales} />*/}
            </Drawer>
          )
        }
      </div>
    )
  }
}

export default AdminReferralComponent;

