import React, {Component} from 'react';
import {notification, Button, Table, Tag, Drawer, Icon} from 'antd';
import moment from 'moment';
import ajax from '../../../services/ajax';
import SuggestionAction from "./SuggestionAction";
import {color} from "../../../config";
import { urlPath } from '../../../services/urlPath';

class SuggestionComponent extends Component{
  constructor(props){
    super(props);

    this.columns = [
      {
        title: 'Business',
        dataIndex: 'business',
        key: 'business',
        render: (text, {business}) => business?.name??text
      },
      {
        title: 'Customer',
        dataIndex: 'customer',
        key: 'customer',
        render: (text, {user}) => (
          user.business
            ? <><Icon type={'shop'} theme={'filled'} style={{fontSize: 16,color: color.accent}}/> {user.business.name}</>
            : `${user?.firstName??text} ${user?.lastName??text}`
        )
      },
      {
        title: 'Suggestion',
        dataIndex: 'message',
        key: 'message',
      },
      {
        title: 'Reply',
        dataIndex: 'reply',
        key: 'reply',
      },
      {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) =>  moment(text).format('DD-MM-YYYY HH:mm')
      },
      {
        title: 'Replied At',
        dataIndex: 'repliedAt',
        key: 'repliedAt',
        render: (text) =>  text ? moment(text).format('DD-MM-YYYY HH:mm') : '--'
      },
      {
        title: 'Action',
        key: 'operation',
        render: (text, record) => <Button onClick={() => { this.showSuggestionDetails(record)}} >View</Button>,
      },
    ];

    this.state = {
      list: [],
      loading: true,
      viewSuggestion: false,
      selectedSuggestion: {},
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    };
  }

  componentWillMount() {
    this.getSuggestions();
  }

  showSuggestionDetails = (suggestion) => {
    this.setState({
      viewSuggestion: true,
      selectedSuggestion: suggestion
    })
  };

  closeViewMode = () => {
    this.setState({
      viewSuggestion: false,
      selectedSuggestion: {}
    })
  }

  getSuggestions = () => {
    let { pagination } = this.state;
    ajax('get', urlPath.suggestion,{ page: pagination.current}).then(({result}) => {
      pagination.total = result.count;
      this.setState({
        viewSuggestion: false,
        pagination: pagination,
        list: result.list,
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        viewSuggestion: false,
        list: [],
        loading: false
      })
    })
  }
  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getSuggestions)
  };

  render(){

    const {list, loading, pagination, viewSuggestion, selectedSuggestion} = this.state;

    return(
      <div>
        <div className={'header'}>
          <div className={'page-title'}>Suggestions</div>
        </div>
        <Table
          rowKey={'id'}
          loading={loading}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />

        {
          viewSuggestion && (
            <Drawer
              width={520}
              visible={viewSuggestion}
              onClose={this.closeViewMode}
            >
              <SuggestionAction suggestion={selectedSuggestion} />
            </Drawer>
          )
        }
      </div>
    )
  }
}

export default SuggestionComponent;
