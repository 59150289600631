import React, { Component } from "react";
import { Form, Input, Button, notification } from "antd";
import ajax from "../../../services/ajax";
import { urlPath, urlLinkPath } from "../../../services/urlPath";

class InquiryAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleSuccess = (msg) => {
    this.setState({
      loading: false,
    });
    notification.success(msg);
  };

  handleError = (msg) => {
    this.setState({
      loading: false,
    });
    notification.error({
      message: "Error",
      description: msg,
    });
  };

  submitForm = (ev) => {
    ev.preventDefault();
    this.sendResponse();
  };

  sendResponse = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true,
      });
       const params={
        enquiryId:this.props.inquiry.id,
        enquiryResponse:values.response
       };
      ajax("post", urlLinkPath.sendResponse, params).then(
        (res) => {
          this.handleSuccess({
            message: "Success",
            description: "Response sent!",
          });
          this.props.onClose();
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );

      
    });
  };

  render() {
    const { loading } = this.state;
    const {inquiry } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.submitForm} className="form">
        <h2>Inquiry</h2>
        <Form.Item label={"Name"} colon={false}>
          {getFieldDecorator("name", {
            initialValue: inquiry && inquiry.name,
          })(<Input size={"large"} disabled={true} />)}
        </Form.Item>
        <Form.Item label={"Phone"} colon={false}>
          {getFieldDecorator("phone", {
            initialValue: inquiry && inquiry.phone,
          })(<Input size={"large"} disabled={true} />)}
        </Form.Item>
        <Form.Item label={"Email Id"} colon={false}>
          {getFieldDecorator("emailId", {
            initialValue: inquiry && inquiry.emailId,
          })(<Input size={"large"} disabled={true} />)}
        </Form.Item>
        <Form.Item label={"Enquiry"} colon={false}>
          {getFieldDecorator("enquiry", {
            initialValue: inquiry && inquiry.enquiry,
          })(
            <Input.TextArea
              rows={4}
              maxLength={6}
              size={"large"}
              disabled={true}
            />
          )}
        </Form.Item>
        <Form.Item label={"Response"} colon={false}>
          {getFieldDecorator("response", {
            initialValue: '',
          })(
            <Input.TextArea
              rows={4}
              size={"large"}
            />
          )}
        </Form.Item>

        <div>
          <Button htmlType={"submit"} size={"large"} type={"default"}>
            Send Response
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create()(InquiryAction);
