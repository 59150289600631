import React, {Component} from 'react';
import {Button, Avatar, Badge, Row, Col, Icon, Divider, notification, Popconfirm} from 'antd';
import DescriptionItem from '../../_common/DescriptionItem';
import moment from 'moment';
import ajax from '../../../services/ajax';
import {color, fileBaseURL} from '../../../config';
import { urlLinkPath } from '../../../services/urlPath';

class CounterEnlistmentAction extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  approve = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.counterEnlist.id,
    };
    ajax('post', urlLinkPath.counterEnlistApprove,params).then((res) => {
      this.setState({
        loading: false
      });
      notification.success({
        message: 'Success',
        description: 'Counter Enlistment Approved!'
      });
      this.props.updateBusinessList();
    }, ({msg}) => {
      this.setState({
        loading: false
      });
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  reject = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.counterEnlist.id,
      rejectReason: "Please contact support!"
    };
    ajax('post', urlLinkPath.reject,params).then((res) => {
      this.setState({
        loading: false
      });
      notification.success({
        message: 'Success',
        description: 'Counter Enlistment Rejected!'
      });
      this.props.updateBusinessList();
    }, ({msg}) => {
      this.setState({
        loading: false
      });
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  render() {

    const { loading } = this.state;
    const { counterEnlist } = this.props;
    return (
      <div>
        <Row>
          {<Col span={3}>
            <Avatar icon={'user'} size={'large'} src={`${fileBaseURL}/user/${counterEnlist.business.user.id}.jpg`}/>
          </Col> }
          <Col span={21}>
            <div className={'drawer-title'}>
              <h2>{counterEnlist.business.name}</h2>
              <p>
                {counterEnlist.business.phone} {' '}
                { counterEnlist.business.verified
                  ? <Icon type="check-circle" theme={'filled'} style={{color: color.primary}}/>
                  : <Icon type="clock-circle" theme={'filled'} style={{color: color.accentDark}}/>
                }
              </p>
            </div>
          </Col>
        </Row>

        <Divider/>
        <Row>
          <Col span={12}>
            <DescriptionItem title={'Email'} content={counterEnlist.business.emailId} />
          </Col>

        </Row>
        {/* <Row>
          <Col span={12}>
            <DescriptionItem title={'City'} content={user.city && user.city.name} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'State'} content={user.city && user.city.stateName} />
          </Col>
        </Row> */}
        <Row>
          <Col span={12}>
            <DescriptionItem title={'A/C Created'} content={moment(counterEnlist.createdAt).format('DD MMM YYYY HH:SS')} />
          </Col>
        </Row>
        <Divider/>

<img src={`${fileBaseURL}/${counterEnlist.enlistImage}`} alt={'image'}/>

<Divider/>

        <div className={'btn-grp opposite drawer-footer'}>
          {
            counterEnlist.isApproved !== true && (
              <Button
              size={'large'}
              onClick={this.approve}
              type={'primary'}
              loading={loading}
            >
              Approve
            </Button>
            )
          }
          {
            counterEnlist.isRejected !== true && (
              <Button
                size={'large'}
                onClick={this.reject}
                type={'danger'}
                loading={loading}
              >
                Reject
              </Button>
            )
          }
        </div>
      </div>
    )
  }
}

export default CounterEnlistmentAction