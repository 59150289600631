import React, {Component} from 'react';
import {Steps, Card} from "antd";
import VerifyNumber from "./subcomponents/VerifyNumber";
import CustomerDetail from "./subcomponents/CustomerDetails";
import BusinessDetails from "./subcomponents/BusinessDetails";
import SubscriptionPlan from "./subcomponents/SubscriptionPlan";
import CompleteProcess from "./subcomponents/CompleteProcess";

class AddBusinessComponent extends Component{

  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      user: null,
      business: null,
      businessCategory: null,
      transaction: null
    }
  }

  getPhoneNumber = (user) => {
    this.setState({
      step: 1,
      user: user,
    })
  };

  changePhoneNumber = () => {
    this.setState({
      step: 0
    })
  };

  getCustomerDetails = (user) => {
    this.setState({
      step: 2,
      user: user
    })
  };

  changeCustomerDetails = () => {
    this.setState({
      step: 1
    })
  };

  getBusinessDetails = (business, businessCategory) => {
    this.setState({
      step: 3,
      business: business,
      businessCategory: businessCategory
    })
  };

  changeBusinessDetails = () => {
    this.setState({
      step: 2
    })
  };

  getTxnDetails = (transaction) => {
    this.setState({
      step: 4,
      transaction: transaction
    })
  };

  completeProcess = () => {

  };

  render(){

    const { step, user, business, businessCategory, transaction } = this.state;
    return(
      <div className={'add-business'}>
        <div className={'header'}>
          <div className={'page-title'}>Add Business</div>
          <div className={'step-block'}>
            <Steps progressDot current={step}>
              <Steps.Step title='1/5' description='Verify Number'/>
              <Steps.Step title='2/5' description='Customer Details'/>
              <Steps.Step title='3/5' description='Business Details'/>
              <Steps.Step title='4/5' description='Subscription Plan'/>
              <Steps.Step title='5/5' description='Finish'/>
            </Steps>
          </div>
          <Card>
            {
              {
                0: (
                  <VerifyNumber
                    user={user}
                    nextStep={this.getPhoneNumber}
                  />
                ),
                1: (
                  <CustomerDetail
                    user={user}
                    goBack={this.changePhoneNumber}
                    nextStep={this.getCustomerDetails}
                  />
                ),
                2: (
                  <BusinessDetails
                    user={user}
                    goBack={this.changeCustomerDetails}
                    nextStep={this.getBusinessDetails}
                  />
                ),
                3: (
                  <SubscriptionPlan
                    user={user}
                    business={business}
                    businessCategory={businessCategory}
                    goBack={this.changeBusinessDetails}
                    nextStep={this.getTxnDetails}
                  />
                ),
                4: (
                  <CompleteProcess
                    user={user}
                    business={business}
                    transaction={transaction}
                    businessCategory={businessCategory}
                    nextStep={this.completeProcess}
                  />
                )
              }[step]
            }
          </Card>
        </div>
      </div>
    )
  }
}

export default AddBusinessComponent;