import React, { Component } from "react";
import {Card } from "antd";
import ActivateMember from "./subcomponents/ActivateMember";

class ActivateMemberComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      user: null,
      business: null,
      businessCategory: null,
      transaction: null,
    };
  }

  getPhoneNumber = (user) => {
    this.setState({
      step: 1,
      user: user,
    });
  };

  render() {
    const { user } = this.state;
    return (
      <div className={"add-business"}>
        <div className={"header"}>
          <div className={"page-title"}>Activate Member</div>
          <Card>
            <ActivateMember user={user} nextStep={this.getPhoneNumber} />
          </Card>
        </div>
      </div>
    );
  }
}

export default ActivateMemberComponent;
