import React, {Component} from 'react';
import {Row, Col, Card, notification} from 'antd';
import ajax from '../../../../../services/ajax';
import { urlPath } from '../../../../../services/urlPath';

class Stats extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      users: 0,
      customers: 0,
      businesses: 0,
      reviews: 0,
      suggestions: 0,
      posts: 0
    }
  }


  componentWillMount() {
    this.getStats();
  }

  getStats = () => {
    ajax('get', urlPath.dashboard).then(({result}) => {
      this.setState({
        loading: false,
        users: result.users,
        customers: result.customers,
        businesses: result.businesses,
        reviews: result.reviews,
        suggestions: result.suggestions,
        posts: result.posts
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  render() {

    const { loading, users, customers, businesses, reviews, suggestions, posts} = this.state;

    return (
      <div className="stats">
        <Row gutter={12} >
          <Col xl={4} lg={6} md={8} sm={12}>
            <Card loading={loading}>
              <div className={'title'}>Total Users</div>
              <div className={'value'}>{users}</div>
            </Card>
          </Col>
          <Col xl={4} lg={6} md={8} sm={12}>
            <Card loading={loading}>
              <div className={'title'}>Customers</div>
              <div className={'value'}>{customers}</div>
            </Card>
          </Col>
          <Col xl={4} lg={6} md={8} sm={12}>
            <Card loading={loading}>
              <div className={'title'}>Business</div>
              <div className={'value'}>{businesses}</div>
            </Card>
          </Col>
          <Col xl={4} lg={6} md={8} sm={12}>
            <Card loading={loading}>
              <div className={'title'}>Review</div>
              <div className={'value'}>{reviews}</div>
            </Card>
          </Col>
          <Col xl={4} lg={6} md={8} sm={12}>
            <Card loading={loading}>
              <div className={'title'}>Suggestions</div>
              <div className={'value'}>{suggestions}</div>
            </Card>
          </Col>
          <Col xl={4} lg={6} md={8} sm={12}>
            <Card loading={loading}>
              <div className={'title'}>Posts</div>
              <div className={'value'}>{posts}</div>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Stats;