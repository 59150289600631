import React, { Component } from "react";
import { notification, Button, Table, Tag, Drawer, Icon, Rate } from "antd";
import moment from "moment";
import ajax from "../../../services/ajax";
import ReviewAction from "./ReviewAction";
import { color } from "../../../config";
import { urlPath } from "../../../services/urlPath";

class ReviewComponent extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Business",
        dataIndex: "business",
        key: "business",
        render: (text, { business }) => business?.name??text,
      },
      {
        title: "Customer",
        dataIndex: "customer",
        key: "customer",
        render: (text, { user }) =>
          user.business ? (
            <>
              <Icon
                type={"shop"}
                theme={"filled"}
                style={{ fontSize: 16, color: color.accent }}
              />{" "}
              {user.business.name}
            </>
          ) : (
            `${user.firstName} ${user.lastName}`
          ),
      },
      {
        title: "Rating",
        width: 200,
        dataIndex: "rating",
        key: "rating",
        render: (text) => <Rate disabled defaultValue={text} />,
      },
      {
        title: "Comment",
        dataIndex: "comment",
        key: "comment",
        render: (text) =>
          text && text.length > 20 ? `${text.substr(0, 20)}...` : text,
      },
      {
        title: "Time",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        render: (text) => moment(text).format("DD-MM-YYYY HH:mm"),
      },
      // {
      //   title: 'Action',
      //   key: 'operation',
      //   width: 250,
      //   render: (text, record) => <Button onClick={() => { this.showReviewDetails(record)}} >View</Button>,
      // },
    ];

    this.state = {
      list: [],
      loading: true,
      viewReview: false,
      selectedReview: {},
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }

  componentWillMount() {
    this.getReviews();
  }

  showReviewDetails = (review) => {
    this.setState({
      viewReview: true,
      selectedReview: review,
    });
  };

  closeViewMode = () => {
    this.setState({
      viewReview: false,
      selectedReview: {},
    });
  };

  getReviews = () => {
    let { pagination } = this.state;
    ajax("get", urlPath.review, { page: pagination.current }).then(
      ({ result }) => {
        pagination.total = result.count;
        console.log(result);
        this.setState({
          viewReview: false,
          loading: false,
          pagination: pagination,
          list: result.list,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          viewReview: false,
          loading: false,
          list: [],
        });
      }
    );
  };
  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getReviews)
  };
  render() {
    const { list, loading, pagination, viewReview, selectedReview } =
      this.state;

    return (
      <div>
        <div className={"header"}>
          <div className={"page-title"}>Reviews</div>
        </div>
        <Table
          rowKey={"id"}
          loading={loading}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />
        {viewReview && (
          <Drawer width={520} visible={viewReview} onClose={this.closeViewMode}>
            <ReviewAction review={selectedReview} />
          </Drawer>
        )}
      </div>
    );
  }
}

export default ReviewComponent;
