import React, { Component } from "react";
import { Button } from "antd";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import "./ImageUpload.scss";
class ImageUpload extends Component {
  imageFile = null;
  state = {
    src: null,
    crop: {
      aspect: 1280 / 720,
      x: 10,
      y: 10,
      width: 1280,
      height: 720,
      fileData: null,
    },
  };

  onImageLoaded = (image) => {
    this.imageRef = image;
  };

  onCropComplete = (crop) => {
    if (this.imageRef && crop.width && crop.height) {
      this.getCroppedImg(this.imageRef, crop, this.makeFile);
    }
  };

  onCropChange = (crop) => {
    this.setState({ crop });
  };

  getCroppedImg(image, crop, cb) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    const reader = new FileReader();
    canvas.toBlob((blob) => {
      reader.readAsDataURL(blob);
      cb(blob);
    });
  }

  makeFile = (blob) => {
    const formDataToUpload = new FormData();
    formDataToUpload.append("media", blob);
    this.props.shareFile(formDataToUpload);
    this.setState({
      fileData: formDataToUpload,
    });
  };

  onSelectFile = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      this.imageFile = e.target.files[0];
      const reader = new FileReader();
      reader.addEventListener(
        "load",
        () =>
          this.setState({
            src: reader.result,
          }),
        false
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  render() {
    const { src, crop } = this.state;
    const uploadType = this.props.uploadType == "LOGO" ? "Logo" : "Banner";
    return (
      <div className={"image-upload"}>
        <input type="file" id={"ad-banner"} onChange={this.onSelectFile} />
        <label
          className={
            "ant-btn ant-btn-default ant-btn-lg ant-btn-block upload-label"
          }
          htmlFor={this.props.uploadType == "logo" ? "add-logo" : "ad-banner"}
        >
          {src ? `Replace ${uploadType}` : `Add ${uploadType}`}
        </label>
        {src && (
          <ReactCrop
            src={src}
            crop={crop}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
          />
        )}
      </div>
    );
  }
}

export default ImageUpload;
