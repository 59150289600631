import React, {Component} from 'react';
import {Button, Avatar, Badge, Row, Col, Icon, Divider, notification, Popconfirm} from 'antd';
import DescriptionItem from '../../_common/DescriptionItem';
import moment from 'moment';
import ajax from '../../../services/ajax';
import {color, fileBaseURL} from '../../../config';
import { urlLinkPath } from '../../../services/urlPath';

class FtMemberAction extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleVisibility = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.user.id,
      status: !this.props.user.visible
    };
    ajax('post',urlLinkPath.userVisibility,params).then((res) => {
      this.setState({
        loading: false
      });
      notification.success({
        message: 'Success',
        description: 'User status updated!'
      });
      this.props.updateUserList();
    }, ({msg}) => {
      this.setState({
        loading: false
      });
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  render() {

    const { loading } = this.state;
    const { user } = this.props;

    return (
      <div>
        <Row>
          <Col span={3}>
            <Avatar icon={'user'} size={'large'} src={`${fileBaseURL}/user/${user.id}.jpg`}/>
          </Col>
          <Col span={21}>
            <div className={'drawer-title'}>
              <h2>{user.firstName} {user.lastName}</h2>
              <p>
                {user.phone} {' '}
                { user.verified
                  ? <Icon type="check-circle" theme={'filled'} style={{color: color.primary}}/>
                  : <Icon type="clock-circle" theme={'filled'} style={{color: color.accentDark}}/>
                }
              </p>
            </div>
          </Col>
        </Row>

        <Divider/>
        <Row>
          <Col span={12}>
            <DescriptionItem title={'Birthday'} content={moment(user.dob).format('MMM DD YYYY')} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'Email'} content={user.emailId} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title={'City'} content={user.city && user.city.name} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'State'} content={user.city && user.city.stateName} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title={'A/C Created'} content={moment(user.createdAt).format('DD MMM YYYY HH:SS')} />
          </Col>
        </Row>

        <div className={'btn-grp opposite drawer-footer'}>
          <Button
            size={'large'}
            onClick={this.handleVisibility}
            type={user && user.visible ? 'danger': 'primary'}
            loading={loading}
          >
            {user && user.visible ? 'Block User': 'Activate User'}
          </Button>
        </div>
      </div>
    )
  }
}

export default FtMemberAction