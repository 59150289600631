import React, { Component } from "react";
import { Form, Input, Button, notification, Select } from "antd";
import ImageUpload from "../../_common/ImageUpload/ImageUpload";
import ajax from "../../../services/ajax";
import {urlPath,urlLinkPath} from "../../../services/urlPath";
import { fileBaseURL } from "../../../config";

class EarningAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pushPicture: null,
    };
  }

  handleSuccess = (msg) => {
    this.setState({
      loading: false,
    });
    notification.success(msg);
    this.props.updateLinkList();
  };

  handleError = (msg) => {
    this.setState({
      loading: false,
    });
    notification.error({
      message: "Error",
      description: msg,
    });
  };

  submitForm = (ev) => {
    ev.preventDefault();
    const action = {
      ADD: this.addLink,
      EDIT: this.updateLink,
    }[this.props.mode];
    action();
  };

  addLink = () => {
    const { pushPicture } = this.state;
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true,
      });
      if (pushPicture) {
        pushPicture.set("link", values.link);
        pushPicture.set("name", values.name);

        const options = {
          headers: { "Content-Type": "multipart/form-data" },
        };
        ajax("post", urlPath.earningLink, pushPicture, options).then(
          (res) => {
            this.handleSuccess({
              message: "Success",
              description: "Earning Link added!",
            });
          },
          ({ msg }) => {
            this.handleError(msg);
          }
        );
      } else {
        this.handleError("Add a Logo!");
      }
    });
  };

  updateLink = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true,
      });
      values.id = this.props.link.id;

      ajax("put", urlPath.earningLink, values).then(
        (res) => {
          this.handleSuccess({
            message: "Success",
            description: "Earning Link updated!",
          });
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );
    });
  };
  handleVisibility = () => {
    this.setState({
      loading: true,
    });

    const params = {
      id: this.props.link.id,
      status: !this.props.link.visible,
    };
    ajax("post",urlLinkPath.earningLinkVisibility, params).then(
      (res) => {
        this.handleSuccess({
          message: "Success",
          description: "Earning Link status updated!",
        });
      },
      ({ msg }) => {
        this.handleError(msg);
      }
    );
  };
  getPushPicture = (formData) => {
    this.setState({
      pushPicture: formData,
    });
  };

  render() {
    const { loading } = this.state;
    const { link, mode } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.submitForm} className="shopping-link-form">
        <h2>Earning Link Details</h2>
        <Form.Item label={"Name"} colon={false}>
          {getFieldDecorator("name", {
            initialValue: link && link.name,
            rules: [
              {
                required: true,
                message: "Please input Name!",
              },
            ],
          })(<Input size={"large"} />)}
        </Form.Item>
        <Form.Item label={"Link"} colon={false}>
          {getFieldDecorator("link", {
            initialValue: link && link.link,
            rules: [
              {
                required: true,
                message: "Please input Link!",
              },
            ],
          })(<Input size={"large"} />)}
        </Form.Item>
        {mode == "EDIT" ? (
          <Form.Item>
            <div>
              <img src={`${fileBaseURL}/${link.image}`} alt={"image"} />
            </div>
          </Form.Item>
        ) : (
          <div />
        )}
        {mode == "ADD" ? (
          <Form.Item>
            <ImageUpload shareFile={this.getPushPicture} uploadType="LOGO" />
          </Form.Item>
        ) : (
          <div />
        )}

        <div>
          <Button
            htmlType={"submit"}
            size={"large"}
            type={"default"}
            hidden={mode !== "ADD"}
            loading={loading}
          >
            Add Earning Link
          </Button>
          <Button
            htmlType={"submit"}
            size={"large"}
            type={"default"}
            hidden={mode !== "EDIT"}
          >
            Update Earning Link
          </Button>
        </div>
        <div
          className={"btn-grp opposite drawer-footer"}
          hidden={mode === "ADD"}
        >
          <Button
            size={"large"}
            loading={loading}
            type={link && link.visible ? "danger" : "primary"}
            onClick={this.handleVisibility}
          >
            {link && link.visible ? "Block Link" : "Activate Link"}
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create()(EarningAction);
