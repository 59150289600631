import React, {Component} from 'react';
import {notification, Button, Table, Tag, Icon, Drawer} from 'antd';
import ajax from '../../../../services/ajax';
import {color} from '../../../../config';
import CategoryAction from "./CategoryAction";
import { urlPath } from '../../../../services/urlPath';

class BusinessCategoryComponent extends Component{
  constructor(props){
    super(props);

    this.columns = [
      {
        title: 'Name',
        width: 250,
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => (
          <>
          {text} {' '} {record.featured && <Icon style={{color: color.accent}} theme={'filled'} type={'flag'}/>}
          </>
        )
      },
      {
        title: 'Master Category',
        width: 200,
        dataIndex: 'masterBusinessCategory',
        key: 'masterBusinessCategory',
        render: (text, record) => record.masterBusinessCategory && record.masterBusinessCategory.name
      },
      {
        title: 'Popularity',
        width: 100,
        dataIndex: 'popular',
        key: 'popular',
      },
      {
        title: 'Status',
        width: 100,
        dataIndex: 'visible',
        key: 'visible',
        render: (text) => text ? <Tag color={color.primary}>ACTIVE</Tag>: <Tag color={color.disabled}>BLOCKED</Tag>
      },
      {
        title: 'Action',
        key: 'operation',
        width: 250,
        render: (text, record) => <Button onClick={() => { this.editCategoryDetails(record)}} >Edit</Button>,
      },
    ];

    this.state = {
      list: [],
      loading: true,
      mode: 'ADD',
      viewCategory: false,
      masterCategories: [],
      selectedCategory: {},
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    };
  }

  componentWillMount() {
    this.getCategories();
    this.getMasterCategories();
  }

  addCategory = () => {
    this.setState({
      viewCategory: true,
      mode: 'ADD'
    })
  };

  editCategoryDetails = (category) => {
    this.setState({
      viewCategory: true,
      mode: 'EDIT',
      selectedCategory: category
    })
  };

  closeViewMode = () => {
    this.setState({
      viewCategory: false,
      selectedCategory: {}
    })
  }

  getCategories = () => {
    let { pagination } = this.state;
    ajax('get', urlPath.businessCategory,{ page: pagination.current}).then(({result}) => {
      pagination.total = result.count;
      this.setState({
        viewCategory: false,
        loading: false,
        pagination: pagination,
        selectedCategory: {},
        mode: 'ADD',
        list: result.list
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        viewCategory: false,
        loading: false,
        selectedCategory: {},
        mode: 'ADD',
        list: []
      })
    })
  };

  getMasterCategories = () => {
    ajax('get', urlPath.masterBusinessCategory,{}).then(({list}) => {
      this.setState({
        masterCategories: list
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getCategories)
  };

  render(){

    const {list, loading, pagination, viewCategory, masterCategories, mode, selectedCategory} = this.state;

    return(
      <div className={'admin-module'}>
        <div className={'header'}>
          <div className={'page-title'}>Business Categories</div>
        </div>

        <div className={'admin-module-body'}>
          <Button type={'primary'} size={'large'} onClick={this.addCategory}>Add Business Category</Button>
          <Table
            rowKey={'id'}
            loading={loading}
            columns={this.columns}
            pagination={pagination}
            dataSource={list}/>
        </div>

        {
          viewCategory && (
            <Drawer
              width={520}
              visible={viewCategory}
              onClose={this.closeViewMode}
            >
              <CategoryAction
                mode={mode}
                category={selectedCategory}
                masterCategories={masterCategories}
                updateCategoryList={this.getCategories}
              />
            </Drawer>
          )
        }

      </div>
    )
  }
}

export default BusinessCategoryComponent;
