import React, { Component } from "react";
import { notification, Input, Col, Row, Form } from "antd";
import ajax from "../../../services/ajax";
import { urlLinkPath } from "../../../services/urlPath";
class SearchBox extends Component {
  constructor(props) {
    super(props);
  }

  search = (e) => {
    let { pagination } = this.props;
    ajax("get", urlLinkPath.searchInvite, {
      search: e.target.value,
    }).then(
      ({ result }) => {
        pagination.total = result.count;
        this.props.searchBoxCallback(result.list);
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.props.searchBoxCallback([]);
      }
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div>
        <div className="spacer" />
        <Form>
          <Row gutter={16}>
            <Col lg={8} md={10} sm={24}>
              <Form.Item>
                {getFieldDecorator("phone", {
                  rules: [
                    {
                      pattern: /^([789])[0-9]{9}$/,
                      message:
                        "Please enter valid 10 digit Indian Phone Number!",
                    },
                  ],
                })(
                  <Input placeholder={"897 XXX XXXX"} onChange={this.search} />
                )}
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </div>
    );
  }
}

export default Form.create()(SearchBox);
