import React, {Component} from 'react';
import ajax from "../../../../services/ajax";
import {Button, notification, Row, Col, Checkbox, Carousel} from "antd"
import moment from 'moment';
import { urlPath,urlLinkPath } from '../../../../services/urlPath';

const getExpiry = (period, type) => {
  const momentType = {
    DAY: 'd',
    MONTH: 'M',
    YEAR: 'y',
  }[type];

  return moment().add(parseInt(period), momentType).format('Do MMMM YYYY');
};

class SubscriptionPlan extends Component{

  state = {
    plan: {},
    plans: [],
    agreement: false,
    loading: false
  };

  componentWillMount() {
    const { masterBusinessCategoryId } = this.props.businessCategory || {};
    const params = {
      category: masterBusinessCategoryId
    };
    ajax('get', `${urlPath.subscriptionPlan}/`,params).then(({plans}) => {
      this.setState({
        plan: plans[0],
        plans: plans
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        loading: false
      });
    })
  }

  confirmPayment = () => {
    this.setState({
      loading: true
    });

    const {user, business} = this.props;
    const params = {
      userId: user.id,
      businessId: business.id,
      subscriptionPlanId: this.state.plan.id
    };

    ajax('post', urlLinkPath.processPayment, params).then(({txn, msg}) => {
      notification.success({
        message: 'Success',
        description: msg
      });
      this.setState({
        loading: false
      });
      this.props.nextStep(txn);
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        loading: false
      });
    })
  }

  onChange = (ev) => {
    this.setState({
      agreement: ev.target.checked
    })
  };

  slideChange = (index) => {
    this.setState((state, props) => {
      return {
        plan: state.plans[index]
      }
    })
  };

  render() {

    const { plans, agreement, loading } = this.state;

    const planList = plans.map((p, index) => {

      const {
        igst = 0,
        cgst = 0,
        sgst = 0,
        period = 0,
        periodType = "DAY",
        fee = 0,
        maxTextPost = 0,
        maxPhotoPost = 0,
        maxVideoPost = 0
      } = p;

      const tax = parseFloat(igst) + parseFloat(cgst) + parseFloat(sgst);
      const totalTax = Math.ceil(parseFloat(fee) * tax / 100);
      const totalFee = Math.ceil((totalTax + parseFloat(fee)));
      const validity = getExpiry(period, periodType);
      return (
        <div key={index}>
          <div className={'card'}>
            <div className={'duration'}>
              <h2>{period}</h2>
              <p className={'bold'}>{periodType}</p>
            </div>
          </div>
          <p className={'validity'}>Validity till <b>{validity}</b></p>
          <div className={'features'}>
            <Row>
              <Col xs={8}>
                <h2>{maxTextPost < 0 ? '∞' : maxTextPost}</h2>
                <p className={'bold'}>Text Post</p>
              </Col>
              <Col xs={8}>
                <h2>{maxPhotoPost < 0 ? '∞' : maxPhotoPost}</h2>
                <p className={'bold'}>Photo Post</p>
              </Col>
              <Col xs={8}>
                <h2>{maxVideoPost < 0 ? '∞' : maxVideoPost}</h2>
                <p className={'bold'}>Video Post</p>
              </Col>
            </Row>
          </div>
          <div className={'amount'}>{totalFee}</div>
          <div className={'tax'}>(inclusive of {tax}% GST)</div>
        </div>
      )
    })

    return (
      <>
        <div className={'sub-title align-center'}>Subscription</div>



        <div className={'subscription'}>
          <Carousel afterChange={this.slideChange}>
            {planList}
          </Carousel>
          <div className={'agreement'}>
            <Checkbox onChange={this.onChange} checked={agreement}>
              I Agree, I received amount from Business Account Holder
            </Checkbox>
          </div>
          <Button
            disabled={!agreement}
            type={'primary'}
            size={'large'}
            onClick={this.confirmPayment}
            block
            loading={loading}
          >
            Proceed to Pay
          </Button>
        </div>
      </>
    );
  }
}

export default SubscriptionPlan