import React, { Component } from "react";
import { Form, InputNumber, Row, Col, Button, notification, Input } from "antd";

import ajax from "../../../services/ajax";
import { urlLinkPath } from "../../../services/urlPath";

class AdPricingAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  updateSateWiseAdPrice = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true,
      });

      values.id = this.props.state.id;
      ajax("put", urlLinkPath.statewiseAdPrice, values).then(
        (res) => {
          this.setState({
            loading: false,
          });
          notification.success({
            message: "Success",
            description: "Ad Price updated!",
          });
          this.props.updateSateWiseAdPriceList();
        },
        (err) => {
          this.setState({
            loading: false,
          });
          notification.error({
            message: "Error",
            description: "Something went wrong. Please try again!",
          });
        }
      );
    });
  };

  render() {
    const { loading } = this.state;
    const { state } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.updateSateWiseAdPrice}>
        <h2>Update Ad Price</h2>
        <Form.Item colon={false}>
          <Row gutter={10}>
            <Col lg={6} xs={8}>
              <Form.Item label={"State"} colon={false}>
                {getFieldDecorator(`name`, {
                  initialValue: state && state.name,
                })(<Input disabled={true} size={"medium"} />)}
              </Form.Item>
            </Col>
            <Col lg={6} xs={8}>
              <Form.Item label={"Price"} colon={false}>
                {getFieldDecorator(`price`, {
                  initialValue: state && state.adPrice,
                  rules: [
                    {
                      required: true,
                      message: `Please input  price value`,
                    },
                  ],
                })(<InputNumber size={"medium"} min={0} />)}
              </Form.Item>
            </Col>
          </Row>
        </Form.Item>
        <div>
          <Button htmlType={"submit"} size={"large"} type={"default"}>
            Update Ad Price
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create()(AdPricingAction);
