import React, {Component} from 'react';
import {Form, Input, Select, Button, notification} from 'antd';

import ajax from '../../../../services/ajax';
import { urlPath } from '../../../../services/urlPath';

class AddAppVersion extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  submitForm = (ev) => {
    ev.preventDefault();
    this.props.form.validateFields((err, values) => {
      if(err) return;
      this.setState({
        loading: true
      });
      ajax('post', urlPath.appVersion,values).then((res) => {
        this.setState({
          loading: false
        });
        notification.success({
          message: 'Success',
          description: 'App Version added!'
        });
        this.props.updateVersionList();
        this.props.form.resetFields();
      }, (err) => {
        this.setState({
          loading: false
        });
        notification.error({
          message: 'Error',
          description: 'Something went wrong. Please try again!'
        });
      })
    })
  };

  render() {

    const { loading } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.submitForm}>
        <h2>Add App Version</h2>
        <Form.Item label={'Version'} colon={false}>
          {
            getFieldDecorator('version', {
              rules: [{
                required: true,
                message: 'Please input Version!'
              }]
            })(<Input size={'large'}/>)
          }
        </Form.Item>
        <Form.Item label={'Version Code'} colon={false}>
          {
            getFieldDecorator('code', {
              rules: [{
                required: true,
                message: 'Please input Version Code!'
              }]
            })(<Input size={'large'}/>)
          }
        </Form.Item>
        <Form.Item label={'Type'} colon={false}>
          {
            getFieldDecorator('type', {
              rules: [{
                required: true,
                message: 'Please select Version Type!'
              }]
            })(
              <Select size={'large'}>
                <Select.Option value={'SOFT'}>SOFT</Select.Option>
                <Select.Option value={'HARD'}>HARD</Select.Option>
              </Select>
            )
          }
        </Form.Item>
        <Form.Item label={'Platform'} colon={false}>
          {
            getFieldDecorator('platform', {
              rules: [{
                required: true,
                message: 'Please select Platform!'
              }]
            })(
              <Select size={'large'}>
                <Select.Option value={'ANDROID'}>Android</Select.Option>
                <Select.Option value={'IOS'}>iOS</Select.Option>
              </Select>
            )
          }
        </Form.Item>
        <div>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            loading={loading}
          >
            Add App Version
          </Button>
        </div>
      </Form>
    )
  }
}

export default Form.create()(AddAppVersion)