import React, { Component } from "react";
import {
  Form,
  Button,
  notification,
  DatePicker,
  InputNumber,
  Input,
} from "antd";
import ajax from "../../../services/ajax";
import { urlPath } from "../../../services/urlPath";
import moment from "moment";
import Range from "./subcomponents/Range";
const { RangePicker } = DatePicker;

class ReferralSchemeAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      pushPicture: null,
      rangeCount: 1,
      ranges: [],
      dateRange: [moment(), moment().add(6, "d")],
      disableDateList: [],
    };
  }

  handleSuccess = (msg) => {
    this.setState({
      loading: false,
    });
    notification.success(msg);
  };

  handleError = (msg) => {
    this.setState({
      loading: false,
    });
    notification.error({
      message: "Error",
      description: msg,
    });
  };

  submitForm = (ev) => {
    ev.preventDefault();
    const action = {
      ADD: this.addReferralScheme,
      EDIT: this.updateReferralScheme,
    }[this.props.mode];
    action();
  };
  addReferralScheme = () => {
    const { ranges, rangeCount } = this.state;
    const { closeDrawer } = this.props;

    this.props.form.validateFields((err, values) => {
      const params = {
        startDate: values.dateRange[0].format(),
        endDate: values.dateRange[1].format(),
        ranges: ranges,
        rangeCount: rangeCount,
      };

      if (err) return;
      this.setState({
        loading: true,
      });
      ajax("post", urlPath.referralScheme, params).then(
        (res) => {
          closeDrawer();
          this.handleSuccess({
            message: "Success",
            description: "Referral Scheme added!",
          });
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );
    });
  };
  updateReferralScheme = () => {
    const { ranges, rangeCount } = this.state;
    const { closeDrawer, scheme } = this.props;
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true,
      });

      const params = {
        startDate: values.dateRange[0].format(),
        endDate: values.dateRange[1].format(),
        ranges: scheme.ranges,
        id: scheme.id,
        rangeCount: scheme.rangeCount,
      };
      ajax("put", urlPath.referralScheme, params).then(
        (res) => {
          this.handleSuccess({
            message: "Success",
            description: "Referral Scheme updated!",
          });
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );
    });
  };
  dateRangeChange = (range) => {
    if (!(range[0] && range[1])) {
      range = [moment().subtract(7, "days"), moment()];
    }
    this.setState({
      dateRange: range,
    });
  };
  disabledDate = (current) => {
    const format = "YYYY-MM-DD";
    const { disableDateList } = this.state;

    return disableDateList.some(
      (date) =>
        current.isBetween(
          moment(date["startDate"], format),
          moment(date["endDate"], format).endOf("day")
        ) ||
        (current <= moment().startOf("day") && current < moment().endOf("day"))
    );
  };
  componentWillMount() {
    this.getReferralSchemes();
  }

  getReferralSchemes = () => {
    ajax("get", urlPath.referralScheme).then(
      ({ result }) => {
        let disabledDates = [];
        for (let i = 0; i < result.length; i++) {
          disabledDates.push({
            startDate: result[i].startDate,
            endDate: result[i].endDate,
          });
          this.setState({
            disableDateList: disabledDates,
          });
        }
        this.setState({
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          loading: false,
        });
      }
    );
  };
  getRangeCount = (value) => {
    const { ranges } = this.state;
    ranges.length = 0;
    for (var i = 1; i <= value; i++) {
      ranges.push({
        minRange: 0,
        maxRange: 0,
        referralCashPer: 0,
        realCashPer: 0,
      });
      this.setState({
        ranges: ranges,
        rangeCount: value,
      });
    }
  };

  render() {
    const { loading, dateRange, rangeCount, ranges, disableDateList } =
      this.state;
    const { mode, scheme } = this.props;

    const { getFieldDecorator } = this.props.form;
    return (
      <Form onSubmit={this.submitForm} className="referral-scheme-form">
        <h2>Referral Scheme</h2>
        <Form.Item label={"Date Range"} colon={false}>
          {getFieldDecorator("dateRange", {
            initialValue: dateRange,
            rules: [
              {
                required: true,
              },
              {
                validator: (rule, value, cb) => {
                  const format = "YYYY-MM-DD";
                  let validate;
                  value.map((current) => {
                    disableDateList.some(
                      (date) =>
                        (validate = current.isBetween(
                          moment(date["startDate"], format),
                          moment(date["endDate"], format).endOf("day")
                        ))
                    );
                  });
                  validate ? cb(true) : cb();
                },
                message: "Please input date range  value",
              },
            ],
          })(
            <RangePicker
              value={dateRange}
              format={"DD/MM/YYYY"}
              separator={"to"}
              disabledDate={this.disabledDate}
              onChange={this.dateRangeChange}
            />
          )}
        </Form.Item>
        <Form.Item label={"Range Count"} colon={false}>
          {getFieldDecorator(`rangeCount`, {
            initialValue: mode == "EDIT" ? scheme && scheme.rangeCount : 1,
            onChange: (value) => {
              this.getRangeCount(value);
            },
            rules: [
              {
                required: true,
                pattern: new RegExp(/^[1-9]+$/),
                message: `Please input range count value`,
              },
            ],
          })(<InputNumber size={"medium"} min={1} />)}
        </Form.Item>
        <Range
          form={this.props.form}
          ranges={mode == "EDIT" ? scheme.ranges : ranges}
          rangeCount={mode == "EDIT" ? scheme.rangeCount : rangeCount}
        />
        <div>
          <Button
            htmlType={"submit"}
            size={"large"}
            type={"default"}
            hidden={mode !== "ADD"}
            disabled={rangeCount == 1 ? true : false}
            loading={loading}
          >
            Add Referral Scheme
          </Button>
          <Button
            htmlType={"submit"}
            size={"large"}
            type={"default"}
            hidden={mode !== "EDIT"}
          >
            Update Referral Scheme
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create()(ReferralSchemeAction);
