import React, { Component } from "react";
import {
  notification,
  Table,
  Button,
  Row,
  Col,
  Tag,
  DatePicker,
  Card,
  Select,
} from "antd";
import moment from "moment";
import ajax from "../../../services/ajax";
import { color, apiBaseURL } from "../../../config";
import {
  REPORT_NAME,
  REPORT_TYPE,
  CONFIGURATION_KEY,
} from "../../../services/constants";
import { strigifyQuery } from "../../../services/queryHelper";
import { urlLinkPath, urlPath } from "../../../services/urlPath";
const { RangePicker } = DatePicker;

class FinancialReport extends Component {
  constructor(props) {
    super(props);

    this.authorization = localStorage.getItem("token");

    this.state = {
      list: [],
      reports: [],
      configList: [],
      selectedReport: null,
      reportType: null,
      loading: true,
      viewReferral: false,
      dateRange: [moment().subtract(6, "d"), moment()],
      downloadLink: "",
      optionsPlan: [],
      planType: null,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }

  componentWillMount() {
    this.getTableColumn();
    this.getTransactions();
    this.getConfigurationList();
  }
  getPlanList = (selectedReport) => {
    ajax(
      "get",
      selectedReport == REPORT_NAME.TOPUPS
        ? urlLinkPath.topupPlanList
        : urlLinkPath.subscriptionPlanList,
      {}
    ).then(
      ({ list }) => {
        this.setState({
          optionsPlan: list,
        });
      },
      ({ msg }) => {
        this.setState({
          optionsPlan: [],
        });
      }
    );
  };
  getConfigurationList = () => {
    ajax("get", urlPath.configuration, {}).then(
      ({ list }) => {
        this.setState({
          configList: list,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          configList: [],
        });
      }
    );
  };
  dateRangeChange = (range) => {
    let { selectedReport, reportType } = this.state;
    if (!(range[0] && range[1])) {
      range = [moment().subtract(7, "days"), moment()];
    }
    this.setState(
      {
        dateRange: range,
      },
      this.getTransactions(selectedReport, reportType),
      this.getTableColumn(selectedReport, reportType)
    );
  };
  getTableColumn = (
    selectedReport = this.state.selectedReport,
    reportType = this.state.reportType
  ) => {
    let { pagination, configList } = this.state;
    this.columns = [
      {
        title: "SR. No",
        key: "srNo",
        render: (value, record, index) =>
          (pagination.current - 1) * 25 + index + 1,
      },
      {
        title: `${
          selectedReport == REPORT_NAME.ADPUBLICATION
            ? reportType == REPORT_TYPE.REVENUEGENERATION
              ? "Business"
              : "Member"
            : ""
        } Mobile No.`,
        dataIndex: "phone",
        key: "phone",
        render: (text, { business, user, userInfo }) => {
          if (selectedReport == REPORT_NAME.BUSINESS) {
            return business && business.phone;
          }
          if (reportType == REPORT_TYPE.INVOICECLEARANCE) {
            return userInfo && userInfo.phone;
          } else {
            return user && user.phone;
          }
        },
      },
      {
        title:
          selectedReport == REPORT_NAME.TOPUPS
            ? "Top up Date"
            : "Registration Date",
        dataIndex:
          selectedReport == REPORT_NAME.TOPUPS
            ? "createdAt"
            : "registrationDate",
        key:
          selectedReport == REPORT_NAME.TOPUPS
            ? "createdAt"
            : "registrationDate",
        render: (text, { business, user }) => {
          if (selectedReport == REPORT_NAME.TOPUPS) {
            return moment(text).format("DD-MM-YYYY HH:mm");
          }
          if (business != null) {
            return moment(business && business.createdAt).format(
              "DD-MM-YYYY HH:mm"
            );
          } else {
            return moment(user && user.createdAt).format("DD-MM-YYYY HH:mm");
          }
        },
      },
      {
        title: "Subscription Plan",
        dataIndex: "plan",
        key: "plan",
        render: (text, { subscriptionPlan, topupPlan }) => {
          if (selectedReport == REPORT_NAME.TOPUPS) {
            return topupPlan && topupPlan.name;
          } else {
            return subscriptionPlan && subscriptionPlan.name;
          }
        },
      },
      {
        title:
          selectedReport == REPORT_NAME.ADPUBLICATION &&
          reportType == REPORT_TYPE.INVOICECLEARANCE
            ? "Amount Paid"
            : "Fees Paid",
        dataIndex: "totalAmount",
        key: "totalAmount",
        render: (text, { razorpayPayout }) =>
          selectedReport == REPORT_NAME.ADPUBLICATION &&
          reportType == REPORT_TYPE.INVOICECLEARANCE
            ? razorpayPayout && razorpayPayout.amount
            : text,
      },
      {
        title:
          selectedReport == REPORT_NAME.ADPUBLICATION &&
          reportType == REPORT_TYPE.INVOICECLEARANCE
            ? "TDS Deducation"
            : "GST Component",
        dataIndex: "taxAmount",
        key: "taxAmount",
        render: (text, { razorpayPayout }) => {
          if (
            selectedReport == REPORT_NAME.ADPUBLICATION &&
            reportType == REPORT_TYPE.INVOICECLEARANCE
          ) {
            var tdsDeduction;
            for (let i = 0; i < configList.length; i++) {
              if (configList[i].key == CONFIGURATION_KEY.TDS_DEDUCTION) {
                tdsDeduction =
                  razorpayPayout &&
                  (razorpayPayout.amount * configList[i].value) / 100;
                return tdsDeduction;
              }
            }
          } else {
            return text;
          }
        },
      },
    ];
    var startIndex = this.columns
      .map((column) => {
        return column.key;
      })
      .indexOf("registrationDate");
    if (
      selectedReport == REPORT_NAME.ADPUBLICATION &&
      reportType == REPORT_TYPE.REVENUEGENERATION
    ) {
      this.columns.splice(
        startIndex,
        2,
        {
          title: "Ad Start Date",
          dataIndex: "startDate",
          key: "startDate",
          render: (text, { ad }) =>
            ad && ad.startDate == null
              ? ""
              : moment(ad && ad.startDate).format("DD-MM-YYYY HH:mm"),
        },
        {
          title: "Ad End Date",
          dataIndex: "endDate",
          key: "endDate",
          render: (text, { ad }) =>
            ad && ad.endDate == null
              ? ""
              : moment(ad && ad.endDate).format("DD-MM-YYYY HH:mm"),
        },
        {
          title: "No of Day",
          dataIndex: "noOfDaya",
          key: "noOfDaya",
          render: (text, { ad }) =>
            (ad && ad.endDate == null) || (ad && ad.startDate == null)
              ? "PENDING"
              : moment(ad && ad.endDate).diff(
                  moment(ad && ad.startDate),
                  "days"
                ),
        }
      );
    }
    if (
      selectedReport == REPORT_NAME.ADPUBLICATION &&
      reportType == REPORT_TYPE.INVOICECLEARANCE
    ) {
      this.columns.splice(
        startIndex,
        2,
        {
          title: "Invoice Amount",
          dataIndex: "amount",
          key: "amount",
          render: (text, { razorpayPayout }) =>
            razorpayPayout ? razorpayPayout.amount : text,
        },
        {
          title: "Clearance Date",
          dataIndex: "createdAt",
          key: "createdAt",
        }
      );
    }
  };

  getTransactions = (
    selectedReport = this.state.selectedReport,
    reportType = this.state.reportType,
    planType = this.state.planType
  ) => {
    let { pagination, dateRange } = this.state;
    const params = {
      startDate: dateRange[0].format("YYYY-MM-DD"),
      endDate: dateRange[1].format("YYYY-MM-DD"),
      page: pagination.current,
      selectedReport: selectedReport,
      planType: planType,
    };
    if (
      selectedReport == REPORT_NAME.ADPUBLICATION &&
      reportType == REPORT_TYPE.INVOICECLEARANCE
    ) {
      const invoiceParams = {
        startDate: dateRange[0].format("YYYY-MM-DD"),
        endDate: dateRange[1].format("YYYY-MM-DD"),
        page: pagination.current,
      };
      ajax("post", urlLinkPath.invoiceReport, invoiceParams).then(
        ({ result }) => {
          console.log(result);
          pagination.total = result.count;
          const link = strigifyQuery({
            authorization: this.authorization,
            startDate: dateRange[0].format("YYYY-MM-DD"),
            endDate: dateRange[1].format("YYYY-MM-DD"),
            reportCount: result.count,
          });
          this.setState({
            downloadLink: `${apiBaseURL}/payouts/download/invoice-clearence${link}`,
            viewReferral: false,
            list: result.list,
            pagination: pagination,
            loading: false,
          });
        },
        ({ msg }) => {
          notification.error({
            message: "Error",
            description: msg,
          });
          this.setState({
            list: [],
            loading: false,
          });
        }
      );
    } else {
      ajax("post", urlLinkPath.financialReport, params).then(
        ({ result }) => {
          pagination.total = result.count;
          const link = strigifyQuery({
            authorization: this.authorization,
            startDate: dateRange[0].format("YYYY-MM-DD"),
            endDate: dateRange[1].format("YYYY-MM-DD"),
            selectedReport: selectedReport,
            reportCount: result.count,
            planType: planType,
          });
          this.setState({
            downloadLink: `${apiBaseURL}/transaction/download-financial-report${link}`,
            viewReferral: false,
            list: result.list,
            pagination: pagination,
            loading: false,
          });
        },
        ({ msg }) => {
          notification.error({
            message: "Error",
            description: msg,
          });
          this.setState({
            list: [],
            loading: false,
          });
        }
      );
    }
  };

  handleReport = (id) => {
    let { reportType, planType } = this.state;
    this.setState(
      {
        selectedReport: id || null,
      },
      this.getTransactions(id, reportType, planType),
      this.getTableColumn(id, reportType, planType),
      this.getPlanList(id)
    );
  };
  handleReportTypeChange = (id) => {
    let { selectedReport } = this.state;
    this.setState(
      { reportType: id || null },
      this.getTransactions(selectedReport, id),
      this.getTableColumn(selectedReport, id)
    );
  };
  handlePlanTypeChange = (id) => {
    let { selectedReport, reportType } = this.state;
    this.setState(
      { planType: id || null },
      this.getTransactions(selectedReport, reportType, id),
      this.getTableColumn(selectedReport, reportType, id)
    );
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
      this.getTransactions
    );
  };

  render() {
    const {
      list,
      dateRange,
      loading,
      pagination,
      downloadLink,
      reportType,
      selectedReport,
      optionsPlan,
    } = this.state;
    let options = [];
    if (optionsPlan.length > 0) {
      options = optionsPlan.map((plan) => {
        return <Select.Option key={plan.id}>{plan.name}</Select.Option>;
      });
    }
    return (
      <div>
        <div className={"header"}>
          <div className={"page-title"}>Financial Report</div>
        </div>
        <div className="spacer" />
        <Row gutter={16}>
          <Col lg={4} md={6} sm={24}>
            <Select
              style={{ width: "100%" }}
              placeholder={" Select Report"}
              allowClear
              onChange={this.handleReport}
            >
              <Select.Option value={REPORT_NAME.ADPUBLICATION}>
                AD PUBLICATION
              </Select.Option>
              <Select.Option value={REPORT_NAME.BUSINESS}>
                BUSINESS
              </Select.Option>
              <Select.Option value={REPORT_NAME.FTMEMBER}>
                FTMEMBER
              </Select.Option>
              <Select.Option value={REPORT_NAME.FTPAYUSER}>
                FTPAYUSER
              </Select.Option>
              <Select.Option value={REPORT_NAME.TOPUPS}>TOP-UPS</Select.Option>
            </Select>
          </Col>

          <Col lg={4} md={6} sm={24}>
            <Select
              style={{ width: "100%" }}
              allowClear
              placeholder={" Select Report Type"}
              onChange={this.handleReportTypeChange}
            >
              {selectedReport == REPORT_NAME.ADPUBLICATION && (
                <Select.Option value={REPORT_TYPE.INVOICECLEARANCE}>
                  Invoice Clearance
                </Select.Option>
              )}
              <Select.Option value={REPORT_TYPE.REVENUEGENERATION}>
                Revenue Generation
              </Select.Option>
            </Select>
          </Col>

          {selectedReport != REPORT_NAME.ADPUBLICATION ? (
            <Col lg={4} md={6} sm={24}>
              <Select
                style={{ width: "100%" }}
                placeholder={" Select Subscription Plan"}
                allowClear
                onChange={this.handlePlanTypeChange}
              >
                {options}
              </Select>
            </Col>
          ) : (
            <></>
          )}
        </Row>
        <div className="spacer" />

        <Card
          className={"table-card"}
          extra={
            <RangePicker
              value={dateRange}
              format={"DD/MM/YYYY"}
              separator={"to"}
              onChange={this.dateRangeChange}
            />
          }
        >
          <Table
            rowKey={"id"}
            loading={loading}
            columns={this.columns}
            pagination={pagination}
            dataSource={list}
          />
        </Card>

        <div className={"spacer"} />
        <a href={downloadLink} target={"_blank"} disabled={list.length === 0}>
          <Button>Download Report</Button>
        </a>
      </div>
    );
  }
}

export default FinancialReport;
