import React, { Component } from "react";
import { Form, InputNumber, Col, Row, Button } from "antd";

class Range extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { form, rangeCount, ranges } = this.props;
    const { getFieldDecorator } = form;

    return (
      <>
        {rangeCount == 1 ? (
          <></>
        ) : (
          ranges.map((range,index) => (
            <Form.Item label={`Range ${index + 1} `} colon={false}>
              <Row>
                <Col lg={6} xs={8}>
                  <Form.Item label={"Min"} colon={false}>
                    {getFieldDecorator(`${index + 1}min`, {
                      initialValue: range.minRange,
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]+$/),
                          message: `Please input ${index + 1} min value`,
                        },
                      ],
                      onChange: (value) => {
                        ranges[index].minRange = value;
                      },
                    })(
                      <InputNumber
                        min={range.minRange}
                        max={range.minRange}
                        disabled={true}
                        size={"medium"}
                      />
                    )}
                  </Form.Item>
                </Col>
                {index + 1 == ranges.length ? (
                  <></>
                ) : (
                  <Col lg={6} xs={8}>
                    <Form.Item label={"Max"} colon={false}>
                      {getFieldDecorator(`${index + 1}max`, {
                        initialValue: range.maxRange,
                        rules: [
                          {
                            required: true,
                            pattern: new RegExp(/^[1-9]/),
                            message: `Please input ${index + 1} max value`,
                          },
                        ],
                        onChange: (value) => {
                          ranges[index].maxRange = value;
                          ranges[index + 1].minRange = value + 1;
                          if (index + 1 == ranges.length) {
                            ranges[index + 1].maxRange = value + 2;
                          }
                          else{
                            ranges[index + 1].maxRange=null;
                          }
                          this.setState({ ranges: ranges });
                        },
                      })(
                        <InputNumber min={range.maxRange} size={"medium"} />
                      )}
                    </Form.Item>
                  </Col>
                )}
                <Col lg={6} xs={8}>
                  <Form.Item label={"Referral Cash %"} colon={false}>
                    {getFieldDecorator(`${index + 1}refCshPer`, {
                      initialValue: range.referralCashPer??0,
                      rules: [
                        {
                          required: true,
                          message: `Please input ${
                            index + 1
                          } referral cash value`,
                        },
                      ],
                      onChange: (value) => {
                        ranges[index].referralCashPer = value;
                        this.setState({ ranges: ranges });
                      },
                    })(<InputNumber size={"medium"} min={0} />)}
                  </Form.Item>
                </Col>
                <Col lg={6} xs={8}>
                  <Form.Item label={"Real Cash %"} colon={false}>
                    {getFieldDecorator(`${index + 1}relCshPer`, {
                      initialValue: range.realCashPer??0,
                      rules: [
                        {
                          required: true,
                          message: `Please input ${index + 1} real cash value`,
                        },
                      ],
                      onChange: (value) => {
                        ranges[index].realCashPer = value;
                        this.setState({ ranges: ranges });
                      },
                    })(<InputNumber size={"medium"} min={0} />)}
                  </Form.Item>
                </Col>
              </Row>
            </Form.Item>
          ))
        )}
      </>
    );
  }
}

export default Range;
