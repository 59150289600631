import React, { Component } from "react";
import { notification, Button, Table, Drawer, Tag } from "antd";
import ajax from "../../../services/ajax";
import moment from "moment";
import { color } from "../../../config";
import { urlLinkPath } from "../../../services/urlPath";
import AdAction from "./AdAction";
import AdView from "./AdView";

class AdComponent extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Business",
        dataIndex: "business",
        key: "business",
        render: (text, record) => record.business && record.business.name,
      },
      {
        title: "Title",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Subtitle",
        dataIndex: "subtitle",
        key: "subtitle",
      },
      {
        title: "Views",
        dataIndex: "hits",
        key: "hits",
      },
      {
        title: "Status",
        dataIndex: "visible",
        key: "visible",
        render: (text, record) => {
          const startDate = moment(record.startDate).unix();
          const endDate = moment(record.endDate).unix();
          const expiry = moment(record.expiry).unix();
          const now = moment().unix();

          let tag = "";
          if (now >= startDate && now <= endDate && endDate === expiry) {
            tag = <Tag color={color.primary}>ACTIVE</Tag>;
          } else if (now < startDate && now < endDate && endDate === expiry) {
            tag = <Tag color={color.disabled}>PENDING</Tag>;
          } else if (now > startDate && now > endDate && endDate === expiry) {
            tag = <Tag color={color.accentDark}>EXPIRED</Tag>;
          } else if (now > expiry) {
            tag = <Tag color={color.danger}>DELETED</Tag>;
          } else {
            tag = <Tag color={color.disabled}>PENDING</Tag>;
          }
          return tag;
        },
      },
      {
        title: "Action",
        key: "operation",
        width: 200,
        render: (text, record) => {
          const startDate = moment(record.startDate).unix();
          const endDate = moment(record.endDate).unix();
          const expiry = moment(record.expiry).unix();
          const now = moment().unix();
          let btn = "";
          btn = (
            <div className="btn-grp">
              <Button
                onClick={() => {
                  this.showAdDetails(record);
                }}
                type={"default"}
              >
                View
              </Button>
              {now >= startDate && now <= endDate && endDate === expiry ? (
                <Button
                  onClick={() => {
                    this.editAd(record);
                  }}
                  type={"default"}
                >
                  Edit
                </Button>
              ) : (
                <></>
              )}
            </div>
            
          );
          return btn;
        },
      },
    ];

    this.state = {
      list: [],
      adAction: false,
      loading: true,
      mode: "ADD",
      adSelected: null,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
      this.getAdList
    );
  };

  componentWillMount() {
    this.getAdList();
  }

  getAdList = () => {
    let { pagination } = this.state;
    ajax("get", urlLinkPath.adList, { page: pagination.current }).then(
      ({ result }) => {
        pagination.total = result.count;
        this.setState({
          list: result.list,
          pagination: pagination,
          loading: false,
          adAction: false,
          adSelected: null,
          mode: "ADD",
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
      }
    );
  };

  addAd = () => {
    this.setState({
      adAction: true,
      adSelected: null,
      mode: "ADD",
    });
  };
  editAd = (record) => {
    this.setState({
      adAction: true,
      adSelected: record,
      mode: "EDIT",
    });
  };
  showAdDetails = (record) => {
    this.setState({
      adAction: true,
      adSelected: record,
      mode: "VIEW",
    });
  };

  closeViewMode = () => {
    this.setState({
      adAction: false,
      adSelected: {},
    });
  };
  render() {
    const { list, mode, pagination, adAction, loading, adSelected } =
      this.state;

    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>Ads</div>
        </div>

        <div className={"admin-module-body"}>
          <Button type={"primary"} size={"large"} onClick={this.addAd}>
            Add Ad
          </Button>
          <Table
            rowKey={"id"}
            loading={loading}
            columns={this.columns}
            pagination={pagination}
            dataSource={list}
          />
        </div>

        {adAction && (
          <Drawer width={520} visible={adAction} onClose={this.closeViewMode}>
            {
              {
                ADD: <AdAction updateAdList={this.getAdList} />,
                VIEW: <AdView ad={adSelected} updateAdList={this.getAdList} />,
                EDIT: <AdView ad={adSelected} mode={'EDIT'} updateAdList={this.getAdList} />,
              }[mode]
            }
          </Drawer>
        )}
      </div>
    );
  }
}

export default AdComponent;
