import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import {HashRouter, Redirect, Route, Switch} from 'react-router-dom';
import {authPaths, mainPaths, path} from './config';
import './index.scss';

import './axios';

import Auth from './components/Auth/AuthComponent';
import Main from './components/Main/MainComponent';
import NotFound from './components/NotFoundComponent';

const PrivateRoute = ({component: Component, ...rest}) => {
  const loginToken = localStorage.getItem('token');
  return (
    <Route
      {...rest}
      render={ props => (
        loginToken ? (
          <Component {...props} />
        ) : (
          <Redirect to={path.login}/>
        )
      )
      }
    />
  );
};


class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loginToken: localStorage.getItem('loginToken')
    }
  }

  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path={authPaths} component={Auth}/>
          <PrivateRoute exact path={mainPaths} component={Main}/>
          <Route component={NotFound}/>
        </Switch>
      </HashRouter>
    )
  }
}


ReactDOM.render(<App />, document.getElementById('root'));
