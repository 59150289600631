import React, {Component} from 'react';
import {Row, Col, Card, notification} from 'antd';
import ajax from '../../../../../services/ajax';
import { urlPath } from '../../../../../services/urlPath';

class Stats extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      referral: 0,
      referralByCode: 0,
      referralByDashboard: 0
    }
  }


  componentWillMount() {
    this.getStats();
  }

  getStats = () => {
    ajax('get', urlPath.marketDashboard).then(({result}) => {
      this.setState({
        loading: false,
        referral: result.referral,
        referralByCode: result.referralByCode,
        referralByDashboard: result.referralByDashboard,
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  render() {

    const { loading, referral, referralByCode, referralByDashboard} = this.state;

    return (
      <div className="stats">
        <Row gutter={12} >
          <Col xl={8} lg={8} md={8} sm={24}>
            <Card loading={loading}>
              <div className={'title'}>Total Business Referrals</div>
              <div className={'value'}>{referral}</div>
            </Card>
          </Col>
          <Col xl={8} lg={8} md={8} sm={24}>
            <Card loading={loading}>
              <div className={'title'}>By Dashboard</div>
              <div className={'value'}>{referralByDashboard}</div>
            </Card>
          </Col>
          <Col xl={8} lg={8} md={8} sm={24}>
            <Card loading={loading}>
              <div className={'title'}>By Referral</div>
              <div className={'value'}>{referralByCode}</div>
            </Card>
          </Col>
        </Row>
      </div>
    )
  }
}

export default Stats;