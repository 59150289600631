import React, {Component} from 'react';
import {Form, InputNumber,Row, Col,Button, notification} from 'antd';

import ajax from '../../../../services/ajax';
import { urlPath } from '../../../../services/urlPath';

class LevelCompletionRewardAction extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      memeberCount:4,
    }
  }

  submitForm = (ev) => {
    ev.preventDefault();
    const action = {
      ADD: this.addLevelReward,
      EDIT: this.updateLevelReward
    }[this.props.mode];
    action();
  };

  addLevelReward = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true
      });
      ajax('post', urlPath.ftMemberLevelReward,values).then((res) => {
        this.setState({
          loading: false
        });
        notification.success({
          message: 'Success',
          description: 'Level Reward added!'
        });
        this.props.updateLevelRewardList();
      }, (err) => {
        this.setState({
          loading: false
        });
        notification.error({
          message: 'Error',
          description: 'Something went wrong. Please try again!'
        });
      })
    })
  }

  updateLevelReward = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true
      });
      values.level = this.props.level.level;
      ajax('put', urlPath.ftMemberLevelReward,values).then((res) => {
        this.setState({
          loading: false
        });
        notification.success({
          message: 'Success',
          description: 'Level Reward updated!'
        });
        this.props.updateLevelRewardList();
      }, (err) => {
        this.setState({
          loading: false
        });
        notification.error({
          message: 'Error',
          description: 'Something went wrong. Please try again!'
        });
      })
    });
  }

  render() {

    const { loading,memeberCount } = this.state;
    const { mode,level} = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.submitForm}>
        <h2>Add Level Completion Rewards</h2>
        <Form.Item colon={false}>
              <Row>
                <Col lg={6} xs={8}>
                  <Form.Item label={"Level"} colon={false}>
                    {getFieldDecorator(`level`, {
                         initialValue: level && level.level,
                      rules: [
                        {
                          required: true,
                          pattern: new RegExp(/^[0-9]+$/),
                          message: `Please input  level value`,
                        },
                      ],
                      onChange: (value) => {
                        let count = 0;
                        for (var i = 1; i <= value; i++) {
                          count += Math.pow(4, i);
                        }
                        this.setState({ memeberCount: count });
                      },
                    })(<InputNumber disabled={mode !=='ADD'} size={"medium"} />)}
                  </Form.Item>
                </Col>
                <Col lg={6} xs={8}>
                  <Form.Item label={"Count"} colon={false}>
                    {getFieldDecorator(`count`, {
                      initialValue: mode !=='ADD'?level.count:memeberCount,
                    })(<InputNumber disabled={true} size={"medium"} />)}
                  </Form.Item>
                </Col>

                <Col lg={6} xs={8}>
                  <Form.Item label={"Price"} colon={false}>
                    {getFieldDecorator(`price`, {
                        initialValue: level && level.price,
                      rules: [
                        {
                          required: true,
                          message: `Please input  price value`,
                        },
                      ],
                    })(<InputNumber size={"medium"} min={0} />)}
                  </Form.Item>
                </Col>
                <Col lg={6} xs={8}>
                  <Form.Item label={"Digital Cash"} colon={false}>
                    {getFieldDecorator(`digitalCash`, {
                        initialValue: level && level.digitalCash,
                      rules: [
                        {
                          required: true,
                          message: `Please input  digital cash value`,
                        },
                      ],
                    })(<InputNumber size={"medium"} min={0} />)}
                  </Form.Item>
                </Col>
               
            
              </Row>
            </Form.Item>
        <div>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !=='ADD'}
            loading={loading}
          >
            Add Level Reward
          </Button>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !== 'EDIT'}
          >
            Update Level Reward
          </Button>
        </div>
      </Form>
    )
  }
}

export default Form.create()(LevelCompletionRewardAction)