import React, {Component} from 'react';
import {Button, Avatar, Row, Col, Divider, notification, Popconfirm} from 'antd';
import DescriptionItem from '../../_common/DescriptionItem';
import ajax from '../../../services/ajax';
import {fileBaseURL} from '../../../config';
import { urlLinkPath } from '../../../services/urlPath';

class BusinessAction extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleVisibility = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.business.id,
      status: !this.props.business.visible
    };
    ajax('post', urlLinkPath.businessVisibility,params).then((res) => {
      this.setState({
        loading: false
      });
      notification.success({
        message: 'Success',
        description: 'Admin status updated!'
      });
      this.props.updateBusinessList();
    }, ({msg}) => {
      this.setState({
        loading: false
      });
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  render() {

    const { loading } = this.state;
    const { business } = this.props;

    return (
      <div>
        <Row>
          <Col span={3}>
            <Avatar icon={'user'} size={'large'} src={`${fileBaseURL}/user/${business.user.id}.jpg`}/>
          </Col>
          <Col span={21}>
            <div className={'drawer-title'}>
              <h2>{business.name}</h2>
              <p>{business.businessCategory && business.businessCategory.name}</p>
            </div>
          </Col>
        </Row>

        <Divider/>
        <Row>
          <Col span={6}>
            <DescriptionItem title={'Rank'} content={business.rank} />
          </Col>
          <Col span={6}>
            <DescriptionItem title={'Rating'} content={business.avgRating} />
          </Col>
          <Col span={6}>
            <DescriptionItem title={'Review'} content={business.totalReviews} />
          </Col>
          <Col span={6}>
            <DescriptionItem title={'Suggestions'} content={business.totalSuggestions} />
          </Col>
        </Row>
        <Divider/>
        <Row>
          <Col span={12}>
            <DescriptionItem title={'Reg'} content={business.regNo} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'GSTN'} content={business.gstn} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title={'Email'} content={business.emailId} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'Phone'} content={business.phone} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title={'Website'} content={business.website} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'Address'} content={business.address} />
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <DescriptionItem title={'City'} content={business.city && business.city.name} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'State'} content={business.city && business.city.stateName} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title={'Service'} content={business.available ? 'AVAILABLE' : 'NOT AVAILABLE'} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'Account'} content={business.visible ? 'ACTIVE': 'BLOCKED'} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title={'Wallet Amt'} content={business.walletAmount} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'Discount Amt'} content={business.discountAmount} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title={'Plan Status'} content={business.planStatus} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'Plan Expiry'} content={business.planExpiry} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title={'User'} content={business.user && ( business.user.firstName + ' ' + business.user.lastName)} />
          </Col>
        </Row>
        <Divider />

        <div className={'btn-grp opposite drawer-footer'}>
          <Button
            size={'large'}
            onClick={this.handleVisibility}
            type={business && business.visible ? 'danger': 'primary'}
            loading={loading}
          >
            {business && business.visible ? 'Block Business': 'Activate Business'}
          </Button>
        </div>
      </div>
    )
  }
}

export default BusinessAction;
