import React, { Component } from "react";
import { notification, Button, Table, Tag, Drawer, Popconfirm } from "antd";
import ajax from "../../../services/ajax";
import{urlPath,urlLinkPath} from "../../../services/urlPath";
import { color } from "../../../config";
import ServiceAction from "./ServiceAction";

class ServiceComponent extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Name",
        width: 250,
        dataIndex: "name",
        key: "name",
        render: (text, record) => text,
      },
      {
        title: "Cost",
        width: 200,
        dataIndex: "cost",
        key: "cost",
        render: (text, record) =>record.cost
      },
      {
        title: "Business",
        width: 200,
        dataIndex: "business",
        key: "business",
        render: (text, record) => record.businessName
        
      },
      {
        title: "Status",
        width: 100,
        dataIndex: "visible",
        key: "visible",
        render: (text) =>
          text ? (
            <Tag color={color.primary}>ACTIVE</Tag>
          ) : (
            <Tag color={color.disabled}>BLOCKED</Tag>
          ),
      },
      {
        title: "Action",
        key: "operation",
        width: 250,
        render: (text, record) => (
          <div className={"btn-grp"}>
            <Button
              type={"default"}
              onClick={() => {
                this.editServiceDetails(record);
              }}
            >
              Edit
            </Button>

            <Popconfirm
              title={"Are you sure?"}
              okText={"YES"}
              okType={"danger"}
              onConfirm={() => {
                this.deleteService(record);
              }}
            >
              <Button type={"danger"}>Delete</Button>
            </Popconfirm>
          </div>
        ),
      },
    ];

    this.state = {
      list: [],
      loading: true,
      mode: "ADD",
      viewService: false,
      selectedService: {},
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }

  componentWillMount() {
    this.getServices();
  }
  addService = () => {
    this.setState({
      viewService: true,
      mode: "ADD",
    });
  };

  editServiceDetails = (service) => {
    this.setState({
      viewService: true,
      mode: "EDIT",
      selectedService: service,
    });
  };

  closeViewMode = () => {
    this.setState({
      viewService: false,
      selectedService: {},
    });
  };

  getServices = () => {
    let { pagination } = this.state;
    ajax("get", urlPath.service, { page: pagination.current }).then(
      ({ result }) => {
        pagination.total = result.count;
        this.setState({
          viewService: false,
          loading: false,
          pagination: pagination,
          selectedService: {},
          mode: "ADD",
          list: result.list,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          viewService: false,
          loading: false,
          selectedService: {},
          mode: "ADD",
          list: [],
        });
      }
    );
  };

  deleteService = (service) => {
    ajax("delete",urlPath.service, { id: service.id }).then(
      () => {
     
        notification.success({
          message: "Success",
          description: "Service deleted!",
        });
        this.getServices();
      },
      (err) => {
        notification.error({
          message: "Error",
          description: err && err.msg,
        });
      }
    );
  };
  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
      this.getServices
    );
  };

  render() {
    const { list, loading, pagination, viewService, mode, selectedService } =
      this.state;

    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>Service</div>
        </div>

        <div className={"admin-module-body"}>
          <Button type={"primary"} size={"large"} onClick={this.addService}>
            Add Service
          </Button>
          <Table
            rowKey={"id"}
            loading={loading}
            columns={this.columns}
            pagination={pagination}
            dataSource={list}
          />
        </div>

        {viewService && (
          <Drawer width={520} visible={viewService} onClose={this.closeViewMode}>
            <ServiceAction
              mode={mode}
              service={selectedService}
              updateServiceList={this.getServices}
            />
          </Drawer>
        )}
      </div>
    );
  }
}

export default ServiceComponent;
