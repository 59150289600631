import React, { Component } from "react";
import {
  notification,
  Button,
  Row,
  Col,
  Select,
  DatePicker,
  Table,
  Tag,
  Drawer,
} from "antd";
import moment from "moment";
import ajax from "../../../services/ajax";
import { color, apiBaseURL } from "../../../config";
import { strigifyQuery } from "../../../services/queryHelper";

import TransactionAction from "./TransactionAction";
import { urlPath } from "../../../services/urlPath";

const { RangePicker } = DatePicker;

class TransactionComponent extends Component {
  constructor(props) {
    super(props);
    this.authorization = localStorage.getItem("token");

    this.columns = [
      {
        title: "Txn ID",
        dataIndex: "txnid",
        key: "txnid",
        render: (text) => `#${text}`,
      },
      {
        title: "Business",
        dataIndex: "business",
        key: "business",
        render: (text, { business }) => business && business.name,
      },
      {
        title: "User",
        dataIndex: "user",
        key: "user",
        render: (text, { user }) => `${user.firstName} ${user.lastName}`,
      },
      {
        title: "Plan",
        dataIndex: "plan",
        key: "plan",
        render: (text, { subscriptionPlan }) => subscriptionPlan==null?'':subscriptionPlan.name,
      },
      {
        title: "Ad",
        dataIndex: "ad",
        key: "ad",
        render: (text, { ad }) => ad==null?'':ad.title,
      },
      {
        title: "Amount",
        dataIndex: "totalAmount",
        key: "totalAmount",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text) =>
          ({
            PENDING: <Tag color={color.disabled}>PENDING</Tag>,
            SUCCESS: <Tag color={color.success}>SUCCESS</Tag>,
            FAILURE: <Tag color={color.danger}>FAILURE</Tag>,
            CANCELLED: <Tag color={color.disabled}>CANCELLED</Tag>,
          }[text]),
      },
      {
        title: "Time",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => moment(text).format("DD-MM-YYYY HH:mm"),
      },
      {
        title: "Action",
        key: "operation",
        render: (text, record) => (
          <Button
            onClick={() => {
              this.showTransactionDetails(record);
            }}
          >
            View
          </Button>
        ),
      },
    ];

    this.state = {
      list: [],
      loading: true,
      viewTransaction: false,
      txnVia: null,
      txnType: null,
      selectedTransaction: {},
      downloadLink: "",
      dateRange: [moment().subtract(6, "d"), moment()],
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }

  componentWillMount() {
    this.getTransactions();
  }

  showTransactionDetails = (transaction) => {
    this.setState({
      viewTransaction: true,
      selectedTransaction: transaction,
    });
  };
  
  dateRangeChange = (range) => {
    if (!(range[0] && range[1])) {
      range = [moment().subtract(7, "days"), moment()];
    }
    this.setState(
      {
        dateRange: range,
      },
      this.getTransactions
    );
  };

  closeViewMode = () => {
    this.setState({
      viewTransaction: false,
      selectedTransaction: {},
    });
  };

  getTransactions = () => {
    let { pagination, dateRange, txnVia, txnType } = this.state;
    const params = {
      startDate: dateRange[0].format("YYYY-MM-DD"),
      endDate: dateRange[1].format("YYYY-MM-DD"),
      txnVia: txnVia,
      txnType: txnType,
      page: pagination.current,
    };
    ajax("post", urlPath.transaction, params).then(
      ({ result }) => {
        pagination.total = result.count;
        const link = strigifyQuery({
          authorization: this.authorization,
          startDate: dateRange[0].format("YYYY-MM-DD"),
          endDate: dateRange[1].format("YYYY-MM-DD"),
          txnVia: txnVia,
          txnType: txnType,
        });
        this.setState({
          downloadLink: `${apiBaseURL}/transaction/download${link}`,
          viewTransaction: false,
          list: result.list,
          pagination: pagination,
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          viewTransaction: false,
          list: [],
          loading: false,
        });
      }
    );
  };

  handleTxnVia = (value) => {
    this.setState(
      {
        txnVia: value || null,
      },
      this.getTransactions
    );
  };

  handleTxnType = (value) => {
    this.setState(
      {
        txnType: value || null,
      },
      this.getTransactions
    );
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
      this.getTransactions
    );
  };

  render() {
    const {
      list,
      loading,
      pagination,
      viewTransaction,
      downloadLink,
      dateRange,
      selectedTransaction,
    } = this.state;

    return (
      <div>
        <div className={"header"}>
          <div className={"page-title"}>Transaction</div>
        </div>
        <div className="spacer" />
        <Row gutter={16}>
          <Col lg={8} md={10} sm={24}>
            <RangePicker
              value={dateRange}
              format={"DD/MM/YYYY"}
              separator={"to"}
              onChange={this.dateRangeChange}
            />
          </Col>
          <Col lg={8} md={10} sm={24}>
            <Select
              style={{ width: "100%" }}
              placeholder={"Txn Via"}
              allowClear
              onChange={this.handleTxnVia}
            >
              <Select.Option value={"APP"}>App</Select.Option>
              <Select.Option value={"REFERRAL"}>Referral</Select.Option>
            </Select>
          </Col>
          <Col lg={8} md={10} sm={24}>
            <Select
              style={{ width: "100%" }}
              placeholder={"Txn Type"}
              allowClear
              onChange={this.handleTxnType}
            >
              <Select.Option value={"FRESH"}>Fresh</Select.Option>
              <Select.Option value={"RENEWAL"}>Renewal</Select.Option>
            </Select>
          </Col>
        </Row>
        <div className="spacer" />
        <Table
          rowKey={"id"}
          loading={loading}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />

        {viewTransaction && (
          <Drawer
            width={520}
            visible={viewTransaction}
            onClose={this.closeViewMode}
          >
            <TransactionAction txn={selectedTransaction} />
          </Drawer>
        )}

        <div className={"spacer"} />
        <a href={downloadLink} target={"_blank"} disabled={list.length === 0}>
          <Button>Download Report</Button>
        </a>
      </div>
    );
  }
}

export default TransactionComponent;
