import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { path } from '../../config';

import logo from '../../images/logo_white.png';

import Login from './subcomponent/Login';
import ResetPassword from "./subcomponent/ResetPassword";

class Auth extends Component {

  render() {

    return (
      <>
        <main className={'auth'}>
          <div className={'logo'}>
            <img src={logo} alt={'logo'}/>
          </div>

          <Switch>
            <Route path={path.login} component={Login}/>
            <Route path={path.reset} component={ResetPassword}/>
          </Switch>
        </main>
      </>
    );
  }
}

export default Auth;