import React, {Component} from 'react';
import {notification, Button, Table, Tag, Drawer} from 'antd';
import moment from 'moment';
import ajax from '../../../services/ajax';
import {color} from '../../../config';
import { urlPath } from '../../../services/urlPath';
// import TransactionAction from "./TransactionAction";

class WalletTransferComponent extends Component{
  constructor(props){
    super(props);

    this.columns = [
      {
        title: 'Sender',
        dataIndex: 'sender',
        key: 'sender',
        render: (text, {sender}) => sender && sender.business?.name
      },
      {
        title: 'Receiver',
        dataIndex: 'receiver',
        key: 'receiver',
        render: (text, {receiver}) => receiver&& receiver.business && receiver.business.name
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => ({
          APPROVED: <Tag color={color.success}>APPROVED</Tag>,
          REJECTED: <Tag color={color.danger}>REJECTED</Tag>,
          FAILED: <Tag color={color.danger}>FAILED</Tag>,
          GENERATED: <Tag>GENERATED</Tag>,
          SCANNED: <Tag>SCANNED</Tag>,
          REQUESTED: <Tag>REQUESTED</Tag>,
        }[text])
      },
      {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) =>  moment(text).format('DD-MM-YYYY HH:mm')
      },
      {
        title: 'Action',
        key: 'operation',
        render: (text, record) => <Button onClick={() => { this.showTransactionDetails(record)}} >View</Button>,
      },
    ];

    this.state = {
      list: [],
      loading: true,
      viewTransaction: false,
      selectedTransaction: {},
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    };
  }

  componentWillMount() {
    this.getTransactions();
  }

  showTransactionDetails = (transaction) => {
    this.setState({
      viewTransaction: true,
      selectedTransaction: transaction
    })
  };

  closeViewMode = () => {
    this.setState({
      viewTransaction: false,
      selectedTransaction: {}
    })
  }

  getTransactions = () => {
    let { pagination } = this.state;
    ajax('get', urlPath.walletTransfer,{ page: pagination.current}).then(({result}) => {
      pagination.total = result.count;
      this.setState({
        viewTransaction: false,
        pagination: pagination,
        list: result.list,
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        viewTransaction: false,
        list: [],
        loading: false
      })
    })
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getTransactions)
  };

  render(){

    const {list, loading, pagination, viewTransaction} = this.state;

    return(
      <div>
        <div className={'header'}>
          <div className={'page-title'}>B2B Wallet Amount Transfers</div>
        </div>
        <Table
          rowKey={'id'}
          loading={loading}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />

        {
          viewTransaction && (
            <Drawer
              width={520}
              visible={viewTransaction}
              onClose={this.closeViewMode}
            >
              {/*<TransactionAction transaction={selectedTransaction} updateTransactionList={this.getTransactiones} />*/}
            </Drawer>
          )
        }
      </div>
    )
  }
}

export default WalletTransferComponent;
