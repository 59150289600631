import React, {Component} from 'react';
import {notification, Popconfirm, Button, Table, Drawer, Tag, Modal, Icon} from 'antd';
import ajax from '../../../../services/ajax';
import { color } from '../../../../config';

import AddAppVersion from './AddAppVersion';
import UpdateAppVersion from './UpdateAppVersion';
import { urlLinkPath ,urlPath} from '../../../../services/urlPath';

const { confirm } = Modal;

class AppVersionComponent extends Component{

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Version',
        dataIndex: 'version',
        key: 'version',
      },
      {
        title: 'Version Code',
        dataIndex: 'code',
        key: 'code',
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        render: (text) => <Tag color={ text === 'HARD' ? color.accentDark : color.accent}>{text}</Tag>

      },
      {
        title: 'Platform',
        dataIndex: 'platform',
        key: 'platform',
        render: (text) => ({
          ANDROID: <Icon style={{fontSize: 28, color: '#A4C639'}} type={'android'} theme={'filled'} />,
          IOS: <Icon style={{fontSize: 28, color: '#8E8E93'}} type={'apple'} theme={'filled'} />,
        }[text])

      },
      {
        title: 'Status',
        dataIndex: 'status',
        key: 'status',
        render: (text) => ({
          PENDING: <Tag>PENDING</Tag>,
          LIVE: <Tag color={color.success}>ACTIVE</Tag>,
          ARCHIVED: <Tag color={color.disabled}>ARCHIVED</Tag>
        }[text])
      },
      {
        title: 'Action',
        key: 'operation',
        render: (text, record) => (
          <div className={'btn-grp'}>
            <Button onClick={() => {this.selectVersion(record)}}>Edit</Button>
            <Popconfirm
              title={'Are you sure?'}
              okText={'YES'}
              okType={'danger'}
              onConfirm={() => { this.deleteAppVersion(record)}}
            >
              <Button type={'danger'}>Delete</Button>
            </Popconfirm>
            <Button
              onClick={() => { this.confirmMarkActive(record)}}
              type={'primary'}
            >
              Make Live
            </Button>
          </div>
        )
      },
    ];

    this.state = {
      loading: true,
      list: [],
      roles: [],
      addVersion: false,
      updateVersion: false,
      appVersion: {}
    }
  }

  componentWillMount() {
    this.getAppVersionList();
  }

  confirmMarkActive = (record) => {
    confirm({
      title: `Are you sure to mark version ${record.version} LIVE?`,
      content: 'Please make sure updated app is on play-store!',
      onOk: () => {
        this.markActive(record);
      }
    });
  }

  selectVersion = (record) => {
    this.setState({
      appVersion: record,
      updateVersion: true
    })
  }

  markActive = (appVersion) => {
    const params = {
      id: appVersion.id,
    };
    ajax('post', urlLinkPath.appVersionMakeLive,params).then((res) => {
      notification.success({
        message: 'Success',
        description: 'New App Version is set!'
      });
      this.getAppVersionList();
    }, (err) => {
      notification.error({
        message: 'Error',
        description: err && err.msg
      });
    })
  }

  getAppVersionList = () => {
    ajax('get',`${urlPath.appVersion}/`,{}).then(({list}) => {
      this.setState({
        list: list,
        loading: false,
        addVersion: false,
        updateVersion: false
      })
    }, (err) => {
      notification.error({
        message: 'Error',
        description: err && err.msg
      });
      this.setState({
        list: [],
        loading: false,
        addVersion: false,
      })
    })
  }

  deleteAppVersion = (appVersion) => {
    ajax('delete', `${urlPath.appVersion}/`,{id: appVersion.id}).then(() => {
      notification.success({
        message: 'Success',
        description: 'App Version deleted!'
      })
      this.getAppVersionList();
    }, (err) => {
      notification.error({
        message: 'Error',
        description: err && err.msg
      });
    })
  }

  addAppVersion = () => {
    this.setState({
      addVersion: true,
    })
  };


  closeAddMode = () => {
    this.setState({
      addVersion: false,
    })
  };

  closeUpdateMode = () => {
    this.setState({
      updateVersion: false,
    })
  };

  render(){

    const {list, loading, addVersion, updateVersion, appVersion} = this.state;

    return(
      <div className={'admin-module'}>
        <div className={'header'}>
          <div className={'page-title'}>App Versions</div>
        </div>

        <div className={'admin-module-body'}>
          <Button type={'primary'} size={'large'} onClick={this.addAppVersion}>Add App Version</Button>
          <Table
            rowKey={'id'}
            loading={loading}
            columns={this.columns}
            pagination={false}
            dataSource={list}/>
        </div>

        {
          addVersion && (
            <Drawer
              width={520}
              visible={addVersion}
              onClose={this.closeAddMode}
            >
              <AddAppVersion updateVersionList={this.getAppVersionList}/>
            </Drawer>
          )
        }
        {
          updateVersion && (
            <Drawer
              width={520}
              visible={updateVersion}
              onClose={this.closeUpdateMode}
            >
              <UpdateAppVersion appVersion={appVersion} updateVersionList={this.getAppVersionList}/>
            </Drawer>
          )
        }

      </div>
    )
  }
}

export default AppVersionComponent;