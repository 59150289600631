import React, {useState, useMemo} from 'react';
import JoditEditor from "jodit-react";

const JoEditor = ({value, setHtml, submitForm}) => {
    const [content, setContent] = useState(value)

    const config = {
        readonly: false, // all options from https://xdsoft.net/jodit/doc/
        extraButtons: 
            [
                {
                    name: 'save',
                    iconURL: '',
                    exec: (editor, control, e) => {
                        submitForm();
                    }
                }
            ]
    }

    return useMemo( 
        () => ( 
            <JoditEditor value={content} config={config} onChange={content => {
                // console.log(content.replace(/\\n/g, '').trim());
                setHtml(content)
            }} /> 
        ), [] 
    );
}
export default JoEditor;