import React, { Component } from "react";
import { notification, Input, Col, Row, Form } from "antd";
import ajax from "../../services/ajax";
import { urlLinkPath } from "../../services/urlPath";
import { USER_TYPE } from "../../services/constants";
class SearchBox extends Component {
  constructor(props) {
    super(props);
  }

  search = (e) => {
    let { pagination, userType } = this.props;
    const httpUrl =
      e.target.id === "level" || e.target.id === "parentPhone"
        ? urlLinkPath.userMembers
        : urlLinkPath.searchUsers;
    ajax("get", httpUrl, {
      search: e.target.value,
      userType: userType,
      searchBy:
        e.target.id === "level"
          ? "level"
          : e.target.id === "parentPhone"
          ? "parentPhone"
          : "userDetails",
    }).then(
      ({ result }) => {
        pagination.total = result.count;
        this.props.searchBoxCallback(result.list);
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.props.searchBoxCallback([]);
      }
    );
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { userType } = this.props;

    return (
      <div>
        <div className="spacer" />
        <Form>
          <Row gutter={16}>
            <Col lg={8} md={10} sm={24}>
              <Form.Item>
                {getFieldDecorator(
                  "name",
                  {}
                )(
                  <Input
                    placeholder={`${userType} NAME`}
                    onChange={this.search}
                  />
                )}
              </Form.Item>
            </Col>
            <Col lg={8} md={10} sm={24}>
              <Form.Item>
                {getFieldDecorator("phone", {
                  rules: [
                    {
                      pattern: /^([789])[0-9]{9}$/,
                      message:
                        "Please enter valid 10 digit Indian Phone Number!",
                    },
                  ],
                })(
                  <Input placeholder={"897 XXX XXXX"} onChange={this.search} />
                )}
              </Form.Item>
            </Col>
            {userType == USER_TYPE.FTMEMBER ? (
              <>
                <Col lg={8} md={10} sm={24}>
                  <Form.Item>
                    {getFieldDecorator("level", {
                      rules: [
                        {
                          pattern: /^[0-9]{1}$/,
                          message: "Please enter valid Level!",
                        },
                      ],
                    })(<Input placeholder={"Level"} onChange={this.search} />)}
                  </Form.Item>
                </Col>
                <Col lg={8} md={10} sm={24}>
                  <Form.Item>
                    {getFieldDecorator("parentPhone", {
                      rules: [
                        {
                          pattern: /^([789])[0-9]{9}$/,
                          message:
                            "Please enter valid 10 digit Indian Phone Number!",
                        },
                      ],
                    })(
                      <Input
                        placeholder={"Parent Phone 897 XXX XXXX"}
                        onChange={this.search}
                      />
                    )}
                  </Form.Item>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
        </Form>
      </div>
    );
  }
}

export default Form.create()(SearchBox);
