import React, {Component} from 'react';
import {Form, Input, Select, Button, notification, Popconfirm} from 'antd';

import ajax from '../../../../services/ajax';
import { urlLinkPath, urlPath } from '../../../../services/urlPath';

class AdvisorAction extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  handleSuccess = (msg) => {
    this.setState({
      loading: false
    });
    notification.success(msg);
    this.props.updateAdminList();
  }

  handleError = (msg) => {
    this.setState({
      loading: false
    });
    notification.error({
      message: 'Error',
      description: msg
    });
  }

  submitForm = (ev) => {
    ev.preventDefault();
    const action = {
      ADD: this.addAdmin,
      EDIT: this.updateAdmin
    }[this.props.mode];
    action();
  };

  addAdmin = () => {
    this.props.form.validateFields((err, values) => {
      if(err) return;
      this.setState({
        loading: true
      });
      ajax('post', urlPath.advisor,values).then((res) => {
        this.handleSuccess({
          message: 'Success',
          description: 'Admin added!'
        })
      }, ({msg}) => {
        this.handleError(msg);
      })
    })
  }

  updateAdmin = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;

      this.setState({
        loading: true
      });
      values.id = this.props.admin.id;
      ajax('put', urlPath.advisor, values).then((res) => {
        this.handleSuccess({
          message: 'Success',
          description: 'Advisor updated!'
        });
      }, ({msg}) => {
        this.handleError(msg);
      })
    });
  }

  handleVisibility = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.admin.id,
      status: !this.props.admin.visible
    };
    ajax('post', urlLinkPath.advisorVisibility,params).then((res) => {
      this.handleSuccess({
        message: 'Success',
        description: 'Advisor status updated!'
      });
    }, ({msg}) => {
      this.handleError(msg);
    })
  }

  deleteAdmin = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.admin.id
    };
    ajax('delete', urlPath.advisor,params).then((res) => {
      this.handleSuccess({
        message: 'Success',
        description: 'Advisor deleted!'
      });
    }, ({msg}) => {
      this.handleError(msg);
    })
  }

  render() {

    const { loading } = this.state;
    const { admin, mode } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.submitForm}>
        <h2>Advisor Details</h2>
        <Form.Item label={'First Name'} colon={false}>
          {
            getFieldDecorator('firstName', {
              initialValue: admin && admin.firstName,
              rules: [{
                required: true,
                message: 'Please input first name for admin!'
              }]
            })(<Input size={'large'}/>)
          }
        </Form.Item>
        <Form.Item label={'Last Name'} colon={false}>
          {
            getFieldDecorator('lastName', {
              initialValue: admin && admin.lastName,
              rules: [{
                required: true,
                message: 'Please input last name for admin!'
              }]
            })(<Input size={'large'}/>)
          }
        </Form.Item>
        <Form.Item label={'Phone'} colon={false}>
          {
            getFieldDecorator('phone', {
              initialValue: admin && admin.phone,
              rules: [{
                required: true,
                message: 'Please input phone number for admin!'
              }]
            })(<Input size={'large'}/>)
          }
        </Form.Item>
        <div>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !=='ADD'}
            loading={loading}
          >
            Add Admin
          </Button>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !== 'EDIT'}
          >
            Update Admin
          </Button>
        </div>

        <div className={'btn-grp opposite drawer-footer'} hidden={mode==='ADD'}>
          <Button
            size={'large'}
            onClick={this.handleVisibility}
            type={admin && admin.visible ? 'danger': 'primary'}
            loading={loading}
          >
            {admin && admin.visible ? 'Block': 'Activate'}
          </Button>
          <Popconfirm
            placement={'topRight'}
            title={'Are you sure you wish to delete this ADVISOR?'}
            onConfirm={this.deleteAdmin}
            okText={'YES! Delete Advisor'} okType={'danger'}
            cancelText={'NO!'}
          >
            <Button size={'large'} type={'danger'} loading={loading}>Delete</Button>
          </Popconfirm>
        </div>
      </Form>
    )
  }
}

export default Form.create()(AdvisorAction)