import React, {Component} from 'react';
import {notification, Table, Button, Row, Col, Tag, DatePicker, Card, Select} from 'antd';
import moment from 'moment';
import ajax from '../../../services/ajax';
import {color, apiBaseURL} from '../../../config';
import { strigifyQuery } from '../../../services/queryHelper';
import { urlLinkPath, urlPath } from '../../../services/urlPath';
const { RangePicker } = DatePicker;

class ChiefPromoterReport extends Component{
  constructor(props){
    super(props);

    this.authorization = localStorage.getItem('token');

    this.columns = [
      {
        title: 'Executive',
        dataIndex: 'executive',
        key: 'executive',
        render: (text, record) => record.admin && (record.admin.firstName + ' (' + record.admin.referralCode + ')')
      },
      {
        title: 'Business',
        dataIndex: 'business',
        key: 'business',
        render: (text, record) => record.business && record.business.name
      },
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        render: (text, record) => record.user && `${record.user.firstName} ${record.user.lastName}`
      },
      {
        title: 'Type',
        dataIndex: 'referralType',
        key: 'referralType',
        render: (text) => ({
          DASHBOARD: <Tag color={color.primary}>DASHBOARD</Tag>,
          REFERRAL_CODE: <Tag color={color.accentDark}>REFERRAL CODE</Tag>,
        }[text])
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: 'Created on',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) =>  moment(text).format('DD-MM-YYYY HH:mm')
      },
    ];

    this.state = {
      list: [],
      chiefCoordinators: [],
      seniorCoordinators: [],
      coordinators: [],
      executives: [],
      selectedChiefCoordinator: null,
      selectedSeniorCoordinator: null,
      selectedCoordinator: null,
      selectedExecutive: null,
      loading: true,
      viewReferral: false,
      selectedReferral: {},
      dateRange: [moment().subtract(6,'d'), moment()],
      downloadLink: '',
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    };
  }

  componentWillMount() {
    this.getChiefCoordinatorList();
    this.getReferralReport();
  }

  getChiefCoordinatorList = () => {
    ajax('get', urlLinkPath.chiefCoordinatorList,{}).then(({list}) => {
      this.setState({
        chiefCoordinators: list,
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  getSeniorCoordinatorList = () => {
    ajax('get', `${urlLinkPath.chiefCoordinatorSeniorCoordinator}/${this.state.selectedChiefCoordinator}`,{}).then(({list}) => {
      this.setState({
        seniorCoordinators: list,
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }


  getCoordinatorList = () => {
    ajax('get', `${urlLinkPath.chiefCoordinatorCoordinator}/${this.state.selectedSeniorCoordinator}`,{}).then(({list}) => {
      this.setState({
        coordinators: list,
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  getExecutiveList = () => {
    if(!this.state.selectedCoordinator) return;
    ajax('get', `${urlLinkPath.chiefCoordinatorExecutives}/${this.state.selectedCoordinator}`,{}).then(({list}) => {
      this.setState({
        executives: list,
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  dateRangeChange = (range) => {
    if(!(range[0] && range[1])){
      range = [moment().subtract(7,'days'), moment()];
    }
    this.setState({
      dateRange: range
    }, this.getReferralReport)
  };

  getReferralReport = () => {
    let {
      selectedChiefCoordinator,
      selectedSeniorCoordinator,
      selectedCoordinator,
      selectedExecutive,
      dateRange, pagination } = this.state;

    const params = {
      chiefCoordinatorId: selectedChiefCoordinator,
      seniorCoordinatorId: selectedSeniorCoordinator,
      coordinatorId: selectedCoordinator,
      executiveId: selectedExecutive,
      startDate: dateRange[0].format('YYYY-MM-DD'),
      endDate: dateRange[1].format('YYYY-MM-DD'),
      page: pagination.current
    };
    ajax('post', urlPath.marketReport,params).then(({result}) => {
      pagination.total = result.count;

      const link = strigifyQuery({
        authorization: this.authorization,
        chiefCoordinatorId: selectedChiefCoordinator,
        seniorCoordinatorId: selectedSeniorCoordinator,
        coordinatorId: selectedCoordinator,
        executiveId: selectedExecutive,
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
      });

      this.setState({
        downloadLink: `${apiBaseURL}${urlPath.marketReport}/download${link}`,
        list: result.list,
        pagination: pagination,
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        list: [],
        loading: false
      })
    })
  };

  handleChiefCoordinator = (id) => {
    this.setState({
      selectedChiefCoordinator: id || null,
      selectedSeniorCoordinator: null,
      selectedCoordinator: null,
      selectedExecutive: null,
    }, () => {
      this.getSeniorCoordinatorList();
      this.getReferralReport();
    })
  }

  handleSeniorCoordinator = (id) => {
    this.setState({
      selectedSeniorCoordinator: id || null,
      selectedCoordinator: null,
      selectedExecutive: null,
    }, () => {
      this.getCoordinatorList();
      this.getReferralReport();
    })
  }

  handleCoordinator = (id) => {
    this.setState({
      selectedCoordinator: id || null,
      selectedExecutive: null
    }, () => {
      this.getExecutiveList();
      this.getReferralReport();
    })
  }

  handleExecutive = (id) => {
    this.setState({
      selectedExecutive: id || null
    }, this.getReferralReport)
  }

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getReferralReport)
  };

  render(){

    const {
      list,
      dateRange,
      chiefCoordinators,
      seniorCoordinators,
      coordinators,
      executives,
      loading, pagination, downloadLink} = this.state;

    const optionsChiefCoordinator = chiefCoordinators.map((ccoor) => {
      return <Select.Option key={ccoor.id} value={ccoor.id}>{ccoor.firstName} {' ('} {ccoor.referralCode} {')'}</Select.Option>
    });

    const optionsSeniorCoordinator = seniorCoordinators.map((scoor) => {
      return <Select.Option key={scoor.id} value={scoor.id}>{scoor.firstName} {' ('} {scoor.referralCode} {')'}</Select.Option>
    });

    const optionsCoordinator = coordinators.map((coor) => {
      return <Select.Option key={coor.id} value={coor.id}>{coor.firstName} {' ('} {coor.referralCode} {')'}</Select.Option>
    });

    const optionsExecutive = executives.map((exec) => {
      return <Select.Option key={exec.id} value={exec.id}>{exec.firstName} {' ('} {exec.referralCode} {')'}</Select.Option>
    });

    return(
      <div>
        <div className={'header'}>
          <div className={'page-title'}>Report</div>
        </div>
        <div className="spacer"/>
        <Row gutter={16}>
          <Col lg={4} md={6} sm={24}>
            <Select style={{width: '100%'}} placeholder={'Chief Coordinator'} allowClear onChange={this.handleChiefCoordinator}>
              {optionsChiefCoordinator}
            </Select>
          </Col>
          <Col lg={4} md={6} sm={24}>
            <Select style={{width: '100%'}} placeholder={'Senior Coordinator'} allowClear onChange={this.handleSeniorCoordinator}>
              {optionsSeniorCoordinator}
            </Select>
          </Col>
          <Col lg={4} md={6} sm={24}>
            <Select style={{width: '100%'}} placeholder={'Coordinator'} allowClear onChange={this.handleCoordinator}>
              {optionsCoordinator}
            </Select>
          </Col>
          <Col lg={4} md={6} sm={24}>
            <Select style={{width: '100%'}} placeholder={'Executive'} allowClear onChange={this.handleExecutive}>
              {optionsExecutive}
            </Select>
          </Col>
        </Row>
        <div className="spacer"/>
        <Card className={'table-card'}
              extra={(
                <RangePicker
                  value={dateRange}
                  format={'DD/MM/YYYY'}
                  separator={'to'}
                  onChange={this.dateRangeChange}
                />
              )}
        >
          <Table
            rowKey={'id'}
            loading={loading}
            columns={this.columns}
            pagination={pagination}
            dataSource={list}
          />
        </Card>

        <div className={'spacer'}/>
        <a href={downloadLink} target={'_blank'} disabled={list.length === 0}><Button>Download Report</Button></a>
      </div>
    )
  }
}

export default ChiefPromoterReport;

