import React, { Component } from "react";
import { Steps, Card } from "antd";
import SendSms from "./subcomponents/SendSms";

class AddFtMemberComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      step: 0,
      user: null,
      business: null,
      businessCategory: null,
      transaction: null,
    };
  }

  getPhoneNumber = (user) => {
    this.setState({
      step: 1,
      user: user,
    });
  };

  render() {
    const { user } = this.state;
    return (
      <div className={"add-business"}>
        <div className={"header"}>
          <div className={"page-title"}>Add FT Members</div>
          <Card>
            <SendSms user={user} nextStep={this.getPhoneNumber} />
          </Card>
        </div>
      </div>
    );
  }
}

export default AddFtMemberComponent;
