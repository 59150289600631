import React, {Component} from 'react';
import {Form, Input, Button, notification} from 'antd';

import ajax from '../../../../services/ajax';
import { urlPath } from '../../../../services/urlPath';

class MasterCategoryAction extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }

  submitForm = (ev) => {
    ev.preventDefault();
    const action = {
      ADD: this.addMasterCategory,
      EDIT: this.updateMasterCategory
    }[this.props.mode];
    action();
  };

  addMasterCategory = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true
      });

      ajax('post', urlPath.masterBusinessCategory,values).then((res) => {
        this.setState({
          loading: false
        });
        notification.success({
          message: 'Success',
          description: 'Master Business Category added!'
        });
        this.props.updateMasterCategoryList();
      }, (err) => {
        this.setState({
          loading: false
        });
        notification.error({
          message: 'Error',
          description: 'Something went wrong. Please try again!'
        });
      })
    })
  }

  updateMasterCategory = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true
      });
      values.id = this.props.category.id;
      ajax('put', urlPath.masterBusinessCategory,values).then((res) => {
        this.setState({
          loading: false
        });
        notification.success({
          message: 'Success',
          description: 'Master Business Category added!'
        });
        this.props.updateMasterCategoryList();
      }, (err) => {
        this.setState({
          loading: false
        });
        notification.error({
          message: 'Error',
          description: 'Something went wrong. Please try again!'
        });
      })
    });
  }

  render() {

    const { loading } = this.state;
    const { mode, category} = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.submitForm}>
        <h2>Add Master Business Category</h2>
        <Form.Item label={'Name'} colon={false}>
          {
            getFieldDecorator('name', {
              initialValue: category && category.name,
              rules: [{
                required: true,
                message: 'Please input Name!'
              }]
            })(<Input size={'large'}/>)
          }
        </Form.Item>
        <div>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !=='ADD'}
            loading={loading}
          >
            Add Module
          </Button>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !== 'EDIT'}
          >
            Update Module
          </Button>
        </div>
      </Form>
    )
  }
}

export default Form.create()(MasterCategoryAction)