import React, { Component } from "react";
import { notification, Button, Table, Tag, Drawer } from "antd";
import moment from "moment";
import ajax from "../../../services/ajax";
import FtMemberAction from "./FtMemberAction";
import { urlLinkPath } from "../../../services/urlPath";
import { USER_TYPE } from "../../../services/constants";
import SearchBox from "../../_common/SearchBox";
import { color } from "../../../config";

class FtMemberComponent extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Name",
        width: 200,
        dataIndex: "name",
        key: "name",
        render: (text, record) =>
          `${record.userInfo.firstName} ${record.userInfo.lastName}`,
      },
      {
        title: "Parent Name",
        width: 200,
        dataIndex: "parentName",
        key: "parentName",
        render: (text, record) =>
          `${
            record.parentUser
              ? `${record.parentUser?.firstName} ${record.parentUser?.lastName}`
              : "ROOT USER"
          }`,
      },
      {
        title: "Phone",
        width: 100,
        dataIndex: "phone",
        key: "phone",
        render: (text, record) => `${record.userInfo.phone}`,
      },
      {
        title: "Level",
        width: 100,
        dataIndex: "hierarchyLevel",
        key: "hierarchyLevel",
        render: (text) => text,
      },
      {
        title: "Plan Status",
        width: 100,
        dataIndex: "planStatus",
        key: "planStatus",
        render: (text) =>
          ({
            NONE: <Tag color={color.disabled}>NONE</Tag>,
            ACTIVE: <Tag color={color.success}>ACTIVE</Tag>,
            EXPIRED: <Tag color={color.danger}>EXPIRED</Tag>,
          }[text]),
      },
      {
        title: "Plan Exoiry",
        width: 100,
        dataIndex: "planExpiry",
        key: "planExpiry",
      },
      {
        title: "Created on",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        render: (text) => moment(text).format("DD-MM-YYYY"),
      },
    ];

    this.state = {
      list: [],
      viewUser: false,
      loading: true,
      selectedUser: {},
      level: "",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }

  componentWillMount() {
    this.getUsers();
  }

  showUserDetails = (user) => {
    this.setState({
      viewUser: true,
      selectedUser: user,
    });
  };

  closeViewMode = () => {
    this.setState({
      viewUser: false,
      selectedUser: {},
    });
  };

  getUsers = () => {
    ajax("get", urlLinkPath.userMembers).then(
      ({ result }) => {
        this.setState({
          viewUser: false,
          list: result.list.filter((user) => user.userInfo),
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          viewUser: false,
          list: [],
          loading: false,
        });
      }
    );
  };

  handleCallback = (searchList) => {
    this.setState({ list: searchList.filter((user) => user.userInfo) });
  };
  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
      this.getUsers
    );
  };

  onHandleOnChange = (val) => {
    this.setState({
      level: val,
    });
    this.getUsers();
  };

  render() {
    const { list, loading, pagination, viewUser, selectedUser } = this.state;

    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>FT Members</div>
        </div>
        <SearchBox
          pagination={pagination}
          userType={USER_TYPE.FTMEMBER}
          searchBoxCallback={this.handleCallback}
        />
        <Table
          loading={loading}
          rowKey={"id"}
          columns={this.columns}
          dataSource={list}
        />

        {viewUser && (
          <Drawer width={520} visible={viewUser} onClose={this.closeViewMode}>
            <FtMemberAction
              user={selectedUser}
              updateUserList={this.getUsers}
            />
          </Drawer>
        )}
      </div>
    );
  }
}

export default FtMemberComponent;
