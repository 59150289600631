import React, {Component} from 'react';
import {notification, Table} from 'antd';
import ajax from '../../../services/ajax';
import { urlPath } from '../../../services/urlPath';

class CityStateComponent extends Component{
  constructor(props){
    super(props);

    this.columns = [
      {
        title: 'City',
        width: 200,
        dataIndex: 'name',
        key: 'name'
      },
      {
        title: 'State',
        width: 100,
        dataIndex: 'stateName',
        key: 'stateName'
      }
    ];

    this.state = {
      list: [],
      loading: true,
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    };
  }

  componentWillMount() {
    this.getCities();
  }

  getCities = () => {
    let { pagination } = this.state;
    ajax('get', urlPath.cityState,{ page: pagination.current}).then(({result}) => {
      pagination.total = result.count;
      this.setState({
        list: result.list,
        pagination: pagination,
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        list: [],
        loading: false
      })
    })
  }

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getCities)
  };

  render(){

    const { list, loading, pagination } = this.state;

    return(
      <div>
        <div className={'header'}>
          <div className={'page-title'}>City List</div>
        </div>
        <Table
          rowKey={'id'}
          loading={loading}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />
      </div>
    )
  }
}

export default CityStateComponent;
