import React, {Component} from 'react';
import {notification, Button, Table, Drawer, Tag} from 'antd';
import moment from 'moment';
import ajax from '../../../services/ajax';
import {color} from "../../../config";
import PlanAction from './PlanAction';
import { urlLinkPath } from '../../../services/urlPath';

class TopupPlanComponent extends Component{

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Plan',
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
      },
      {
        title: 'Price',
        dataIndex: 'fee',
        key: 'fee',
      },
      {
        title: 'IOS Fee',
        dataIndex: 'iosPrice',
        key: 'iosPrice',
      },
      {
        title: 'Store Id',
        dataIndex: 'storeId',
        key: 'storeId',
      },
      {
        title: 'Max Wallet Cashback',
        dataIndex: 'maxWalletCashback',
        key: 'maxWalletCashback',
      },
      {
        title: 'Status',
        dataIndex: 'visible',
        key: 'visible',
        render: (text) => text ? <Tag color={color.primary}>ACTIVE</Tag>: <Tag color={color.disabled}>BLOCKED</Tag>
      },
      {
        title: 'Last Updated',
        dataIndex: 'updatedAt',
        key: 'updatedAt',
        render: (text) => moment(text).format('DD-MM-YYYY HH:mm')
      },
      {
        title: 'Created',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => moment(text).format('DD-MM-YYYY HH:mm')
      },
      {
        title: 'Action',
        key: 'operation',
        fixed: 'right',
        render: (text, record) => <Button onClick={() => { this.updatePlanDetails(record)}} type={'default'}>EDIT</Button>,
      },
    ];

    this.state = {
      loading: true,
      list: [],
      planAction: false,
      mode: 'ADD',
      planSelected: null
    }
  }

  componentWillMount() {
    this.getPlanList();
  }

  getPlanList = () => {
    ajax('get',urlLinkPath.topupPlanList,{}).then(({list}) => {
      this.setState({
        list: list,
        planAction: false,
        planSelected: {},
        mode: 'ADD',
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        list: [],
        planAction: false,
        planSelected: {},
        mode: 'ADD',
        loading: false
      })
    })
  }

  addPlan = () => {
    this.setState({
      planAction: true,
      planSelected: {},
      mode: 'ADD'
    })
  };

  updatePlanDetails = (record) => {
    this.setState({
      planAction: true,
      planSelected: record,
      mode: 'EDIT'
    });
  };

  closeViewMode = () => {
    this.setState({
      planAction: false,
      planSelected: {}
    })
  }
  render(){

    const {list, loading,  mode, masterCategories, planAction, planSelected} = this.state;

    return(
      <div className={'admin-module'}>
        <div className={'header'}>
          <div className={'page-title'}>Topup Plans</div>
        </div>

        <div className={'admin-module-body'}>
          <Button type={'primary'} size={'large'} onClick={this.addPlan}>Add Topup Plan</Button>
          <Table loading={loading} rowKey={'id'} columns={this.columns} pagination={false} dataSource={list} scroll={{ x: 1500 }}/>
        </div>

        {
          planAction && (
            <Drawer
              width={520}
              visible={planAction}
              onClose={this.closeViewMode}
            >
              <PlanAction
                plan={planSelected}
                mode={mode}
                masterCategories={masterCategories}
                updatePlanList={this.getPlanList}
              />
            </Drawer>
          )
        }
      </div>
    )
  }
}

export default TopupPlanComponent;
