import React, {Component} from 'react';
import {notification, Button, Table, Drawer} from 'antd';
import ajax from '../../../../services/ajax';

import RoleAction from './RoleAction';
import { urlLinkPath } from '../../../../services/urlPath';

class AdminRoleComponent extends Component{

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Display Name',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
      },
      {
        title: 'Action',
        key: 'operation',
        render: (text, record) => (
          <div className={'btn-grp'}>
            <Button
              onClick={() => { this.viewRoleDetails(record)}} type={'primary'}>VIEW</Button>
            <Button
              hidden={record.role === 'ROOT'}
              onClick={() => { this.editRoleDetails(record)}} type={'default'}>EDIT</Button>
          </div>
        ),
      },
    ];

    this.state = {
      list: [],
      loading: true,
      viewMode: false,
      roleSelected: {}
    };
  }

  componentWillMount() {
    this.getAdminRoles();
    this.getAdminModules();
  }

  getAdminRoles = () => {
    ajax('get',urlLinkPath.roleList,{}).then(({list}) => {
      this.setState({
        list: list,
        adminAction: false,
        roleSelected: {},
        mode: 'ADD',
        viewMode: false,
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        list: [],
        adminAction: false,
        roleSelected: {},
        mode: 'ADD',
        viewMode: false,
        loading: false
      })
    })
  }

  getAdminModules = () => {
    ajax('get', urlLinkPath.moduleList,{}).then(({list}) => {
      this.setState({
        modules: list
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  addRole = () => {
    this.setState({
      viewMode: true,
      roleSelected: {},
      mode: 'ADD'
    });
  }

  viewRoleDetails = (record) => {
    this.setState({
      viewMode: true,
      roleSelected: record,
      mode: 'VIEW'
    });
  };

  editRoleDetails = (record) => {
    this.setState({
      viewMode: true,
      roleSelected: record,
      mode: 'EDIT'
    });
  };

  closeViewMode = () => {
    this.setState({
      viewMode: false,
      roleSelected: {}
    })
  }

  render(){

    const {list, loading, viewMode, modules, mode, roleSelected} = this.state;

    return(
      <div className={'admin-module'}>
        <div className={'header'}>
          <div className={'page-title'}>Admin Roles</div>
        </div>

        <div className={'admin-module-body small'}>
          <Button type={'primary'} size={'large'} onClick={this.addRole}>Add Role</Button>
          <Table
            rowKey={'id'}
            loading={loading}
            columns={this.columns}
            pagination={false}
            dataSource={list}/>
        </div>
        {
          viewMode && (
            <Drawer
              width={520}
              visible={true}
              onClose={this.closeViewMode}
            >
              <RoleAction role={roleSelected} mode={mode} modules={modules} updateRoleList={this.getAdminRoles}/>
            </Drawer>
          )
        }

      </div>
    )
  }
}

export default AdminRoleComponent;
