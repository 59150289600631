import React, {Component} from 'react';
import {Route, Switch} from "react-router-dom";
import {path} from '../../config';
import { Icon } from "antd";

import AdminDashboard from './Dashboard/Admin/DashboardComponent';
import MarketDashboard from './Dashboard/MarketTeam/MarketingDashboardComponent';
import SidebarComponent from "../Sidebar/SidebarComponent";
import AppVersionComponent from "./RootComponents/AppVersion/AppVersionComponent";
import AddBusinessComponent from "./AddBusiness/AddBusinessComponent";
import UserComponent from "./User/UserComponent";
import BusinessComponent from "./Business/BusinessComponent";
import ReviewComponent from "./Review/ReviewComponent";
import SuggestionComponent from "./Suggestion/SuggestionComponent";
import PostComponent from "./Post/PostComponent";
import BusinessCategoryComponent from "./RootComponents/BusinessCategory/BusinessCategoryComponent";
import MasterBusinessCategoryComponent from "./RootComponents/MasterBusinessCategory/MasterBusinessCategoryComponent";
import CityStateComponent from "./CityState/CityStateComponent";
import TransactionComponent from "./Transaction/TransactionComponent";
import WalletTransferComponent from "./WalletTransfer/WalletTransferComponent";
import AdminComponent from "./RootComponents/Admin/AdminComponent";
import AdminLogComponent from "./AdminLog/AdminLogComponent";
import AdminModuleComponent from "./RootComponents/AdminModule/AdminModuleComponent";
import AdminReferralComponent from "./AdminReferral/AdminReferralComponent";
import AdminRoleComponent from "./RootComponents/AdminRole/AdminRoleComponent";
import ConfigurationComponent from "./RootComponents/Configurations/ConfigurationComponent";
import SubscriptionPlanComponent from "./SubscriptionPlan/SubscriptionPlanComponent";
import ShoppingComponent from "./Shopping/ShoppingComponent";
import EarningComponent  from './Earning/EarningComponent';
import ServiceComponent  from './Service/ServiceComponent';
import TopupPlanComponent from "./TopupPlan/TopupPlanComponent";
import ChiefCoordinatorComponent from "./MarketingTeam/ChiefCoordinator/ChiefCoordinatorComponent";
import CoordinatorComponent from "./MarketingTeam/Coordinator/CoordinatorComponent";
import MarketingExecutiveComponent from "./MarketingTeam/MarketingExecutive/MarketingExecutiveComponent";
import AdComponent from "./Ad/AdComponent";
import CounterEnlistmentComponent from "./CounterEnlistment/CounterEnlistmentComponent";
import FtMemberComponent from "./FtMember/FtMemberComponent";
import PayoutComponent from "./Payout/PayoutComponent";
import InvoicesComponent from './Invoices/InvoicesComponent';

import AdminReport from "./ReferralReport/AdminReport";
import AdvisorReport from "./ReferralReport/AdvisorReport";
import ChiefPromoterReport from "./ReferralReport/ChiefPromoterReport";
import ChiefCoordinatorReport from "./ReferralReport/ChiefCoordinatorReport";
import SeniorCoordinatorReport from "./ReferralReport/SeniorCoordinatorReport";
import CoordinatorReport from "./ReferralReport/CoordinatorReport";
import MarketingExecutiveReport from "./ReferralReport/MarketingExecutiveReport";
import FinancialReport from "./ReferralReport/FinancialReport";
import PushNotificationComponent from "./PushNotification/PushNotificationComponent";
import AdvisorComponent from "./MarketingTeam/Advisor/AdvisorComponent";
import ChiefPromoterComponent from "./MarketingTeam/ChiefPromoter/ChiefPromoterComponent";
import SeniorCoordinatorComponent from "./MarketingTeam/SeniorCoordinator/SeniorCoordinatorComponent";
import AddFtMemberComponent from "./AddFtMember/AddFtMemberComponent";
import CreditWalletAmountComponent from "./CreditWalletAmount/CreditWalletAmountComponent";
import ReferralSchemeComponent from "./ReferralScheme/ReferralSchemeComponent";
import LevelCompletionRewardComponent from "./RootComponents/LevelCompletionReward/LevelCompletionRewardComponent" ;
import AdPricingComponent from "./AdPricing/AdPricingComponent";
import FtPayUserComponent from "./FtPayUser/FtPayUserComponent";
import InquiriesComponent from "./Inquiries/InquiriesComponent";
import ChannelLevelCompletionRewardComponent from "./RootComponents/ChannelLevelCompletionReward/ChannelLevelCompletionComponent";
import TerminationNoticeComponent from "./TerminationNotice/TerminationNoticeComponent";
import UserRequestComponent from "./UserRequest/UserRequestComponent";
import DeleteUserAccountComponent from "./DeleteUserAccountRequests/DeleteUserAccountComponent";
import InvitesComponent from "./Invites/InvitesComponent";
import FTChannelDashboardComponent from "./Dashboard/FTChannel/FTChannelDashboardComponent";
import FTChannelTreeComponent from './FTChannelTreeReport/FTChannelTreeComponent';
import ActivateMemberComponent from './ActivateMember/ActivateMemberComponent';
class Main extends Component {
  state = {
    showSidebar: false
  };

  hideSidebar = () => {
    this.setState({
      showSidebar: false
    })
  }

  render() {

    const { showSidebar } = this.state;

    let admin = localStorage.getItem('admin');
    if(admin) {
      try{
        admin = JSON.parse(admin)
      } catch (e) {
        admin = {
          adminRole: {
            id: 2,
            role: 'ADMIN'
          }
        }
      }
    }
    let isRootAdmin = false;
    let DashboardContainer = AdminDashboard;
    let ReportContainer = null;
    const roleAdmin = admin && admin.adminRole && admin.adminRole.role;
    switch (roleAdmin) {
      case 'ADMIN':
      case 'ROOT':
        DashboardContainer = AdminDashboard;
        ReportContainer = AdminReport;
        isRootAdmin = true;
        break;
      case 'ADVISOR':
        ReportContainer = AdvisorReport;
        DashboardContainer = MarketDashboard;
        break;
      case 'CHIEF_PRO':
        ReportContainer = ChiefPromoterReport;
        DashboardContainer = MarketDashboard;
        break;
      case 'CHIEF_CO':
        ReportContainer = ChiefCoordinatorReport;
        DashboardContainer = MarketDashboard;
        break;
      case 'SENIOR_CO':
        ReportContainer = SeniorCoordinatorReport;
        DashboardContainer = MarketDashboard;
        break;
      case 'CO':
        ReportContainer = CoordinatorReport;
        DashboardContainer = MarketDashboard;
        break;
      case 'MARKET_EXEC':
        ReportContainer = MarketingExecutiveReport;
        DashboardContainer = MarketDashboard;
        break;
      default:

    }

    return (
      <main className={showSidebar ? 'show-sidebar' : ''}>
        <div className={'sidebar-control'} onClick={() => {
          this.setState({
            showSidebar: true
          })
        }}>
          <svg height="40" width="40">
            <polygon points="0,0 0,40 40,0" className="triangle"/>
          </svg>
          <Icon type="arrow-right"/>
        </div>

        <SidebarComponent {...this.props} hideSidebar={this.hideSidebar}/>

        <div className="sidebar-overlay" onClick={() => {
          this.setState({
            showSidebar: false
          })
        }}/>
        <div className={'main-bar'}>
          <Switch>
            <Route exact path={path.dashboard} component={DashboardContainer}/>
            <Route exact path={path.ftChannelDashboard} component={FTChannelDashboardComponent}/>
            {
              isRootAdmin && (
                <Route exact path={path.marketDashboard} component={MarketDashboard}/>
              )
            }
            <Route exact path={path.addBusiness} component={AddBusinessComponent}/>
            <Route exact path={path.appVersion} component={AppVersionComponent}/>
            <Route exact path={path.ad} component={AdComponent}/>
            <Route exact path={path.user} component={UserComponent}/>
            <Route exact path={path.business} component={BusinessComponent}/>
            <Route exact path={path.review} component={ReviewComponent}/>
            <Route exact path={path.suggestion} component={SuggestionComponent}/>
            <Route exact path={path.subscriptionPlan} component={SubscriptionPlanComponent}/>
            <Route exact path={path.shopping} component={ShoppingComponent}/>
            <Route exact path={path.earning} component={EarningComponent}/>
            <Route exact path={path.service} component={ServiceComponent}/>
            <Route exact path={path.post} component={PostComponent}/>
            <Route exact path={path.businessCategory} component={BusinessCategoryComponent}/>
            <Route exact path={path.masterBusinessCategory} component={MasterBusinessCategoryComponent}/>
            <Route exact path={path.cityState} component={CityStateComponent}/>
            <Route exact path={path.transaction} component={TransactionComponent}/>
            <Route exact path={path.transactionByBusiness} component={TransactionComponent}/>
            <Route exact path={path.walletTransfer} component={WalletTransferComponent}/>
            <Route exact path={path.walletTransferByBusiness} component={WalletTransferComponent}/>
            <Route exact path={path.admin} component={AdminComponent}/>
            <Route exact path={path.advisor} component={AdvisorComponent}/>
            <Route exact path={path.chiefPromoter} component={ChiefPromoterComponent}/>
            <Route exact path={path.chiefCoordinator} component={ChiefCoordinatorComponent}/>
            <Route exact path={path.seniorCoordinator} component={SeniorCoordinatorComponent}/>
            <Route exact path={path.coordinator} component={CoordinatorComponent}/>
            <Route exact path={path.marketingExecutive} component={MarketingExecutiveComponent}/>
            <Route exact path={path.adminLog} component={AdminLogComponent}/>
            <Route exact path={path.adminLogByAdmin} component={AdminLogComponent}/>
            <Route exact path={path.adminModule} component={AdminModuleComponent}/>
            <Route exact path={path.adminReferral} component={AdminReferralComponent}/>
            <Route exact path={path.adminReferralByAdmin} component={AdminReferralComponent}/>
            <Route exact path={path.adminRole} component={AdminRoleComponent}/>
            <Route exact path={path.configuration} component={ConfigurationComponent}/>
            <Route exact path={path.pushNotification} component={PushNotificationComponent}/>
            <Route exact path={path.ftMember} component={FtMemberComponent}/>
            <Route exact path={path.counterEnlistment} component={CounterEnlistmentComponent}/>
            <Route exact path={path.payout} component={PayoutComponent}/>
            <Route exact path={path.invoices} component={InvoicesComponent}/>
            <Route exact path={path.topupPlan} component={TopupPlanComponent}/>
            <Route exact path={path.financialReport} component={FinancialReport}/>
            <Route exact path={path.addFtMember} component={AddFtMemberComponent}/>
            <Route exact path={path.creditWalletAmount} component={CreditWalletAmountComponent}/>
            <Route exact path={path.referralSchemes} component={ReferralSchemeComponent}/>
            <Route exact path={path.levelCompletionReward} component={LevelCompletionRewardComponent}/>
            <Route exact path={path.adPricing} component={AdPricingComponent}/>
            <Route exact path={path.ftPayUser} component={FtPayUserComponent}/>
            <Route exact path={path.inquiries} component={InquiriesComponent}/>
            <Route exact path={path.channelCevelCompletionReward} component={ChannelLevelCompletionRewardComponent}/>
            <Route exact path={path.terminationNotice} component={TerminationNoticeComponent}/>
            <Route exact path={path.userRequest} component={UserRequestComponent}/>
            <Route exact path={path.deleteUserAccountRequest} component={DeleteUserAccountComponent}/>
            <Route exact path={path.invites} component={InvitesComponent}/>
            <Route exact path={path.ftChannelTree}component={FTChannelTreeComponent}/>
            <Route exact path={path.activateMember}component={ActivateMemberComponent}/>
           
            {
              ReportContainer && (
                <Route exact path={path.report} component={ReportContainer}/>
              )
            }
          </Switch>
        </div>
      </main>
    )
  }
}

export default Main;