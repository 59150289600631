import React, { Component } from "react";
import { notification, Button, Table, Tag, Drawer, Popconfirm } from "antd";
import ajax from "../../../services/ajax";
import { color } from "../../../config";
import {urlPath} from "../../../services/urlPath";
import EarningAction from "./EarningAction";

class EarningComponent extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Name",
        width: 250,
        dataIndex: "name",
        key: "name",
        render: (text, record) => text,
      },
      {
        title: "Link",
        width: 200,
        dataIndex: "link",
        key: "link",
        render: (text, record) => (
          <a href={record.link} target="_blank">
            {record.link}
          </a>
        ),
      },
      {
        title: "Status",
        width: 100,
        dataIndex: "visible",
        key: "visible",
        render: (text) =>
          text ? (
            <Tag color={color.primary}>ACTIVE</Tag>
          ) : (
            <Tag color={color.disabled}>BLOCKED</Tag>
          ),
      },
      {
        title: "Action",
        key: "operation",
        width: 250,
        render: (text, record) => (
          <div className={"btn-grp"}>
            <Button
              type={"default"}
              onClick={() => {
                this.editLinkDetails(record);
              }}
            >
              Edit
            </Button>

            <Popconfirm
              title={"Are you sure?"}
              okText={"YES"}
              okType={"danger"}
              onConfirm={() => {
                this.deleteEarningLink(record);
              }}
            >
              <Button type={"danger"}>Delete</Button>
            </Popconfirm>
          </div>
        ),
      },
    ];

    this.state = {
      list: [],
      loading: true,
      mode: "ADD",
      viewLink: false,
      selectedLink: {},
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }

  componentWillMount() {
    this.getLinks();
  }

  addLink = () => {
    this.setState({
      viewLink: true,
      mode: "ADD",
    });
  };

  editLinkDetails = (link) => {
    this.setState({
      viewLink: true,
      mode: "EDIT",
      selectedLink: link,
    });
  };

  closeViewMode = () => {
    this.setState({
      viewLink: false,
      selectedLink: {},
    });
  };

  getLinks = () => {
    let { pagination } = this.state;
    ajax("get", urlPath.earningLink, { page: pagination.current }).then(
      ({ result }) => {
        console.log(result);
        pagination.total = result.count;
        this.setState({
          viewLink: false,
          loading: false,
          pagination: pagination,
          selectedLink: {},
          mode: "ADD",
          list: result.list,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          viewLink: false,
          loading: false,
          selectedLink: {},
          mode: "ADD",
          list: [],
        });
      }
    );
  };

  deleteEarningLink = (link) => {
    ajax("delete", urlPath.earningLink, { id: link.id }).then(
      () => {
        notification.success({
          message: "Success",
          description: "Earning Link deleted!",
        });
        this.getLinks();
      },
      (err) => {
        notification.error({
          message: "Error",
          description: err && err.msg,
        });
      }
    );
  };
  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
      this.getLinks
    );
  };

  render() {
    const { list, loading, pagination, viewLink, mode, selectedLink } =
      this.state;

    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>Earning</div>
        </div>

        <div className={"admin-module-body"}>
          <Button type={"primary"} size={"large"} onClick={this.addLink}>
            Add Earning link
          </Button>
          <Table
            rowKey={"id"}
            loading={loading}
            columns={this.columns}
            pagination={pagination}
            dataSource={list}
          />
        </div>

        {viewLink && (
          <Drawer width={520} visible={viewLink} onClose={this.closeViewMode}>
            <EarningAction
              mode={mode}
              link={selectedLink}
              updateLinkList={this.getLinks}
            />
          </Drawer>
        )}
      </div>
    );
  }
}

export default EarningComponent;
