import React, {Component} from 'react';
import {Form, Input, Select, Button, notification, Popconfirm} from 'antd';

import ajax from '../../../../services/ajax';
import { urlLinkPath, urlPath } from '../../../../services/urlPath';

class AdminAction extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      parentAdmins: [],
    }
  }

  handleSuccess = (msg) => {
    this.setState({
      loading: false
    });
    notification.success(msg);
    this.props.updateAdminList();
  }

  handleError = (msg) => {
    this.setState({
      loading: false
    });
    notification.error({
      message: 'Error',
      description: msg
    });
  }

  submitForm = (ev) => {
    ev.preventDefault();
    const action = {
      ADD: this.addAdmin,
      EDIT: this.updateAdmin
    }[this.props.mode];
    action();
  };

  addAdmin = () => {
    this.props.form.validateFields((err, values) => {
      if(err) return;
      this.setState({
        loading: true
      });
      ajax('post', urlPath.admin,values).then((res) => {
        this.handleSuccess({
          message: 'Success',
          description: 'Admin added!'
        })
      }, ({msg}) => {
        this.handleError(msg);
      })
    })
  }

  updateAdmin = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;

      this.setState({
        loading: true
      });
      values.id = this.props.admin.id;
      ajax('put', urlPath.admin, values).then((res) => {
        this.handleSuccess({
          message: 'Success',
          description: 'Admin updated!'
        });
      }, ({msg}) => {
        this.handleError(msg);
      })
    });
  }

  handleVisibility = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.admin.id,
      status: !this.props.admin.visible
    };
    ajax('post', urlLinkPath.adminVisibility,params).then((res) => {
      this.handleSuccess({
        message: 'Success',
        description: 'Admin status updated!'
      });
    }, ({msg}) => {
      this.handleError(msg);
    })
  }

  getParentAdmins = (value) => {
    const params = {
      roleId: value
    };
    ajax('get', urlLinkPath.adminListByRoles,params).then((result) => {
      console.log(result);
      this.setState({
        parentAdmins: result.list,
      }, () => {
        this.props.form.setFieldsValue({'adminParentId':  ''})
      })
    }, ({msg}) => {
      this.handleError(msg);
    })
  }

  deleteAdmin = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.admin.id
    };
    ajax('delete', urlPath.admin,params).then((res) => {
      this.handleSuccess({
        message: 'Success',
        description: 'Admin deleted!'
      });
    }, ({msg}) => {
      this.handleError(msg);
    })
  }

  componentDidMount() {
    const { admin } = this.props;
    if((admin && admin.parentAdmin)){
      this.setState({
        parentAdmins: [admin.parentAdmin]
      }, () => {
        this.props.form.setFieldsValue({'adminParentId':  admin.parentAdmin.id})
      })
    }
  }

  render() {

    const { loading, parentAdmins } = this.state;
    const { admin, mode, roles } = this.props;
    const { getFieldDecorator } = this.props.form;

    const adminRole = roles.map((role, index) => {
      return (
        <Select.Option key={index} value={role.id}>{role.title}</Select.Option>
      )
    });

    const parentAdminsOptions = parentAdmins.map((admin, index) => {
      return (
          <Select.Option key={index} value={admin.id}>{`${admin.firstName} ${admin.lastName}`}</Select.Option>
      )
    });



    return (
      <Form onSubmit={this.submitForm} className="form">
        <h2>Admin Details</h2>
        <Form.Item label={'First Name'} colon={false}>
          {
            getFieldDecorator('firstName', {
              initialValue: admin && admin.firstName,
              rules: [{
                required: true,
                message: 'Please input first name for admin!'
              }]
            })(<Input size={'large'}/>)
          }
        </Form.Item>
        <Form.Item label={'Last Name'} colon={false}>
          {
            getFieldDecorator('lastName', {
              initialValue: admin && admin.lastName,
              rules: [{
                required: true,
                message: 'Please input last name for admin!'
              }]
            })(<Input size={'large'}/>)
          }
        </Form.Item>
        <Form.Item label={'Phone'} colon={false}>
          {
            getFieldDecorator('phone', {
              initialValue: admin && admin.phone,
              rules: [{
                required: true,
                message: 'Please input phone number for admin!'
              }]
            })(<Input size={'large'}/>)
          }
        </Form.Item>
        <Form.Item label={'Role'} colon={false}>
          {
            getFieldDecorator('adminRoleId', {
              initialValue: (admin && admin.adminRole) && admin.adminRole.id,
              rules: [{
                required: true,
                message: 'Please select Admin Role!'
              }]
            })(
              <Select size={'large'}>
                {adminRole}
              </Select>
            )
          }
        </Form.Item>
        <Form.Item label={'Parent Role'} colon={false}>
          {
            getFieldDecorator('adminParentRoleId', {
              initialValue: (admin && admin.parentAdmin && admin.parentAdmin.adminRole) && admin.parentAdmin.adminRole.id,
              onChange: (v) => this.getParentAdmins(v),
              rules: [{
                required: true,
                message: 'Please select Parent Admin Role!'
              }]
            })(
                <Select size={'large'}>
                  {adminRole}
                </Select>
            )
          }
        </Form.Item>
        <Form.Item label={'Parent Admin'} colon={false}>
          {
            getFieldDecorator('adminParentId', {
              rules: [{
                required: true,
                message: 'Please select Parent Admin!'
              }]
            })(
                <Select size={'large'}>
                  {parentAdminsOptions}
                </Select>
            )
          }
        </Form.Item>
        <div>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !=='ADD'}
            loading={loading}
          >
            Add Admin
          </Button>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !== 'EDIT'}
          >
            Update Admin
          </Button>
        </div>

        <div className={'btn-grp opposite drawer-footer'} hidden={mode==='ADD'}>
          <Button
            size={'large'}
            onClick={this.handleVisibility}
            type={admin && admin.visible ? 'danger': 'primary'}
            loading={loading}
          >
            {admin && admin.visible ? 'Block Admin': 'Activate Admin'}
          </Button>
          <Popconfirm
            placement={'topRight'}
            title={'Are you sure you wish to delete this ADMIN?'}
            onConfirm={this.deleteAdmin}
            okText={'YES! Delete Admin'} okType={'danger'}
            cancelText={'NO!'}
          >
            <Button size={'large'} type={'danger'} loading={loading}>Delete Admin</Button>
          </Popconfirm>
        </div>
      </Form>
    )
  }
}

export default Form.create()(AdminAction)