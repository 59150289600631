import React, { Component } from "react";
import { notification, Button, Table, Modal, Tag } from "antd";
import moment from "moment";
import ajax from "../../../services/ajax";
import { color } from "../../../config";
import { urlPath, urlLinkPath } from "../../../services/urlPath";
const { confirm } = Modal;
class UserRequestComponent extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Sender",
        dataIndex: "user",
        key: "user",
        render: (text, { user }) =>
          user && `${user.firstName} ${user.lastName}`,
      },
      {
        title: "Requested To",
        dataIndex: "receiver",
        key: "receiver",
        render: (text, { receiver }) =>
          receiver && `${receiver.firstName} ${receiver.lastName}`,
      },
      {
        title: "Type",
        dataIndex: "type",
        key: "type",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text) =>
          ({
            NONE: <Tag color={color.disabled}>NONE</Tag>,
            REJECTED: <Tag color={color.danger}>REJECTED</Tag>,
            APPROVED: <Tag color={color.success}>APPROVED</Tag>,
          }[text]),
      },
      {
        title: "Last Updated At",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (text) => moment(text).format("DD-MM-YYYY HH:mm"),
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => moment(text).format("DD-MM-YYYY HH:mm"),
      },
      {
        title: "Action",
        key: "operation",
        render: (text, record) => (
          <div className={"btn-grp"}>
            <Button
              disabled={record.status != "NONE"}
              onClick={() => {
                this.showApproveConfirm(record);
              }}
              type={"default"}
            >
              APPROVE
            </Button>
            <Button
              disabled={record.status != "NONE"}
              onClick={() => this.showRejectConfirm(record)}
              type={"danger"}
            >
              Reject
            </Button>
          </div>
        ),
      },
    ];

    this.state = {
      loading: true,
      list: [],
      requestSelected: null,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }

  componentWillMount() {
    this.getTerminationNoticeList();
  }

  getTerminationNoticeList = () => {
    const { pagination } = this.state;
    ajax("get", urlPath.request, {
      page: pagination.current,
      type: "FT_CONTRACT_EXTENSION",
    }).then(
      ({ result }) => {
        this.setState({
          list: result.list,
          requestSelected: {},
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          list: [],
          requestSelected: {},
          loading: false,
        });
      }
    );
  };

  approveContractExtension = (id) => {
    ajax("post", "/user-request/approve", { id: id })
      .then(({ msg }) => {
        notification.success({
          message: "Success",
          description: msg,
        });
        this.getTerminationNoticeList();
      })
      .catch((error) => {
        const errorMsg = error?.msg || error;
        notification.error({
          message: "Error",
          description: errorMsg,
        });
      });
  };
  rejectContractExtension = (id) => {
    ajax("post", "/user-request/reject", { id: id })
      .then(({ msg }) => {
        notification.success({
          message: "Success",
          description: msg,
        });
        this.getTerminationNoticeList();
      })
      .catch((error) => {
        const errorMsg = error?.msg || error;
        notification.error({
          message: "Error",
          description: errorMsg,
        });
      });
  };
  showApproveConfirm = (record) => {
    confirm({
      title: "Approve contract",
      content: "Are you sure you want to approve this contract?",
      onOk: () => {
        this.approveContractExtension(record.id);
      },
    });
  };
  showRejectConfirm = (record) => {
    confirm({
      title: "Reject contract",
      content: "Are you sure you want to reject this contract?",
      onOk: () => {
        this.rejectContractExtension(record.id);
      },
    });
  };
  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getTerminationNoticeList)
  };
  render() {
    const { list, loading } = this.state;

    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>Contract Extension Request</div>
        </div>

        <div className={"admin-module-body"}>
          <Table
            loading={loading}
            rowKey={"id"}
            columns={this.columns}
            pagination={false}
            dataSource={list}
            scroll={{ x: 1500 }}
          />
        </div>
      </div>
    );
  }
}

export default UserRequestComponent;
