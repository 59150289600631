import React, {Component} from 'react';
import {notification, Button, Table, Drawer} from 'antd';
import ajax from '../../../../services/ajax';

import ModuleAction from './ModuleAction';
import { urlLinkPath } from '../../../../services/urlPath';

class AdminModuleComponent extends Component{

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Name',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: 'Sort Order',
        dataIndex: 'sortOrder',
        key: 'sortOrder',
      },
      {
        title: 'Action',
        key: 'operation',
        render: (text, record) => <Button onClick={() => { this.updateModuleDetails(record)}} type={'default'}>Edit Module</Button>,
      },
    ];

    this.state = {
      list: [],
      loading: true,
      moduleAction: false,
      mode: 'ADD',
      moduleSelected: null
    }
  }

  componentWillMount() {
    this.getModuleList();
  }

  getModuleList = () => {
    ajax('get', urlLinkPath.moduleList,{}).then(({list}) => {
      this.setState({
        list: list,
        moduleAction: false,
        moduleSelected: null,
        mode: 'ADD',
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        list: [],
        moduleAction: false,
        moduleSelected: null,
        mode: 'ADD',
        loading: false
      })
    })
  }

  addModule = () => {
    this.setState({
      moduleAction: true,
      moduleSelected: null,
      mode: 'ADD'
    })
  };

  updateModuleDetails = (record) => {
    this.setState({
      moduleAction: true,
      moduleSelected: record,
      mode: 'EDIT'
    });
  };

  closeViewMode = () => {
    this.setState({
      moduleAction: false,
      moduleSelected: {}
    })
  }
  render(){

    const {list, loading, mode, moduleAction, moduleSelected} = this.state;

    return(
      <div className={'admin-module'}>
        <div className={'header'}>
          <div className={'page-title'}>Modules</div>
        </div>

        <div className={'admin-module-body'}>
          <Button type={'primary'} size={'large'} onClick={this.addModule}>Add Module</Button>
          <Table
            rowKey={'id'}
            loading={loading}
            columns={this.columns}
            pagination={false}
            dataSource={list}/>
        </div>

        {
          moduleAction && (
            <Drawer
              width={520}
              visible={moduleAction}
              onClose={this.closeViewMode}
            >
              <ModuleAction module={moduleSelected} mode={mode} updateModuleList={this.getModuleList}/>
            </Drawer>
          )
        }
      </div>
    )
  }
}

export default AdminModuleComponent;
