import React, {Component} from 'react';
import {Form, Input, DatePicker, Button, notification} from "antd";
import ajax from '../../../../services/ajax';
import {passwordRules} from '../../../../services/validationRules';
import moment from 'moment';
import { urlLinkPath } from '../../../../services/urlPath';
class CustomerDetail extends Component{

  state = {
    loading: false
  };

  updateCustomer = (ev) => {
    ev.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.setState({
        loading: true
      });
      values.id = this.props.user.id;
      ajax('post', urlLinkPath.updateCustomer, values).then(({user, msg}) => {
        notification.success({
          message: 'Success',
          description: msg
        });
        this.setState({
          loading: false
        });
        this.props.nextStep(user);
      }, ({msg}) => {
        notification.error({
          message: 'Error',
          description: msg
        });
        this.setState({
          loading: false
        });
      })
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { loading } = this.state;
    return (
      <>
        <div className={'sub-title'}>Customer Details</div>
        <Form onSubmit={this.updateCustomer}>

          <Form.Item label={'Phone Number'} colon={false}>
            {
              getFieldDecorator('phone', {
                initialValue: this.props.user ?  this.props.user.phone : null
              })( <Input readOnly size={'large'}/> )
            }
          </Form.Item>
          <Form.Item label={'Password'} colon={false}>
            {
              getFieldDecorator('password', {
                rules: passwordRules
              })( <Input.Password  size={'large'} placeholder={'******'}/> )
            }
          </Form.Item>
          <Form.Item label={'First Name'} colon={false}>
            {
              getFieldDecorator('firstName', {
                initialValue: this.props.user ?  this.props.user.firstName : null,
                rules: [{
                  required: true,
                  message: 'Please input First Name!'
                }]
              })(<Input size={'large'}/>)
            }
          </Form.Item>
          <Form.Item label={'Last Name'} colon={false}>
            {
              getFieldDecorator('lastName', {
                initialValue: this.props.user ?  this.props.user.lastName : null,
                rules: [{
                  required: true,
                  message: 'Please input Last Name!'
                }]
              })(<Input size={'large'}/>)
            }
          </Form.Item>
          <Form.Item label={'Email'} colon={false}>
            {
              getFieldDecorator('emailId', {
                initialValue: this.props.user ?  this.props.user.emailId : null,
                rules: [{
                  required: true,
                  message: 'Please input Email ID!'
                }]
              })(<Input size={'large'} type={'email'}/>)
            }
          </Form.Item>
          <Form.Item label={'Date of Birth'} colon={false}>
            {
              getFieldDecorator('dob', {
                initialValue: this.props.user ?  moment(this.props.user.dob) : null,
                rules: [{
                  required: true,
                  message: 'Please select Date of Birth!'
                }]
              })(<DatePicker size={'large'}/>)
            }
          </Form.Item>
          <div className={'btn-grp opposite'}>
            <Button htmlType={'button'} type={'default'} size={'large'} disabled={loading} onClick={this.props.goBack}>Change Number</Button>
            <Button htmlType={'submit'} type={'primary'} size={'large'} loading={loading}>Next</Button>
          </div>
        </Form>
      </>
    )
  }
}

export default Form.create()(CustomerDetail);