import React, {Component} from 'react';
import {Card, Form, Input, Button, notification, Col } from 'antd';
import { Link } from 'react-router-dom';
import { path } from '../../../config';
import ajax from '../../../services/ajax';
import DescriptionItem from '../../_common/DescriptionItem';
import { urlLinkPath, urlPath } from '../../../services/urlPath';

const ifscRules = [{
    required: true,
    message: 'Please input IFSC!'
  },{
    min: 11,
    max: 11,
    message: 'IFCS length incorrect!'
}];
const accountRules = [{
    required: true,
    message: 'Please input Account Number!'
},{
    min: 5,
    max: 35,
    message: 'Acc Number incorrect!'
}];
class RazorpayContact extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            initialLoad: true,
            account: null
        }
      }

  componentWillMount() {
      const { user,invoiceType } = this.props;
      const razorpayContactId=invoiceType =='FTMember'?user.userInfo.razorpayContactId:user.razorpayContactId
      if(razorpayContactId) {   
        ajax('get',`${urlLinkPath.payFundAccount}/${user.id}`).then(({data: {items}}) => {
          this.setState({
                razorpayContactId:razorpayContactId,
                initialLoad: false,
                account: items && items.length && items[0].bank_account
            })
          }, () => {
            notification.error({
              message: 'Error',
            //   description: msg
            });
            this.setState({
                initialLoad: false,
            });
          });
      } else {
        this.setState({
            initialLoad: false,
        });
      }
  }

  addAccount = (ev) => {
    ev.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return
      }

      this.setState({
        loading: true
      });

      const { user } = this.props
      ajax('post',`${urlLinkPath.paylinkBank}/${user.id}`, values).then(({ data, msg }) => {
        notification.success({
            message: 'Success',
            description: msg
          });
          this.setState({
            loading: false
          });
      }, ({ msg }) => {
        notification.error({
          message: 'Error',
          description: msg
        });
        this.setState({
          loading: false
        });
      });
    });
  };

  createRazorpayContact = () => {
    const {user} = this.props;
    ajax('post',`${urlPath.pay}/${user.id}`).then(({ razorpayContactId }) => {
        notification.success({
            message: 'Success',
            description: "Razorpay contact created"
          });
          this.setState({
            razorpayContactId
          })
        
      }, ({ msg }) => {
        notification.error({
          message: 'Error',
          description: msg
        });
        this.setState({
          loading: false
        });
      });
  }
  render() {

    const { form : { getFieldDecorator} } = this.props;
    const { razorpayContactId, initialLoad, account } = this.state;

    return (
      
      <div className={'razorpay-account'}>
          {
              razorpayContactId ? (
                  
                <Col span={22}>
                    {
                        account ? (
                            <>
                                <DescriptionItem title={'Account No'} content={account.account_number} />
                                <DescriptionItem title={'IFSC'} content={account.ifsc} />
                                <DescriptionItem title={'Bank Name'} content={account.bank_name} />
                            </>
                        ) : (
                            
                            <Card bordered={false}>
                                <div className={'title'}>Add Bank Account</div>
                                <Form onSubmit={this.addAccount}>
                                    <Form.Item label={'Name'} colon={false}>
                                        {getFieldDecorator('name', {
                                            rules: [{
                                                required: true
                                            }],
                                        })( <Input size={'large'} placeholder={'Name'}/> )}
                                    </Form.Item>
                                    <Form.Item label={'IFSC'} colon={false} >
                                        {getFieldDecorator('ifsc', {
                                            rules: ifscRules,
                                        })( <Input size={'large'} /> )}
                                    </Form.Item>
                                    <Form.Item label={'Acc Number'} colon={false} >
                                        {getFieldDecorator('account_number', {
                                            rules: accountRules,
                                        })( <Input size={'large'} /> )}
                                    </Form.Item>

                                    <div className={'btn-container'}>
                                    <Button loading={this.state.loading} type={'primary'} size={'large'} block htmlType={'submit'}>Add Account</Button>
                                    </div>
                                </Form>
                             </Card> 
                        )
                    }
                
                </Col>
                    
              ) : (
                  <>
                    {
                        initialLoad ? (
                            <>
                                Please wait...
                            </>
                        ) : (
                            <Button loading={this.state.loading} onClick={this.createRazorpayContact}>Create Razorpay Contact</Button>

                        )
                    }
                  </>
              )
          }
        </div>
    )
  }
}

export default Form.create()(RazorpayContact);