import React, {Component} from 'react';
import {notification, Table, Tag, DatePicker, Card, Select, Button} from 'antd';
import moment from 'moment';
import ajax from '../../../services/ajax';
import {color, apiBaseURL} from '../../../config';
import { strigifyQuery } from '../../../services/queryHelper';
import { urlLinkPath, urlPath } from '../../../services/urlPath';
const { RangePicker } = DatePicker;

class CoordinatorReport extends Component{
  constructor(props){
    super(props);
    this.authorization = localStorage.getItem('token');
    this.columns = [
      {
        title: 'Executive',
        dataIndex: 'executive',
        key: 'executive',
        render: (text, record) => record.admin && (record.admin.firstName + ' (' + record.admin.referralCode + ')')
      },
      {
        title: 'Business',
        dataIndex: 'business',
        key: 'business',
        render: (text, record) => record.business && record.business.name
      },
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        render: (text, record) => record.user && `${record.user.firstName} ${record.user.lastName}`
      },
      {
        title: 'Type',
        dataIndex: 'referralType',
        key: 'referralType',
        render: (text) => ({
          DASHBOARD: <Tag color={color.primary}>DASHBOARD</Tag>,
          REFERRAL_CODE: <Tag color={color.accentDark}>REFERRAL CODE</Tag>,
        }[text])
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: 'Created on',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) =>  moment(text).format('DD-MM-YYYY HH:mm')
      },
    ];

    this.state = {
      list: [],
      executives: [],
      selectedExecutive: null,
      loading: true,
      viewReferral: false,
      selectedReferral: {},
      dateRange: [moment().subtract(6,'d'), moment()],
      downloadLink: '',
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    };
  }

  componentWillMount() {
    this.getExecutiveList();
    this.getReferralReport();
  }

  getExecutiveList = () => {
    ajax('get', urlLinkPath.marketingExecList,{}).then(({list}) => {
      this.setState({
        executives: list,
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  dateRangeChange = (range) => {
    if(!(range[0] && range[1])){
      range = [moment().subtract(7,'days'), moment()];
    }
    this.setState({
      dateRange: range
    }, this.getReferralReport)
  };

  getReferralReport = () => {
    let { selectedExecutive, dateRange, pagination } = this.state;

    const params = {
      executiveId: selectedExecutive,
      startDate: dateRange[0].format('YYYY-MM-DD'),
      endDate: dateRange[1].format('YYYY-MM-DD'),
      page: pagination.current
    };
    ajax('post', urlPath.marketReport,params).then(({result}) => {
      pagination.total = result.count;

      const link = strigifyQuery({
        authorization: this.authorization,
        executiveId: selectedExecutive,
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
      });

      this.setState({
        downloadLink: `${apiBaseURL}${urlPath.marketReport}/download${link}`,
        list: result.list,
        pagination: pagination,
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        list: [],
        loading: false
      })
    })
  };

  handleExecutive = (id) => {
    this.setState({
      selectedExecutive: id || null
    }, this.getReferralReport)
  }

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getReferralReport)
  };

  render(){

    const {list, dateRange, executives, loading, pagination, downloadLink} = this.state;

    const options = executives.map((executive) => {
      return <Select.Option key={executive.id} value={executive.id}>{executive.firstName} {' ('} {executive.referralCode} {')'}</Select.Option>
    });
    return(
      <div>
        <div className={'header'}>
          <div className={'page-title'}>Report</div>
        </div>

        <Card className={'table-card'}
              title={
                <Select style={{width: 200}} placeholder={'Select Executive'} allowClear onChange={this.handleExecutive}>
                  {options}
                </Select>
              }
              extra={(
                <RangePicker
                  value={dateRange}
                  format={'DD/MM/YYYY'}
                  separator={'to'}
                  onChange={this.dateRangeChange}
                />
              )}
        >
          <Table
            rowKey={'id'}
            loading={loading}
            columns={this.columns}
            pagination={pagination}
            dataSource={list}
          />
        </Card>

        <div className={'spacer'}/>
        <a href={downloadLink} target={'_blank'} disabled={list.length === 0}><Button>Download Report</Button></a>

      </div>
    )
  }
}

export default CoordinatorReport;

