import React, {Component} from 'react';
import {Button, Form, Input, List, notification, Popconfirm, Select} from 'antd';

import ajax from '../../../../services/ajax';
import { urlPath } from '../../../../services/urlPath';

const RoleModules = ({role}) => (
  <Form.Item label={'Assigned Modules'} colon={false}>
    <div className={'scroll-block'}>
      <List
        itemLayout={'horizontal'}
        dataSource={role.adminRoleModules}
        renderItem={roleModule => (
          <List.Item actions={[
            <Select
              disabled
              className={'access-level' + ' ' +roleModule.accessLevel}
              value={roleModule.accessLevel}
            >
              <Select.Option value={'NONE'}>NONE</Select.Option>
              <Select.Option value={'READ'}>READ</Select.Option>
              <Select.Option value={'WRITE'}>WRITE</Select.Option>
            </Select>
          ]}>
            <List.Item.Meta
              title={roleModule.adminModule.title}
            />
          </List.Item>
        )}
      />
    </div>
  </Form.Item>
);


class Modules extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modules: [],
    }
  }

  componentDidMount() {
    const {list, roleModules} = this.props;

    const roleModulesLength = roleModules ? roleModules.length : 0;

    const modules = list.map((item, index) => {
      item.accessLevel = 'NONE';
      for(let i = 0; i < roleModulesLength; i++){
        if(roleModules[i].adminModule.id === item.id){
          item.accessLevel = roleModules[i].accessLevel;
          break;
        }
      }
      return item;
    });

    this.setState({
      modules: modules
    })
  }

  get = (filtered) => {
    if(!filtered){
      return this.state.modules;
    }

    const modulesFiltered = this.state.modules.filter((item, index) => {
      return item.accessLevel !== 'NONE'
    });

    return modulesFiltered;
  };

  onAccessLevelChange = (value, item, index) => {
    const {modules} = this.state;
    modules[index].accessLevel = value;
    this.setState({
      modules: modules
    })
  };

  render() {

    const { modules } = this.state;

    return (
      <Form.Item label={'Assigned Modules'} colon={false}>
        <div className={'scroll-block'}>
          <List
            itemLayout={'horizontal'}
            dataSource={modules}
            renderItem={(item, index) => (
              <List.Item actions={[
                <Select
                  className={'access-level' + ' ' + item.accessLevel}
                  value={item.accessLevel}
                  onChange={(ev) => { this.onAccessLevelChange(ev, item, index)}}
                >
                  <Select.Option value={'NONE'}>NONE</Select.Option>
                  <Select.Option value={'READ'}>READ</Select.Option>
                  <Select.Option value={'WRITE'}>WRITE</Select.Option>
                </Select>
              ]}>
                <List.Item.Meta
                  title={item.title}
                />
              </List.Item>
            )}
          />
        </div>
      </Form.Item>
    )
  }
}

class RoleAction extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }

    this.moduleList = React.createRef();
  }

  handleSuccess = (msg) => {
    this.setState({
      loading: false
    });
    notification.success(msg);
    this.props.updateRoleList();
  }

  handleError = (msg) => {
    this.setState({
      loading: false
    });
    notification.error({
      message: 'Error',
      description: msg
    });
  }

  submitForm = (ev) => {
    ev.preventDefault();
    const action = {
      ADD: this.addRole,
      EDIT: this.updateRole
    }[this.props.mode];
    action();
  };

  addRole = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true
      });

      values.modules = this.moduleList.current.get(true);
      ajax('post', urlPath.role, values).then((res) => {
        this.handleSuccess({
          message: 'Success',
          description: 'Role added!'
        })
      }, ({msg}) => {
        this.handleError(msg);
      })
    })
  }

  updateRole = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;

      this.setState({
        loading: true
      });
      values.id = this.props.role.id;
      values.modules = this.moduleList.current.get();
      ajax('put', urlPath.role, values).then((res) => {
        this.handleSuccess({
          message: 'Success',
          description: 'Role updated!'
        });
      }, ({msg}) => {
        this.handleError(msg);
      })
    });
  }


  deleteRole = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.admin.id
    };
    ajax('delete', urlPath.role, params).then((res) => {
      this.handleSuccess({
        message: 'Success',
        description: 'Role deleted!'
      });
    }, ({msg}) => {
      this.handleError(msg);
    })
  }


  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if(nextProps.mode){
      return true;
    }
  }

  render() {

    const {loading} = this.state;
    const {role, mode, modules} = this.props;
    const {getFieldDecorator} = this.props.form;

    return (
      <Form onSubmit={this.submitForm}>
        <h2>Role Details</h2>
        <Form.Item label={'Title'} colon={false}>
          {
            getFieldDecorator('title', {
              initialValue: role && role.title,
              rules: [{
                required: true,
                message: 'Please input title for Role!'
              }]
            })(<Input size={'large'} readOnly={mode === 'VIEW'}/>)
          }
        </Form.Item>
        <Form.Item label={'Role'} colon={false}>
          {
            getFieldDecorator('role', {
              initialValue: role && role.role,
              rules: [{
                required: true,
                message: 'Please role key!'
              }]
            })(<Input size={'large'} readOnly={mode !== 'ADD'}/>)
          }
        </Form.Item>

        {
          mode === 'VIEW' ? (
            <RoleModules role={role}/>
          ) : (
            <Modules ref={this.moduleList} list={modules} roleModules={role ? role.adminRoleModules : []}/>
          )
        }

        <div>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !== 'ADD'}
            loading={loading}
          >
            Add Role
          </Button>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !== 'EDIT'}
          >
            Update Role
          </Button>
        </div>

        <div className={'btn-grp opposite drawer-footer'} hidden={true || mode === 'ADD'}>
          <Popconfirm
            placement={'topRight'}
            title={'Are you sure you wish to delete this ROLE?'}
            onConfirm={this.deleteRole}
            okText={'YES! Delete Role'} okType={'danger'}
            cancelText={'NO!'}
          >
            <Button size={'large'} type={'danger'} loading={loading}>Delete Role</Button>
          </Popconfirm>
        </div>
      </Form>
    )
  }
}

export default Form.create()(RoleAction)