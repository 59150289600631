import React, {Component} from 'react';
import {notification, Button, Table, Drawer, Tag} from 'antd';
import ajax from '../../../../services/ajax';

import AdminAction from './CoordinatorAction';
import { urlLinkPath } from '../../../../services/urlPath';

class CoordinatorComponent extends Component{

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'First Name',
        dataIndex: 'firstName',
        key: 'firstName',
      },
      {
        title: 'Last Name',
        dataIndex: 'lastName',
        key: 'lastName',
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: 'Code',
        dataIndex: 'referralCode',
        key: 'referralCode',
        className: 'bold'
      },
      {
        title: 'Status',
        dataIndex: 'visible',
        key: 'visible',
        render: (text) => text ? <Tag color={'#34b7e3'}>ACTIVE</Tag>: <Tag color={'#ff443f'}>BLOCKED</Tag>
      },
      {
        title: 'Action',
        key: 'operation',
        render: (text, record) => (
          <div className="btn-grp">
            <Button onClick={() => { this.updateAdminDetails(record)}} type={'default'}>Edit Details</Button>
          </div>
        ),
      },
    ];

    this.state = {
      list: [],
      adminAction: false,
      mode: 'ADD',
      adminSelected: null
    }
  }

  componentWillMount() {
    this.getAdminList();
  }

  getAdminList = () => {
    ajax('get', urlLinkPath.coordinatorList,{}).then(({list}) => {
      this.setState({
        list: list,
        adminAction: false,
        adminSelected: null,
        mode: 'ADD'
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  addAdmin = () => {
    this.setState({
      adminAction: true,
      adminSelected: null,
      mode: 'ADD'
    })
  };

  updateAdminDetails = (record) => {
    this.setState({
      adminAction: true,
      adminSelected: record,
      mode: 'EDIT'
    });
  };

  closeViewMode = () => {
    this.setState({
      adminAction: false,
      adminSelected: {}
    })
  }
  render(){

    const {list, mode, adminAction, adminSelected} = this.state;

    return(
      <div className={'admin-module'}>
        <div className={'header'}>
          <div className={'page-title'}>Executive Coordinators</div>
        </div>

        <div className={'admin-module-body'}>
          <Button type={'primary'} size={'large'} onClick={this.addAdmin}>Add Executive Coordinator</Button>
          <Table rowKey={'id'} columns={this.columns} pagination={false} dataSource={list}/>
        </div>

        {
          adminAction && (
            <Drawer
              width={520}
              visible={adminAction}
              onClose={this.closeViewMode}
            >
              <AdminAction admin={adminSelected} mode={mode} updateAdminList={this.getAdminList}/>
            </Drawer>
          )
        }

      </div>
    )
  }
}

export default CoordinatorComponent;