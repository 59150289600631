import React, { Component } from "react";
import { Row, Col, Card, notification } from "antd";
import ajax from "../../../../../services/ajax";
import { urlLinkPath } from "../../../../../services/urlPath";

class Stats extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      levelList: [],
    };
  }

  componentWillMount() {
    this.getStats();
  }

  getStats = () => {
    ajax("get", urlLinkPath.ftChannelDashboard).then(
      ({ result }) => {
        this.setState({
          levelList: result,
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
      }
    );
  };

  render() {
    const { loading, levelList } = this.state;
    let menuItems = [];
    for (var i = 0; i < 8; i++) {
      menuItems.push(
        <Col xl={4} lg={6} md={8} sm={12} key={i}>
          <Card loading={loading}>
            <div className={"title"}>{`Level ${i + 1}`}</div>
            <div className={"value"}>{levelList[i] ?? 0}</div>
          </Card>
        </Col>
      );
    }
    return (
      <div className="stats">
        <Row gutter={12}>{menuItems}</Row>
      </div>
    );
  }
}

export default Stats;
