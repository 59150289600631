import React, { Component } from "react";
import {
  notification,
  Button,
  Table,
  Tag,
  Drawer,
  Select,
  message,
} from "antd";
import moment from "moment";
import ajax from "../../../services/ajax";
import FtMemberAction from "./FtMemberAction";
import { urlPath, urlLinkPath } from "../../../services/urlPath";

const { Option } = Select;
class PayoutComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      list: [],
      referralList: [],
      viewUser: false,
      loading: true,
      selectedUser: {},
      level: 1,
      payoutType: "FTChannel",
      status: "PENDING",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
      selectedRowKeys: [],
      loadingButton: false,
      disabledBtn: false,
    };
  }

  componentWillMount() {
    this.getUsers();
    this.getTableColumn();
  }

  showUserDetails = (user) => {
    this.setState({
      viewUser: true,
      selectedUser: user,
    });
  };

  closeViewMode = () => {
    this.setState({
      viewUser: false,
      selectedUser: {},
    });
  };

  getUsers = () => {
    let { pagination, level, status, payoutType } = this.state;

    if (payoutType == "FTChannel") {
      ajax("get", urlLinkPath.channelPayouts, {
        page: pagination.current,
        level,
        status,
      }).then(
        (response) => {
          this.setState({
            viewUser: false,
            list: response ? response.data : [],
            loading: false,
          });
        },
        ({ msg }) => {
          notification.error({
            message: "Error",
            description: msg,
          });
          this.setState({
            viewUser: false,
            list: [],
            loading: false,
          });
        }
      );
    } else {
      ajax("get", urlLinkPath.referrerPayouts, {
        page: pagination.current,
        status,
      }).then(
        (response) => {
          this.setState({
            viewUser: false,
            referralList: response ? response.data : [],
            loading: false,
          });
        },
        ({ msg }) => {
          notification.error({
            message: "Error",
            description: msg,
          });
          this.setState({
            viewUser: false,
            list: [],
            loading: false,
          });
        }
      );
    }
  };
  getTableColumn = (payoutType = this.state.payoutType) => {
    this.columns = [
      {
        title: "Name",
        width: 200,
        dataIndex: "name",
        key: "name",
        render: (text, record) =>
          `${record.userInfo.firstName} ${record.userInfo.lastName}`,
      },
      {
        title: "Phone",
        width: 100,
        dataIndex: "phone",
        key: "phone",
        render: (text, record) => `${record.userInfo.phone}`,
      },
      {
        title: "Account Details",
        width: 100,
        dataIndex: "accountDetails",
        key: "accountDetails",
        render: (text, record) =>
          record.userInfo.razorpayAccountDetails ? (
            <Tag color={"#34b7e3"}>Submitted</Tag>
          ) : (
            <Tag color={"#ff443f"}>Not Submitted</Tag>
          ),
      },
      {
        title: "Level",
        width: 100,
        dataIndex: "level",
        key: "level",
      },
      {
        title: "Member Count",
        width: 100,
        dataIndex: "memberCount",
        key: "memberCount",
      },
      {
        title: "Amount",
        dataIndex: "amount",
        key: "amount",
        render: (text) => text,
      },
      {
        title: "Payment Status",
        width: 100,
        dataIndex: "status",
        key: "status",
        // render: (text) => text ? <Tag color={'#34b7e3'}>ACTIVE</Tag>: <Tag color={'#ff443f'}>INACTIVE</Tag>
      },
      {
        title: "Payout Type",
        width: 100,
        dataIndex: "payoutType",
        key: "payoutType",
        render: (text) => text,
      },
      {
        title: "Failure Reason",
        dataIndex: "failureReason",
        key: "failureReason",
        render: (text, record) =>
          record.razorpayPayout && record.razorpayPayout.failureReason,
      },
      {
        title: "Created on",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        render: (text) => moment(text).format("DD-MM-YYYY"),
      },
    ];
    var startIndex = this.columns
      .map((column) => {
        return column.key;
      })
      .indexOf("level");
    if (payoutType == "Referrers") {
      this.columns.splice(startIndex, 2);
    }
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
      this.getUsers
    );
  };

  onHandleOnChange = (val) => {
    this.setState(
      {
        level: val,
      },
      this.getUsers
    );
  };
  onHandlePayoutType = (val) => {
    this.setState(
      {
        payoutType: val,
      },
      this.getUsers
    );
    this.getTableColumn(val);
  };
  statusChange = (val) => {
    this.setState(
      {
        status: val,
      },
      this.getUsers
    );
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  payout = () => {
    const { selectedRowKeys } = this.state;
    if (!selectedRowKeys.length) {
      message.error("No users selected");
    } else {
      this.setState({
        loadingButton: true,
      });
      ajax("post", urlPath.payouts, { payoutIds: selectedRowKeys }).then(
        (response) => {
          message.success("Payment status will be updated shortly");

          this.setState(
            {
              loadingButton: false,
              selectedRowKeys: [],
            },
            this.getUsers
          );
        },
        (error) => {
          notification.error({
            message: "Error",
            description: error && error.msg ? error.msg : "",
          });
          this.setState({
            loadingButton: false,
          });
        }
      );
    }
  };
  sendPayout = () => {
    this.setState({
      disabledBtn: true,
    });
    ajax("post", urlLinkPath.sendPayouts).then(
      (response) => {
        message.success("Payment are forwarded to razorpay");
        this.setState(
          {
            loadingButton: false,
          },
          this.getUsers
        );
        window.location.reload();
      },
      (error) => {
        notification.error({
          message: "Error",
          description: error && error.msg ? error.msg : "",
        });
        this.setState({
          loadingButton: false,
        });
      }
    );
  };
  getPayoutUpdate = () => {
    const { selectedRowKeys } = this.state;
    if (!selectedRowKeys.length) {
      message.error("No users selected");
    } else {
      this.setState({
        loadingButton: true,
      });
      ajax("get", urlLinkPath.payoutUpdate, {
        payoutIds: selectedRowKeys,
      }).then(
        (response) => {
          message.success("Payment status will be updated shortly");

          this.setState(
            {
              loadingButton: false,
              selectedRowKeys: [],
            },
            this.getUsers
          );
        },
        (error) => {
          notification.error({
            message: "Error",
            description: error && error.msg ? error.msg : "",
          });
          this.setState({
            loadingButton: false,
          });
        }
      );
    }
  };
  render() {
    const {
      list,
      loading,
      pagination,
      payoutType,
      viewUser,
      selectedUser,
      selectedRowKeys,
      loadingButton,
      referralList,
      status,
      disabledBtn,
    } = this.state;
    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
    };

    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>Payout</div>
        </div>
        <div>
          <Select
            defaultValue="FTChannel"
            style={{ width: 150 }}
            onChange={this.onHandlePayoutType}
          >
            <Option value="FTChannel">FT Channel</Option>
            <Option value="Referrers">Referrers</Option>
          </Select>
        </div>
        {payoutType != "Referrers" ? (
          <Select
            defaultValue="1"
            style={{ width: 150 }}
            onChange={this.onHandleOnChange}
          >
            <Option value="1">1</Option>
            <Option value="2">2</Option>
            <Option value="3">3</Option>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
            <Option value="6">6</Option>
            <Option value="7">7</Option>
            <Option value="8">8</Option>
          </Select>
        ) : (
          <></>
        )}

        <Select
          defaultValue="PENDING"
          style={{ width: 150 }}
          onChange={this.statusChange}
        >
          <Option value="PENDING">Pending</Option>
          <Option value="INPROGRESS">In Progress</Option>
          <Option value="PROCESSING">Processing</Option>
          <Option value="PAID">Paid</Option>
          <Option value="FAILED">Failed</Option>
        </Select>
        {status === "PENDING" && selectedRowKeys.length > 0 && (
          <Button loading={loadingButton} onClick={this.payout}>
            Payout
          </Button>
        )}
        {(status === "INPROGRESS" || status === "FAILED") &&
          selectedRowKeys.length > 0 && (
            <Button
              loading={loadingButton}
              disabled={disabledBtn}
              onClick={this.sendPayout}
            >
              Send Payout To Razorpay
            </Button>
          )}
        {status === "PROCESSING" && selectedRowKeys.length > 0 && (
          <Button loading={loadingButton} onClick={this.getPayoutUpdate}>
            Get Payout Update
          </Button>
        )}
        <Table
          loading={loading}
          rowKey={"id"}
          columns={this.columns}
          pagination={pagination}
          dataSource={payoutType == "Referrers" ? referralList : list}
          rowSelection={rowSelection}
        />

        {viewUser && (
          <Drawer width={520} visible={viewUser} onClose={this.closeViewMode}>
            <FtMemberAction
              user={selectedUser}
              updateUserList={this.getUsers}
            />
          </Drawer>
        )}
      </div>
    );
  }
}

export default PayoutComponent;
