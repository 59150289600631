import React, { Component } from "react";
import { notification, Button, Table, Tag, Row, Col, Input, Form } from "antd";
import moment from "moment";
import ajax from "../../../services/ajax";
import { urlLinkPath } from "../../../services/urlPath";
import { USER_TYPE } from "../../../services/constants";
import SearchBox from "../../_common/SearchBox";

class FtPayUserComponent extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Name",
        width: 200,
        dataIndex: "name",
        key: "name",
        render: (text, record) => `${record.firstName} ${record.lastName}`,
      },
      {
        title: "Phone",
        width: 100,
        dataIndex: "phone",
        key: "userId",
        render: (text, record) => text,
      },
      {
        title: "City",
        width: 100,
        dataIndex: "city",
        key: "city",
        render: (text, record) => record.city && `${record.city.name}`,
      },
      {
        title: "Status",
        width: 100,
        dataIndex: "visible",
        key: "visible",
        render: (text) =>
          text ? (
            <Tag color={"#34b7e3"}>ACTIVE</Tag>
          ) : (
            <Tag color={"#ff443f"}>BLOCKED</Tag>
          ),
      },
      {
        title: "Created on",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        render: (text) => moment(text).format("DD-MM-YYYY"),
      },
    ];

    this.state = {
      list: [],
      loading: true,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
      },
    };
  }

  componentWillMount() {
    this.getFtPayUsers();
  }

  getFtPayUsers = () => {
    let { pagination } = this.state;
    ajax("get", urlLinkPath.ftPayUserList, { page: pagination.current }).then(
      ({ result }) => {
        pagination.total = result.count;
        this.setState({
          pagination: pagination,
          list: result.list,
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          list: [],
          loading: false,
        });
      }
    );
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
      this.getFtPayUsers
    );
  };
  handleCallback = (searchList) => {
    this.setState({ list: searchList });
  };
  render() {
    const { list, loading, pagination } = this.state;

    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>FT Pay Users</div>
        </div>
        <SearchBox
          pagination={pagination}
          userType={USER_TYPE.FTPAYUSER}
          searchBoxCallback={this.handleCallback}
        />
        <Table
          loading={loading}
          rowKey={"id"}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />
      </div>
    );
  }
}

export default FtPayUserComponent;
