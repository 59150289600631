import React, { Component } from "react";
import { Form, Input, Button } from "antd";
import { phoneRules } from "../../../../services/validationRules";

class CBTreeReport extends Component {
  state = {
    loading: false,
    otp: false,
  };

  phone = "";

  getCBTreeReport = (ev) => {
    ev.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }

      this.props.getChildrenNodes(values);
      this.setState({
        loading: false,
      });
    });
  };

  render() {
    const { loading } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <div className={"sub-title"}>Channel Business Tree Report</div>
        <Form onSubmit={this.getCBTreeReport}>
          <Form.Item label={"Phone Number"} colon={false}>
            {getFieldDecorator("phone", {
              initialValue: this.props.user ? this.props.user.phone : null,
              rules: phoneRules,
            })(<Input size={"large"} placeholder={"897 XXX XXXX"} />)}
          </Form.Item>
          <div className={"btn-container"}>
            <Button
              loading={loading}
              type={"primary"}
              size={"large"}
              block
              htmlType={"submit"}
            >
              {"Get CB Tree Report"}
            </Button>
          </div>
        </Form>
      </>
    );
  }
}

export default Form.create()(CBTreeReport);
