import React, { Component } from "react";
import { notification, Button, Table, Tag, Card } from "antd";
import CBTreeReport from "./subcomponents/CBTreeReport";
import ajax from "../../../services/ajax";
import FtTreeNode from "./subcomponents/FtTreeNode";
import { urlPath, urlLinkPath } from "../../../services/urlPath";
import { apiBaseURL } from "../../../config";
import { strigifyQuery } from "../../../services/queryHelper";

class FTChannelTreeComponent extends Component {
  constructor(props) {
    super(props);
    this.authorization = localStorage.getItem("token");
    this.state = {
      loading: true,
      myDescendents: [],
      isLinkDisabled: true,
    };
  }

  componentWillMount() {}

  getChildrenNodes = (values) => {
    ajax("get", urlLinkPath.ftChannelUserChildren, values).then(
      ({ user }) => {
        const link = strigifyQuery({
          authorization: this.authorization,
          userId: user.id,
        });
        this.setState({
          myDescendents: [user],
          downloadLink: `${apiBaseURL}/ft-channel-tree/download${link}`,
          loading: false,
          isLinkDisabled: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          loading: false,
        });
      }
    );
  };
  getDescendants = (parentId) => {
    return ajax("get", urlLinkPath.ftChannelUserDescendent, {
      userId: parentId,
    }).then(
      ({ user, descendent }) => {
        return { user, descendent };
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          loading: false,
        });
      }
    );
  };
  render() {
    const { myDescendents, isLinkDisabled, downloadLink } = this.state;
    return (
      <div className={"ft-channel-tree"}>
        <div className={"header"}>
          <div className={"page-title"}>FT Channel Business Partner Tree</div>
          <Card>
            <CBTreeReport getChildrenNodes={this.getChildrenNodes} />
          </Card>
          {myDescendents.map((child, index) => (
            <FtTreeNode
              info={child.userInfo}
              getDescendants={this.getDescendants}
              key={`ftParent_${index}`}
              parentID={child.id}
            />
          ))}
        </div>
        <div className={"spacer"} />
        <a href={downloadLink} target={"_blank"} disabled={isLinkDisabled}>
          <Button>Download Report</Button>
        </a>
      </div>
    );
  }
}

export default FTChannelTreeComponent;
