import React, {Component} from 'react';
import moment from 'moment';
import {notification, Button, Table, Drawer} from 'antd';
import ajax from '../../../services/ajax';

import PushNotificationAction from './PushNotificationAction';
import PushNotificationView from './PushNotificationView';
import { urlPath } from '../../../services/urlPath';

class PushNotificationComponent extends Component{

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Date & Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) => moment(text).format('DD MMM YYYY HH:SS')
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
      },
      {
        title: 'Notification Type',
        dataIndex: 'notificationType',
        key: 'notificationType',
      },
      {
        title: 'Action',
        key: 'operation',
        render: (text, record) => (
          <div className="btn-grp">
            <Button onClick={() => { this.showPushDetails(record)}} type={'default'}>View</Button>
          </div>
        ),
      },
    ];

    this.state = {
      list: [],
      pushAction: false,
      loading: true,
      mode: 'ADD',
      pushSelected: null,
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    }
  }

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getNotificationList)
  };

  componentWillMount() {
    this.getNotificationList();
  }

  getNotificationList = () => {
    let { pagination } = this.state;
    ajax('get', urlPath.pushNotification,{ page: pagination.current}).then(({result}) => {
      pagination.total = result.count;
      this.setState({
        list: result.list,
        pagination: pagination,
        loading: false,
        pushAction: false,
        pushSelected: null,
        mode: 'ADD'
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  addPush = () => {
    this.setState({
      pushAction: true,
      pushSelected: null,
      mode: 'ADD'
    })
  };

  showPushDetails = (record) => {
    this.setState({
      pushAction: true,
      pushSelected: record,
      mode: 'VIEW'
    });
  };

  closeViewMode = () => {
    this.setState({
      pushAction: false,
      pushSelected: {}
    })
  }
  render(){

    const {list, mode, pagination, pushAction, loading, pushSelected} = this.state;

    return(
      <div className={'admin-module'}>
        <div className={'header'}>
          <div className={'page-title'}>Push Notifications</div>
        </div>

        <div className={'admin-module-body'}>
          <Button type={'primary'} size={'large'} onClick={this.addPush}>New Push Notification</Button>
          <Table rowKey={'id'} loading={loading} columns={this.columns} pagination={pagination} dataSource={list}/>
        </div>

        {
          pushAction && (
            <Drawer
              width={520}
              visible={pushAction}
              onClose={this.closeViewMode}
            >
              {
                {
                  'ADD':  <PushNotificationAction updateNotificationList={this.getNotificationList}/>,
                  'VIEW': <PushNotificationView notification={pushSelected} updateNotificationList={this.getNotificationList}/>
                }[mode]
              }
            </Drawer>
          )
        }
      </div>
    )
  }
}

export default PushNotificationComponent;