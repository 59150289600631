import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {Button} from 'antd';
import { path } from '../../../../config';

class CompleteProcess extends Component{
  render() {
    const {
      user = {firstName: '', lastName: '', phone: ''},
      business = {name: ''},
      businessCategory = { name: ''},
    } = this.props;

    return (
      <>
        <div className={'sub-title align-center'}>Congratulation!</div>
        <div className={'completion align-center'}>
          <h4>{user.firstName + ' ' + user.lastName}</h4>
          <h3>{user.phone}</h3>
          <h2 className={'bold'}>{business.name}</h2>
          <p>{businessCategory.name}</p>
        </div>
        <h3 className={'align-center'}>Business Account has been setup!</h3>
        <Link to={path.dashboard}>
          <Button type={'primary'} block size={'large'}>Go to Dashboard</Button>
        </Link>
      </>
    );
  }
}

export default CompleteProcess