import React, { useState } from "react";
import { Collapse } from "antd";
import FtTreeNode from "./FtTreeNode";
import FtTreeHOC from "./FtTreeHOC";

const { Panel } = Collapse;

const FtTreeNodeDescendant = (props) => {
  const {
    parentID,
    getDescendants,
    onExpand,
    descendants,
    info,
    showNoData,
    reachedMaxLevel,
  } = props;

  return (
    <Collapse
      className="site-collapse-custom-collapse"
      bordered={false}
      onChange={onExpand}
    >
      <Panel header={`${info.firstName} ${info.lastName}`} key={parentID}>
        {descendants.map((descendant, index) => (
          <FtTreeNode
            info={descendant.userInfo}
            getDescendants={getDescendants}
            key={`ftchild_${index}`}
            parentID={descendant.id}
          />
        ))}

        {reachedMaxLevel ? (
          <div>User reached max Level</div>
        ) : (
          <>{showNoData && <div>Has no children</div>}</>
        )}
      </Panel>
    </Collapse>
  );
};

export default FtTreeHOC(FtTreeNodeDescendant);
