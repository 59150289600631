import React, { Component } from "react";
import { notification, Popconfirm, Button, Table, Drawer } from "antd";
import ajax from "../../../services/ajax";

import AdPricingAction from "./AdPricingAction";
import { urlLinkPath } from "../../../services/urlPath";

class AdPricingComponent extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "State",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "Ad price",
        dataIndex: "adPrice",
        key: "adPrice",
      },
      {
        title: "Action",
        key: "operation",
        render: (text, record) => (
          <div className={"btn-grp"}>
            <Button
              type={"default"}
              onClick={() => {
                this.editAdPrice(record);
              }}
            >
              Edit
            </Button>
          </div>
        ),
      },
    ];

    this.state = {
      list: [],
      loading: true,
      selectedAdPrice: {},
      viewAdPrice: false,
    };
  }

  componentWillMount() {
    this.getSateWiseAdPriceList();
  }

  getSateWiseAdPriceList = () => {
    ajax("get", urlLinkPath.statewiseAdPrice, {}).then(
      ({ result }) => {
        this.setState({
          list: result,
          selectedAdPrice: {},
          viewAdPrice: false,
          loading: false,
        });
      },
      (err) => {
        notification.error({
          message: "Error",
          description: err && err.msg,
        });
        this.setState({
          list: [],
          selectedAdPrice: {},
          viewAdPrice: false,
          loading: false,
        });
      }
    );
  };

  editAdPrice = (ad) => {
    this.setState({
      viewAdPrice: true,
      selectedAdPrice: ad,
    });
  };

  closeEditMode = () => {
    this.setState({
      viewAdPrice: false,
    });
  };

  render() {
    const { list, loading, viewAdPrice, selectedAdPrice } = this.state;

    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>Statewise Ad Pricing</div>
        </div>

        <div className={"admin-module-body"}>
          <Table
            rowKey={"id"}
            loading={loading}
            columns={this.columns}
            pagination={false}
            dataSource={list}
          />
        </div>
        {viewAdPrice && (
          <Drawer
            width={520}
            visible={viewAdPrice}
            onClose={this.closeEditMode}
          >
            <AdPricingAction
              state={selectedAdPrice}
              updateSateWiseAdPriceList={this.getSateWiseAdPriceList}
            />
          </Drawer>
        )}
      </div>
    );
  }
}

export default AdPricingComponent;
