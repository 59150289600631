import React, {Component} from 'react';

import Stats from './subcomponents/Stats';
import Txn from './subcomponents/Txn';

class FTChannelDashboardComponent extends Component {

  render() {

    return (
      <div className={'dashboard'}>
        <div className={'header'}>
          <div className={'page-title'}>FT Channel Dashboard</div>
        </div>
        <Stats/>
        <Txn/>
      </div>
    )
  }
}

export default FTChannelDashboardComponent;