import React, { Component } from "react";
import { notification, Button, Table, Modal, Tag } from "antd";
import moment from "moment";
import ajax from "../../../services/ajax";
import { urlPath, urlLinkPath } from "../../../services/urlPath";
import SearchBox from "./SearchBox";
const { confirm } = Modal;
class InvitesComponent extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Sender",
        dataIndex: "userName",
        key: "userName",
        render: (text, record) => (
          <>
            {text} <Tag>{record.isAdmin ? "ADMIN" : "USER"}</Tag>
          </>
        ),
      },
      {
        title: "Sent To",
        dataIndex: "phone",
        key: "phone",
        render: (text) => text,
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => moment(text).format("DD-MM-YYYY HH:mm"),
      },
      {
        title: "Joined On",
        dataIndex: "joinedOn",
        key: "joinedOn",
        render: (text) =>
          text
            ? moment(text).format("DD-MM-YYYY HH:mm")
            : "User is not Registered",
      },
      {
        title: "Action",
        key: "operation",
        render: (text, record) => (
          <Button
            type={"danger"}
            disabled={record.joinedStatus}
            onClick={() => this.confirmDelete(record)}
          >
            Delete
          </Button>
        ),
      },
    ];

    this.state = {
      loading: true,
      list: [],
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }
  componentWillMount() {
    this.getInvites();
  }
  handleCallback = (searchList) => {
    this.setState({ list: searchList });
  };
  getInvites = () => {
    const { pagination } = this.state;
    ajax("get", urlPath.invites, {
      page: pagination.current,
    }).then(
      ({ result }) => {
        this.setState({
          list: result.list,
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          list: [],
          loading: false,
        });
      }
    );
  };
  deleteInvite = (invite) => {
    ajax("delete", urlPath.invites, { id: invite.id }).then(
      () => {
        notification.success({
          message: "Success",
          description: "Invite deleted!",
        });
        this.getInvites();
      },
      (err) => {
        notification.error({
          message: "Error",
          description: err && err.msg,
        });
      }
    );
  };
  confirmDelete = (record) => {
    confirm({
      title: "Delete Invite",
      content: "Are you sure?",
      onOk: () => {
        this.deleteInvite(record);
      },
    });
  };
  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
      this.getInvites
    );
  };

  render() {
    const { list, loading, pagination } = this.state;

    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>Invites</div>
        </div>
        <SearchBox
          pagination={pagination}
          searchBoxCallback={this.handleCallback}
        />
        <div className={"admin-module-body"}>
          <Table
            loading={loading}
            rowKey={"id"}
            columns={this.columns}
            pagination={pagination}
            dataSource={list}
          />
        </div>
      </div>
    );
  }
}

export default InvitesComponent;
