import React, {Component} from 'react';
import {notification, Table, Tag} from 'antd';
import moment from 'moment';
import ajax from '../../../services/ajax';
import { color } from '../../../config';
import { urlPath } from '../../../services/urlPath';

class AdminLogComponent extends Component{
  constructor(props){
    super(props);

    this.columns = [
      {
        title: 'Admin',
        width: 200,
        dataIndex: 'admin',
        key: 'admin',
        render: (text, record) => record.admin && (
          <>
            {record.admin.firstName} {' '} {record.admin.lastName} <Tag>{record.admin.adminRole.title}</Tag>
          </>
        )
      },
      {
        title: 'Action',
        width: 100,
        dataIndex: 'title',
        key: 'title',
        render: (text) => <Tag color={color.primary}>{text}</Tag>
      },
      {
        title: 'Description',
        dataIndex: 'message',
        key: 'message',
        width: 250,
      },
      {
        title: 'Module',
        width: 100,
        dataIndex: 'module',
        key: 'module',
        render: (text, record) => record.adminModule && <Tag>{record.adminModule.title}</Tag>
      },
      {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 200,
        render: (text) =>  moment(text).format('DD-MM-YYYY HH:mm')
      }
    ];

    this.state = {
      list: [],
      loading: true,
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    };
  }

  componentWillMount() {
    this.getLogs();
  }

  getLogs = () => {
    let { pagination } = this.state;
    ajax('get', urlPath.log,{ page: pagination.current}).then(({result}) => {
      pagination.total = result.count;
      this.setState({
        list: result.list,
        pagination: pagination,
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        list: [],
        loading: false
      })
    })
  }

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getLogs)
  };

  render(){

    const { list, loading, pagination } = this.state;

    return(
      <div>
        <div className={'header'}>
          <div className={'page-title'}>Admin Logs</div>
        </div>
        <Table
          rowKey={'id'}
          loading={loading}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />
      </div>
    )
  }
}

export default AdminLogComponent;
