import React, {Component} from 'react';
import {notification, Button, Table, Tag, Drawer} from 'antd';
import moment from 'moment';
import ajax from '../../../services/ajax';
import CounterEnlistmentAction from "./CounterEnlistmentAction";
import { urlPath } from '../../../services/urlPath';

class CounterEnlistmentComponent extends Component{
  constructor(props){
    super(props);

    this.columns = [
      {
        title: 'Name',
        width: 200,
        dataIndex: 'name',
        key: 'name',
        render: (text, record) => `${record.business?.name}`
      },
      {
        title: 'Phone',
        width: 100,
        dataIndex: 'phone',
        key: 'phone',
        render: (text, record) => `${record.business?.phone}`
      },
      {
        title: 'Discount (%)',
        width: 100,
        dataIndex: 'discount',
        key: 'discount',
        // render: (text, record) => record.city && `${record.city.name}`
      },
      {
        title: 'Created on',
        dataIndex: 'createdAt',
        key: 'createdAt',
        width: 200,
        render: (text) =>  moment(text).format('DD-MM-YYYY')
      },
      {
        title: 'Status',
        width: 100,
        dataIndex: 'status',
        key: 'status',
        render: (text, record) => `${record.isApproved}` === 'true' ? <Tag color={'#34b7e3'}>APPROVED</Tag> : (`${record.isRejected}` === 'true' ? <Tag color={'#ff443f'}>REJECTED</Tag> : <Tag>NONE</Tag>)
      },
      {
        title: 'Action',
        key: 'operation',
        width: 250,
        render: (text, record) => <Button onClick={() => { this.showUserDetails(record)}} >View</Button>,
      },
    ];

    this.state = {
      list: [],
      viewUser: false,
      loading: true,
      selectedUser: {},
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
  }
    };
  }

  componentWillMount() {
    this.getBusinesses();
  }

  showUserDetails = (user) => {
    this.setState({
      viewUser: true,
      selectedUser: user
    })
  };

  closeViewMode = () => {
    this.setState({
      viewUser: false,
      selectedUser: {}
    })
  }

  getBusinesses = () => {
    let { pagination } = this.state;
    ajax('get', urlPath.counterEnlist,{ page: pagination.current}).then(({result}) => {
      pagination.total = result.count;
      this.setState({
        viewUser: false,
        pagination: pagination,
        list: result.list,
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        viewUser: false,
        list: [],
        loading: false
      })
    })
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getBusinesses)
  };

  render(){

    const {list, loading, pagination, viewUser, selectedUser} = this.state;

    return(
      <div className={'admin-module'}>
        <div className={'header'}>
          <div className={'page-title'}>Counter Enlists</div>
        </div>
        <Table
          loading={loading}
          rowKey={'id'}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />

        {
          viewUser && (
            <Drawer
              width={520}
              visible={viewUser}
              onClose={this.closeViewMode}
            >
              <CounterEnlistmentAction counterEnlist={selectedUser} updateBusinessList={this.getBusinesses} />
            </Drawer>
          )
        }
      </div>
    )
  }
}

export default CounterEnlistmentComponent;


