import React, {Component} from 'react';
import {notification, Button, Table, Drawer, Tag} from 'antd';
import ajax from '../../../../services/ajax';

import AdminAction from './AdminAction';
import { urlLinkPath } from '../../../../services/urlPath';

class AdminComponent extends Component{

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Name',
        dataIndex: 'firstName',
        key: 'firstName',
        render: (text, record) => (`${record.firstName} ${record.lastName}`)
      },
      {
        title: 'Phone',
        dataIndex: 'phone',
        key: 'phone',
      },
      {
        title: 'Role',
        dataIndex: 'role',
        key: 'role',
        render: (text, record) => (record.adminRole.role)
      },
      {
        title: 'Parent',
        dataIndex: 'parent',
        key: 'parent',
        render: (text, record) => record.parentAdmin ? (`${record.parentAdmin.firstName} ${record.parentAdmin.lastName} (${record.parentAdmin.adminRole.role})`) : 'NONE'
      },
      {
        title: 'Referral Code',
        dataIndex: 'referralCode',
        key: 'referralCode',
        className: 'bold'
      },
      {
        title: 'Status',
        dataIndex: 'visible',
        key: 'visible',
        render: (text) => text ? <Tag color={'#34b7e3'}>ACTIVE</Tag>: <Tag color={'#ff443f'}>BLOCKED</Tag>
      },
      {
        title: 'Action',
        key: 'operation',
        render: (text, record) => <Button onClick={() => { this.updateAdminDetails(record)}} type={'default'}>Edit Admin</Button>,
      },
    ];

    this.state = {
      list: [],
      loading: true,
      roles: [],
      adminAction: false,
      mode: 'ADD',
      adminSelected: null,
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    }
  }

  componentWillMount() {
    this.getAdminList();
    this.getAdminRole();
  }

  getAdminList = () => {
    let { pagination } = this.state;
    ajax('get', urlLinkPath.adminList,{ page: pagination.current}).then(({result}) => {
      pagination.total = result.count;
      this.setState({
        list: result.list,
        pagination: pagination,
        adminAction: false,
        adminSelected: null,
        mode: 'ADD',
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        list: [],
        adminAction: false,
        adminSelected: null,
        mode: 'ADD',
        loading: false
      })
    })
  }

  getAdminRole = () => {
    ajax('get', urlLinkPath.roleList,{}).then(({list}) => {
      this.setState({
        roles: list
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
    })
  }

  addAdmin = () => {
    this.setState({
      adminAction: true,
      adminSelected: null,
      mode: 'ADD'
    })
  };

  updateAdminDetails = (record) => {
    this.setState({
      adminAction: true,
      adminSelected: record,
      mode: 'EDIT'
    });
  };

  closeViewMode = () => {
    this.setState({
      adminAction: false,
      adminSelected: {}
    })
  }
  
  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getAdminList)
  };
  render(){

    const {list, loading, mode, roles, adminAction, adminSelected, pagination} = this.state;

    return(
      <div className={'admin-module'}>
        <div className={'header'}>
          <div className={'page-title'}>Admins</div>
        </div>

        <div className={'admin-module-body'}>
          <Button type={'primary'} size={'large'} onClick={this.addAdmin}>Add Admin</Button>
          <Table
            rowKey={'id'}
            loading={loading}
            columns={this.columns}
            pagination={pagination}
            dataSource={list}/>
        </div>

        {
          adminAction && (
            <Drawer
              width={520}
              visible={adminAction}
              onClose={this.closeViewMode}
            >
              <AdminAction admin={adminSelected} mode={mode} roles={roles} updateAdminList={this.getAdminList}/>
            </Drawer>
          )
        }
      </div>
    )
  }
}

export default AdminComponent;