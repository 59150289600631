import React, {Component} from 'react';
import moment from 'moment';
import {Row, Col, Divider} from 'antd';
import DescriptionItem from '../../_common/DescriptionItem';

class PushNotificationView extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    }
  }


  render() {

    const { notification } = this.props;

    return (
      <div className={''}>
        <Row>
          <Col span={24}>
            <div className={'drawer-title'}>
              <h2>Push Notification Details</h2>
            </div>
          </Col>
        </Row>

        <div className="spacer"/>
        {
          notification.image && <img src={notification.image} alt={'image'}/>
        }

        <Divider/>

        <Row>
          <Col span={24}>
            <DescriptionItem title={'Title'} content={notification.title} />
          </Col>
          <Col span={24}>
            <DescriptionItem title={'Description'} content={notification.description} />
          </Col>
          <Col span={24}>
            <DescriptionItem title={'Type'} content={notification.notificationType} />
          </Col>
          <Col span={24}>
            <DescriptionItem title={'Time'} content={moment(notification.createdAt).format('DD MMM YYYY HH:SS')} />
          </Col>


        </Row>
      </div>
    )
  }
}

export default PushNotificationView;
