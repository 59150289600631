import React, {Component} from 'react';
import {NavLink} from "react-router-dom";
import {Avatar} from "antd";
import {path} from '../../config';

import logo from '../../images/logo.png';

const Modules = ({hideSidebar}) => {
  let modules = [];
  try{
    const modulesJSON = localStorage.getItem('modules');
    if(modulesJSON){
      modules = JSON.parse(modulesJSON);
    }
  }catch (e) {
    console.error(e)
  }

  return modules.map((module, index) => {
    return (
      <NavLink to={'/'+ module.key} key={index}>
        <li onClick={hideSidebar}>{module.title}</li>
      </NavLink>
    )
  })
};

const ProfileView = () => {
  let admin = {}
  try{
    const adminJSON = localStorage.getItem('admin');
    if(adminJSON){
      admin = JSON.parse(adminJSON);
    }
  }catch (e) {
    console.error(e)
  }

  const { firstName, lastName, referralCode, adminRole} = admin;

  return (
    <div className={'profile'}>
      <div className={'picture'}>
        <Avatar style={{ color: '#fff', backgroundColor: '#34b7e3' }} size={'large'}>
          {firstName[0] + lastName[0]}
        </Avatar>
      </div>
      <div className={'designation'}>
        <div className={'name'}>{firstName + ' ' + lastName}</div>
        <div className={'role'}>{adminRole.role}</div>
      </div>
      <div className={'referral'}>{referralCode}</div>
    </div>
  )
}

class SidebarComponent extends Component{

  logOut = () => {
    localStorage.clear();
    this.props.hideSidebar();
    this.props.history.push('/login');
  };

  render() {

    let admin = {}
    let isAdmin = false;
    try{
      const adminJSON = localStorage.getItem('admin');
      if(adminJSON){
        admin = JSON.parse(adminJSON);
      }
    }catch (e) {
      console.error(e)
    }
    const { adminRole } = admin;
    if(['ROOT', 'ADMIN'].indexOf(adminRole.role) !== -1){
      isAdmin = true;
    }

    return (
      <div className={'sidebar'}>
        <div className={'side-header'}>
          <div className={'logo'}>
            <img src={logo} alt={'Feedback Tower'}/>
          </div>
          <ProfileView/>
        </div>
        <div className={'side-body'}>
          <ul>
            <NavLink exact to={path.dashboard}>
              <li onClick={this.props.hideSidebar}>Dashboard</li>
            </NavLink>
            {
              isAdmin ? (
                <NavLink exact to={path.marketDashboard}>
                  <li onClick={this.props.hideSidebar}>Market Dashboard</li>
                </NavLink>
              ) : null
            }
            <Modules hideSidebar={this.props.hideSidebar}/>
            <NavLink exact to={path.report}>
              <li onClick={this.props.hideSidebar}>Referral Report</li>
            </NavLink>
          </ul>
        </div>
        <div className={'side-footer'}>
          <ul>
            <li onClick={this.logOut}>
              Log Out
            </li>
          </ul>
        </div>
      </div>
    )
  }
}

export default SidebarComponent;