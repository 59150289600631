import React, { Component } from "react";
import {
  notification,
  Button,
  Popconfirm,
  Row,
  Drawer,
  List,
  Card,
} from "antd";
import ajax from "../../../services/ajax";
import { color } from "../../../config";
import { urlPath, urlLinkPath } from "../../../services/urlPath";
import ReferralSchemeAction from "./ReferralSchemeAction";
import moment from "moment";

class ReferralSchemeComponent extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Id",
        width: 250,
        dataIndex: "endDate",
        key: "endDate",
        render: (text, record) => text,
      },
    ];

    this.state = {
      list: [],
      loading: true,
      mode: "ADD",
      viewReferralScheme: false,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }

  componentWillMount() {
    this.getReferralSchemes();
  }

  addReferralScheme = () => {
    this.setState({
      viewReferralScheme: true,
      mode: "ADD",
    });
  };
  editSchemeDetails = (scheme) => {
    this.setState({
      viewReferralScheme: true,
      mode: "EDIT",
      selectedScheme: scheme,
    });
  };
  deleteScheme = (scheme) => {
    ajax('delete', `${urlPath.referralScheme}/${scheme.id}`).then((res) => {
      this.handleSuccess({
        message: 'Success',
        description: 'Scheme deleted!'
      });
    }, ({msg}) => {
      notification.error({
        message: "Error",
        description: msg,
      });
    })
  };

  closeViewMode = () => {
    this.setState({
      viewReferralScheme: false,
    });
  };

  getReferralSchemes = () => {
    let { pagination } = this.state;
    ajax("get", urlLinkPath.referralSchemesList, {
      page: pagination.current,
    }).then(
      ({ result }) => {
        pagination.total = result.count;

        this.setState({
          viewLink: false,
          loading: false,
          pagination: pagination,
          selectedLink: {},
          mode: "ADD",
          list: result.list,
        });
        let expiredSchemes = [];
        result.list.map((scheme) => {
          if (moment(scheme.endDate).isSameOrBefore(moment(), "day")) {
            expiredSchemes.push(scheme);
          }
        });
        expiredSchemes.map((expiredScheme) => {
          result.list.shift();
          result.list.push(expiredScheme);
        });
        this.setState({ list: result.list });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          viewLink: false,
          loading: false,
          selectedLink: {},
          mode: "ADD",
          list: [],
        });
      }
    );
  };
  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
      this.getReferralSchemes
    );
  };
  
  handleSuccess = (msg) => {
    this.setState({
      loading: false,
    });
    notification.success(msg);
  };

  handleError = (msg) => {
    this.setState({
      loading: false,
    });
    notification.error({
      message: "Error",
      description: msg,
    });
  };


  render() {
    const { list, selectedScheme, pagination, viewReferralScheme, mode } =
      this.state;

    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>Referral Schemes</div>
        </div>

        <div className={"admin-module-body"}>
          <Button
            type={"primary"}
            size={"large"}
            onClick={this.addReferralScheme}
          >
            Add Referral Scheme
          </Button>
          <List
            itemLayout="vertical"
            size="large"
            pagination={pagination}
            dataSource={list}
            renderItem={(item, index) => (
              <List.Item key={item.id}>
                <Card
                  title={`Scheme ${index + 1} `}
                  extra={
                    <Row>
                      Start Date: {moment(item.startDate).format("DD-MM-YYYY")}{" "}
                      End Date: {moment(item.endDate).format("DD-MM-YYYY")}
                      <Popconfirm
                        placement={"topRight"}
                        title={"Are you sure you wish to delete this Scheme?"}
                        onConfirm={()=>this.deleteScheme(item)}
                        okText={"YES! Delete Scheme"}
                        okType={"danger"}
                        cancelText={"NO!"}
                      >
                        <Button size={"large"} type={"danger"} style={{marginRight: '15px',marginLeft: '15px'}}>
                          Delete
                        </Button>
                      </Popconfirm>
                      {moment(item.endDate).isSameOrBefore(moment(), "day") ? (
                        <Button size={"large"} onClick={() => this.editSchemeDetails(item)}>
                          Update
                        </Button>
                      ) : (
                        ""
                      )}
                    </Row>
                  }
                  bordered={false}
                  style={{
                    width: 700,
                    backgroundColor: moment(item.endDate).isSameOrBefore(
                      moment(),
                      "day"
                    )
                      ? "#dfe1e6"
                      : "",
                    boxShadow: moment(item.endDate).isSameOrBefore(
                      moment(),
                      "day"
                    )
                      ? "0px 0px 0px 0px"
                      : null,
                  }}
                >
                  {item.ranges.map((range, index) => (
                    <p>{`${String.fromCharCode(97 + index)})	${
                      index == 0 ? "First" : "Next"
                    } ${range.minRange} ${
                      range.maxRange == null
                        ? "and further"
                        : `to ${range.maxRange}`
                    } referrals: ${
                      range.referralCashPer
                    }% in digital cash and ${
                      range.referralCashPer == range.realCashPer
                        ? ""
                        : `${range.realCashPer}% in`
                    } real cash from registration fee.`}</p>
                  ))}
                </Card>
              </List.Item>
            )}
          />
        </div>

        {viewReferralScheme && (
          <Drawer
            width={520}
            visible={viewReferralScheme}
            onClose={this.closeViewMode}
          >
            <ReferralSchemeAction
              mode={mode}
              scheme={selectedScheme}
              updateLinkList={this.getReferralSchemes}
              closeDrawer={this.closeViewMode}
            />
          </Drawer>
        )}
      </div>
    );
  }
}

export default ReferralSchemeComponent;
