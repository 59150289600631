import React, {Component} from 'react';
import {Row, Col, Form, Input, InputNumber, Select, Button, notification, message, Modal, Popconfirm} from 'antd';

import ajax from '../../../services/ajax';
import { urlPath,urlLinkPath } from '../../../services/urlPath';

const { confirm } = Modal;

class PlanAction extends Component{

  constructor(props) {
    super(props);
    this.state = {
      DELETE_KEY: '',
      loading: false,
      states: []
    }
  }
  
  handleSuccess = (msg) => {
    this.setState({
      loading: false
    });
    notification.success(msg);
    this.props.updatePlanList();
  }

  handleError = (msg) => {
    this.setState({
      loading: false
    });
    notification.error({
      message: 'Error',
      description: msg
    });
  }

  submitForm = (ev) => {
    ev.preventDefault();
    const action = {
      ADD: this.addPlan,
      EDIT: this.updatePlan
    }[this.props.mode];
    action();
  };

  addPlan = () => {
    this.props.form.validateFields((err, values) => {
      if(err) return;
      this.setState({
        loading: true
      });
      ajax('post', urlPath.topupPlan,values).then((res) => {
        this.handleSuccess({
          message: 'Success',
          description: 'Plan added!'
        })
      }, ({msg}) => {
        this.handleError(msg);
      })
    })
  }

  updatePlan = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;

      this.setState({
        loading: true
      });
      values.id = this.props.plan.id;
      ajax('put',urlPath.topupPlan, values).then((res) => {
        this.handleSuccess({
          message: 'Success',
          description: 'Plan updated!'
        });
      }, ({msg}) => {
        this.handleError(msg);
      })
    });
  }

  handleVisibility = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.plan.id,
      status: !this.props.plan.visible
    };
    ajax('post', urlLinkPath.topupPlanVisibility,params).then((res) => {
      this.handleSuccess({
        message: 'Success',
        description: 'Plan status updated!'
      });
    }, ({msg}) => {
      this.handleError(msg);
    })
  };

  confirmDeletePlan = () => {
    confirm({
      title: 'Are you sure delete this TOPUP PLAN?',
      content: (
        <div>
          <Form.Item label={'Verify Topup Plan Name'}>
            <Input onChange={(ev)=> {this.setState({DELETE_KEY: ev.target.value})}}/>
          </Form.Item>
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        if(this.state.DELETE_KEY === this.props.plan.name){
          this.deletePlan()
        }else{
          message.error('Name doesnt match!')
        }
      }
    });
  }

  deletePlan = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.plan.id
    };
    ajax('delete', urlPath.topupPlan,params).then((res) => {
      this.handleSuccess({
        message: 'Success',
        description: 'Topup Plan deleted!'
      });
    }, ({msg}) => {
      this.handleError(msg);
    })
  }

  render() {
    const { loading, states } = this.state;
    const { plan, mode } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.submitForm}>
        <h2>Topup Plan Details</h2>
        <Form.Item label={'Plan Name'} colon={false}>
          {
            getFieldDecorator('name', {
              initialValue: plan && plan.name,
              rules: [{
                required: true,
                message: 'Please input name for plan!'
              }]
            })(<Input size={'large'}/>)
          }
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item label={'Price'} colon={false}>
              {
                getFieldDecorator('fee', {
                  initialValue: plan && plan.fee,
                  rules: [{
                    required: true,
                    message: 'Please input price for plan!'
                  }]
                })(<InputNumber size={'large'}/>)
              }
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'Max Wallet Cashback'} colon={false}>
              {
                getFieldDecorator('maxWalletCashback', {
                  initialValue: plan && plan.maxWalletCashback,
                  rules: [{
                    required: true,
                    message: 'Please input Max Wallet Cashback for plan!'
                  }]
                })(<InputNumber size={'large'}/>)
              }
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={'IOS Fee'} colon={false}>
              {
                getFieldDecorator('iosPrice', {
                  initialValue: plan && plan.iosPrice,
                  rules: [{
                    required: true,
                    message: 'Please input ios fee for plan!'
                  }]
                })(<InputNumber size={'large'}/>)
              }
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'Store Id (Product Id)'} colon={false}>
              {
                getFieldDecorator('storeId', {
                  initialValue: plan && plan.storeId,
                  rules: [{
                    required: true,
                    message: 'Please input Store Id for plan!'
                  }]
                })(<Input size={'large'}/>)
              }
            </Form.Item>
          </Col>
        </Row>
        <div>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'primary'}
            hidden={mode !=='ADD'}
            loading={loading}
          >
            Add Plan
          </Button>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'primary'}
            hidden={mode !== 'EDIT'}
          >
            Update Plan
          </Button>
        </div>

        <div className={'btn-grp opposite drawer-footer'} hidden={mode==='ADD'}>
          <Button
            size={'large'}
            onClick={this.handleVisibility}
            type={plan && plan.visible ? 'danger': 'primary'}
            loading={loading}
          >
            {plan && plan.visible ? 'Block Plan': 'Activate Plan'}
          </Button>
          <Popconfirm
            placement={'topRight'}
            title={'Are you sure you wish to delete this SUBSCRIPTION PLAN?'}
            onConfirm={this.confirmDeletePlan}
            okText={'YES! Delete Subscription Plan'} okType={'danger'}
            cancelText={'NO!'}
          >
            <Button size={'large'} type={'danger'} loading={loading}>Delete Plan</Button>
          </Popconfirm>
        </div>
      </Form>
    )
  }
}

export default Form.create()(PlanAction)