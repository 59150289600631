import React, {Component} from 'react';
import {Card, Form, Input, Button, notification } from 'antd';
import { Link } from 'react-router-dom';
import { path } from '../../../config';
import ajax from '../../../services/ajax';
import { urlLinkPath } from '../../../services/urlPath';
import { phoneRules, passwordRules } from "../../../services/validationRules";

class Login extends Component {

  state = {
    loading: false
  };

  componentWillMount() {
    localStorage.clear();
  }

  handleLogin = (ev) => {
    ev.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        return
      }

      this.setState({
        loading: true
      });

      ajax('post',urlLinkPath.login, values).then(({ token, admin, modules }) => {
        localStorage.setItem('token', token);
        localStorage.setItem('admin', JSON.stringify(admin));
        localStorage.setItem('modules', JSON.stringify(modules));
        this.props.history.push(path.dashboard);
      }, ({ msg }) => {
        notification.error({
          message: 'Error',
          description: msg
        });
        this.setState({
          loading: false
        });
      });
    });
  };

  render() {

    const { getFieldDecorator } = this.props.form;

    return (

      <div className={'login'}>
        <Card bordered={false}>
          <div className={'title'}>Admin Log In</div>
          <Form onSubmit={this.handleLogin}>
            <Form.Item label={'Phone Number'} colon={false}>
              {getFieldDecorator('phone', {
                rules: phoneRules,
              })( <Input size={'large'} placeholder={'897 XXX XXXX'}/> )}
            </Form.Item>
            <Form.Item label={'Password'} colon={false} >
              {getFieldDecorator('password', {
                rules: passwordRules,
              })( <Input.Password size={'large'} placeholder={'******'}/> )}
            </Form.Item>
            <Link to={path.reset}>Forgot your Password ?</Link>

            <div className={'btn-container'}>
              <Button loading={this.state.loading} type={'primary'} size={'large'} block htmlType={'submit'}>Login</Button>
            </div>
          </Form>
        </Card>
      </div>
    )
  }
}

export default Form.create()(Login);