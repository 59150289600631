import React, {Component} from 'react';
import {Form, Input, Button, notification} from 'antd';
import {phoneRules, otpRules} from '../../../../services/validationRules';
import ajax from '../../../../services/ajax';
import { urlLinkPath } from '../../../../services/urlPath';

class VerifyNumber extends Component{
  state = {
    loading: false,
    otp: false
  };

  phone = '';

  verify = (ev) => {
    ev.preventDefault();

    this.props.form.validateFields((err, values) => {
      if(err){
        return;
      }

      this.setState({
        loading: true
      });

      if(!this.state.otp){ // step 1
        this.phone = values.phone;
        this.requestOTP(true);
      }else{ // step 2
        ajax('post',urlLinkPath.verifyBusinessOtp, values).then(({ user, msg }) => {
          notification.success({
            message: 'Success',
            description: msg
          });
          this.setState({
            loading: false
          });
          this.props.nextStep(user);
        }, ({ msg }) => {
          notification.error({
            message: 'Error',
            description: msg
          });
          this.setState({
            loading: false
          });
        });
      }
    });
  };

  requestOTP = (preRegister) => {
    ajax('post',`/add-business/${preRegister ? 'register' : 'request-otp'}`, {phone: this.phone}).then(({ msg }) => {
      notification.success({
        message: 'Success',
        description: msg
      });
      this.setState({
        otp: true,
        loading: false
      });
    }, ({ msg }) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        loading: false
      });
    });
  };

  render() {

    const {otp, loading} = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <div className={'sub-title'}>Verify Phone Number</div>
        <Form onSubmit={this.verify}>
          <Form.Item label={'Phone Number'} colon={false}>
            {
              getFieldDecorator('phone', {
                initialValue: this.props.user ? this.props.user.phone : null,
                rules: phoneRules
              })(<Input size={'large'} placeholder={'897 XXX XXXX'}/>)
            }
          </Form.Item>
          {
            otp ? (
              <>
                <Form.Item label={'OTP Code'} colon={false}>
                  {
                    getFieldDecorator('otp', {
                      rules: otpRules
                    })(<Input.Password size={'large'} placeholder={'*** ***'}/>)
                  }
                </Form.Item>
                <div className={'link'}>Not received OTP yet? <a onClick={ () => this.requestOTP()}>Resend OTP!</a></div>
              </>
            ) : null
          }
          <div className={'btn-container'}>
            <Button loading={loading} type={'primary'} size={'large'} block htmlType={'submit'}>
              { !otp ? 'Verify Phone Number' : 'Confirm Phone Number' }
            </Button>
          </div>
        </Form>
      </>
    );
  }
}

export default Form.create()(VerifyNumber);