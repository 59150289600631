import React, {Component} from 'react';
import {notification, Table, Tag, DatePicker, Card, Button} from 'antd';
import moment from 'moment';
import ajax from '../../../services/ajax';
import {color, apiBaseURL} from '../../../config';
import { strigifyQuery } from '../../../services/queryHelper';
import { urlPath } from '../../../services/urlPath';

const { RangePicker } = DatePicker;

class MarketingExecutiveReport extends Component{
  constructor(props){
    super(props);
    this.authorization = localStorage.getItem('token');
    this.columns = [
      {
        title: 'Business',
        dataIndex: 'business',
        key: 'business',
        render: (text, record) => record.business && record.business.name
      },
      {
        title: 'User',
        dataIndex: 'user',
        key: 'user',
        render: (text, record) => record.user && `${record.user.firstName} ${record.user.lastName}`
      },
      {
        title: 'Type',
        dataIndex: 'referralType',
        key: 'referralType',
        render: (text) => ({
          DASHBOARD: <Tag color={color.primary}>DASHBOARD</Tag>,
          REFERRAL_CODE: <Tag color={color.accentDark}>REFERRAL CODE</Tag>,
        }[text])
      },
      {
        title: 'Amount',
        dataIndex: 'amount',
        key: 'amount',
      },
      {
        title: 'Created on',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) =>  moment(text).format('DD-MM-YYYY HH:mm')
      },
    ];

    this.state = {
      list: [],
      loading: true,
      viewReferral: false,
      selectedReferral: {},
      dateRange: [moment().subtract(6,'d'), moment()],
      downloadLink: '',
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    };
  }

  componentWillMount() {
    this.getReferralReport();
  }

  dateRangeChange = (range) => {
    if(!(range[0] && range[1])){
      range = [moment().subtract(7,'days'), moment()];
    }
    this.setState({
      dateRange: range
    }, this.getReferralReport)
  };

  getReferralReport = () => {
    let { dateRange, pagination } = this.state;

    const params = {
      startDate: dateRange[0].format('YYYY-MM-DD'),
      endDate: dateRange[1].format('YYYY-MM-DD'),
      page: pagination.current
    };
    ajax('post', urlPath.marketReport,params).then(({result}) => {
      pagination.total = result.count;

      const link = strigifyQuery({
        authorization: this.authorization,
        startDate: dateRange[0].format('YYYY-MM-DD'),
        endDate: dateRange[1].format('YYYY-MM-DD'),
      });

      this.setState({
        downloadLink: `${apiBaseURL}${urlPath.marketReport}/download${link}`,
        list: result.list,
        pagination: pagination,
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        list: [],
        loading: false
      })
    })
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getReferralReport)
  };

  render(){

    const {list, dateRange, loading, downloadLink, pagination} = this.state;

    return(
      <div>
        <div className={'header'}>
          <div className={'page-title'}>Report</div>
        </div>

        <Card className={'table-card'} extra={(
          <RangePicker
            value={dateRange}
            format={'DD/MM/YYYY'}
            separator={'to'}
            onChange={this.dateRangeChange}
          />
        )}>
          <Table
            rowKey={'id'}
            loading={loading}
            columns={this.columns}
            pagination={pagination}
            dataSource={list}
          />
        </Card>

        <div className={'spacer'}/>
        <a href={downloadLink} target={'_blank'} disabled={list.length === 0}><Button>Download Report</Button></a>

      </div>
    )
  }
}

export default MarketingExecutiveReport;

