import React, {Component} from 'react';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { EditorState, convertToRaw, ContentState } from 'draft-js';


class Wysiwyg extends Component {
  constructor(props) {
    super(props);

    const contentBlock = htmlToDraft(props.value);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
        html: props.value,
        showEditorCode: false
      };
    } else {
        this.state = {
            editorState: EditorState.createEmpty(),
            html: ''
        };
    }
  }

  componentWillReceiveProps(nextProps) {
      console.log(nextProps);
      if(nextProps.value != this.state.html) {
          this.setState({
              html: nextProps.value,
          })
      }
  }
  
  onEditorStateChange = (editorState) => {
    this.setState({
      editorState,
    });
    this.props.setHtml(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  onEditEditorHTML = e => {
    const editorHTML = e.target.value

    let editor
    const contentBlock = htmlToDraft(editorHTML)
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks)
      editor = EditorState.createWithContent(contentState)
    } else {
      editor = EditorState.createEmpty()
    }
    this.setState({ editorState: editor, html: editorHTML })
  }

  toggleEditorCode = () => {
    const { showEditorCode } = this.state
    this.setState({ showEditorCode: !showEditorCode }, () => {
      if (!showEditorCode) {
        // autosize(this.textareaEditor)
        // autosize.update(this.textareaEditor)
      } else {
        // autosize.destroy(this.textareaEditor)
      }
    })
  }

  render() {
    const { editorState, showEditorCode, html } = this.state;
    const ShowEditorCode = () => (
      <div className="rdw-option-wrapper"
        onClick={this.toggleEditorCode}>
        {showEditorCode ? 'Hide' : 'Show'} Code
      </div>
    );
    return (
      <>
        <Editor
          editorState={editorState}
          wrapperClassName="demo-wrapper"
          editorClassName={showEditorCode ? 'demo-editor' : 'editorHide'}
          onEditorStateChange={this.onEditorStateChange}
          toolbarCustomButtons={[<ShowEditorCode />]}
        />
        { showEditorCode && <textarea
          ref={c => { this.textareaEditor = c }}
          value={html}
          onChange={this.onEditEditorHTML}
        /> }
      </>
    )
  }
}

export default Wysiwyg;