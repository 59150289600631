import React, { Component } from "react";
import { Form, Input, Button, notification, AutoComplete ,InputNumber} from "antd";
import ajax from "../../../services/ajax";
import {urlPath,urlLinkPath} from "../../../services/urlPath";

class ServiceAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      list: [],
    };
  }

  handleSuccess = (msg) => {
    this.setState({
      loading: false,
    });
    notification.success(msg);
    this.props.updateServiceList();
  };

  handleError = (msg) => {
    this.setState({
      loading: false,
    });
    notification.error({
      message: "Error",
      description: msg,
    });
  };

  componentWillMount() {
    this.getBusinesses();
  }
  getBusinesses = () => {
    ajax("get", urlLinkPath.businessList).then(
      ({ result }) => {
        this.setState({
          list: result.list,
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          list: [],
          loading: false,
        });
      }
    );
  };
  submitForm = (ev) => {
    ev.preventDefault();
    const action = {
      ADD: this.addService,
      EDIT: this.updateService,
    }[this.props.mode];
    action();
  };

  addService = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true,
      });
      const business = this.state.list.find((business) => values.business == business.name);
      values.businessId = business.id;
      values.businessName = business.name;
      ajax("post", urlPath.service, values).then(
        (res) => {
          this.handleSuccess({
            message: "Success",
            description: "Service added!",
          });
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );
    });
  };

  updateService = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true,
      });
      values.id = this.props.service.id;
      const business = this.state.list.find((business) => values.business == business.name);
      values.businessId = business.id;
      values.businessName = business.name;
      ajax("put",urlPath.service, values).then(
        (res) => {
          this.handleSuccess({
            message: "Success",
            description: "Service updated!",
          });
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );
    });
  };
  handleVisibility = () => {
    this.setState({
      loading: true,
    });

    const params = {
      id: this.props.service.id,
      status: !this.props.service.visible,
    };
    ajax("post", urlLinkPath.serviceVisibility, params).then(
      (res) => {
        this.handleSuccess({
          message: "Success",
          description: "Service status updated!",
        });
      },
      ({ msg }) => {
        this.handleError(msg);
      }
    );
  };

  render() {
    const { loading, list } = this.state;
    const { service, mode } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.submitForm} className="shopping-link-form">
        <h2>Service Details</h2>
        <Form.Item label={"Name"} colon={false}>
          {getFieldDecorator("name", {
            initialValue: service && service.name,
            rules: [
              {
                required: true,
                message: "Please input Name!",
              },
            ],
          })(<Input size={"large"} />)}
        </Form.Item>
        <Form.Item label={"Cost"} colon={false}>
          {getFieldDecorator("cost", {
            initialValue: service && service.cost,
            rules: [
              {
                required: true,
                message: "Please input Cost!",
              },
            ],
          })(<InputNumber  precision={2} />)}
        </Form.Item>
        <Form.Item label={"Business"} colon={false}>
          {getFieldDecorator("business", {
            initialValue: service && service.businessName,
          })(
            <AutoComplete
              size={"large"}
              dataSource={list.map((business) => business.name)}
              placeholder="Select a Business"
              filterOption={(inputValue, option) =>
                option.props.children.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          )}
        </Form.Item>

        <div>
          <Button
            htmlType={"submit"}
            size={"large"}
            type={"default"}
            hidden={mode !== "ADD"}
            loading={loading}
          >
            Add Service
          </Button>
          <Button
            htmlType={"submit"}
            size={"large"}
            type={"default"}
            hidden={mode !== "EDIT"}
          >
            Update Service
          </Button>
        </div>
        <div
          className={"btn-grp opposite drawer-footer"}
          hidden={mode === "ADD"}
        >
          <Button
            size={"large"}
            loading={loading}
            type={service && service.visible ? "danger" : "primary"}
            onClick={this.handleVisibility}
          >
            {service && service.visible ? "Block Service" : "Activate Service"}
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create()(ServiceAction);
