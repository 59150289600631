import React, { Component } from "react";
import { notification, Button, Table, Modal, Tag } from "antd";
import moment from "moment";
import ajax from "../../../services/ajax";
import { color } from "../../../config";
import { urlLinkPath } from "../../../services/urlPath";
const { confirm } = Modal;
class TerminationNoticeComponent extends Component {
  constructor(props) {
    super(props);

    this.columns = [
      {
        title: "Sender",
        dataIndex: "sender",
        key: "sender",
        render: (text, { sender }) =>
          sender && `${sender.firstName} ${sender.lastName}`,
      },
      {
        title: "Receiver",
        dataIndex: "receiver",
        key: "receiver",
        render: (text, { receiver }) =>
          receiver && `${receiver.firstName} ${receiver.lastName}`,
      },
      {
        title: "Notice",
        dataIndex: "notice",
        key: "notice",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (text) =>
          ({
            PENDING: <Tag color={color.disabled}>PENDING</Tag>,
            COMPLETED: <Tag color={color.success}>COMPLETED</Tag>,
            PROCESSING: <Tag color={color.accent}>PROCESSING</Tag>,
            DELETED: <Tag color={color.primary}>DELETED</Tag>,
          }[text]),
      },
      {
        title: "Last Updated At",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (text) => moment(text).format("DD-MM-YYYY HH:mm"),
      },
      {
        title: "Created At",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (text) => moment(text).format("DD-MM-YYYY HH:mm"),
      },
      {
        title: "Action",
        key: "operation",
        render: (text, record) => (
          <Button
            disabled={
              record.status === "COMPLETED" || record.status === "DELETED"
            }
            onClick={() => this.showTerminationConfirm(record)}
            type={"default"}
          >
            Stop Termination
          </Button>
        ),
      },
    ];

    this.state = {
      loading: true,
      list: [],
      noticeSelected: null,
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }
  showTerminationConfirm = (record) => {
    confirm({
      title: "Stop Termination",
      content: "Are you sure you wish to stop termination of this User?",
      onOk() {
        this.stopUserTermination(record);
      },
    });
  };
  componentWillMount() {
    this.getTerminationNoticeList();
  }

  getTerminationNoticeList = () => {
    const { pagination } = this.state;
    ajax("get", urlLinkPath.transactionNoticeList, {
      page: pagination.current,
    }).then(
      ({ result }) => {
        this.setState({
          list: result.list,
          noticeSelected: {},
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          list: [],
          noticeSelected: {},
          loading: false,
        });
      }
    );
  };

  stopUserTermination = (record) => {
    ajax("post", urlLinkPath.stopTermination, { id: record.id }).then(
      () => {
        notification.success({
          message: "Success",
          description: "Stopped Termination of the user",
        });
        this.getTerminationNoticeList();
      },
      (err) => {
        notification.error({
          message: "Error",
          description: err && err.msg,
        });
      }
    );
  };
  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getTerminationNoticeList)
  };
  render() {
    const { list, loading } = this.state;

    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>Termination Notice</div>
        </div>

        <div className={"admin-module-body"}>
          <Table
            loading={loading}
            rowKey={"id"}
            columns={this.columns}
            pagination={false}
            dataSource={list}
            scroll={{ x: 1500 }}
          />
        </div>
      </div>
    );
  }
}

export default TerminationNoticeComponent;
