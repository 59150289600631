import React, { Component } from "react";
import { DatePicker, Card, notification, Button } from "antd";
import moment from "moment";
import ajax from "../../../../../services/ajax";
import { urlLinkPath } from "../../../../../services/urlPath";
import { Bar } from "react-chartjs-2";
import { apiBaseURL } from "../../../../../config";
import { strigifyQuery } from "../../../../../services/queryHelper";

const { RangePicker } = DatePicker;
export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: "top",
    },
    title: {
      display: true,
      text: "FT Channel Bar Chart",
    },
  },
};

const getData = (labels, data) => {
  return {
    labels: labels,
    datasets: [
      {
        label: "Level 1",
        data: data.level1,
        backgroundColor: "#4572a7",
      },
      {
        label: "Level 2",
        data: data.level2,
        backgroundColor: "#aa4643",
      },
      {
        label: "Level 3",
        data: data.level3,
        backgroundColor: "#89a54e",
      },
      {
        label: "Level 4",
        data: data.level4,
        backgroundColor: "#71588f",
      },
      {
        label: "Level 5",
        data: data.level5,
        backgroundColor: "#4198af",
      },
      {
        label: "Level 6",
        data: data.level6,
        backgroundColor: "#db843d",
      },
      {
        label: "Level 7",
        data: data.level7,
        backgroundColor: "#93a9cf",
      },
      {
        label: "Level 8",
        data: data.level8,
        backgroundColor: "#d19392",
      },
    ],
  };
};
class Txn extends Component {
  constructor(props) {
    super(props);
    this.authorization = localStorage.getItem("token");
    this.state = {
      loading: true,
      data: {},
      labels: [],
      downloadLink: "",
      isLinkDisabled: true,
      dateRange: [moment().subtract(6, "d"), moment()],
    };
  }

  componentWillMount() {
    this.getFTChannelUsers();
  }

  dateRangeChange = (range) => {
    if (!(range[0] && range[1])) {
      range = [moment().subtract(7, "days"), moment()];
    }
    this.setState(
      {
        dateRange: range,
      },
      this.getFTChannelUsers
    );
  };

  getFTChannelUsers = () => {
    const { dateRange } = this.state;

    const currDate = dateRange[0].startOf("day").clone();
    const lastDate = dateRange[1].startOf("day").clone();

    let dates = [];
    let labels = [];
    let level1 = [];
    let level2 = [];
    let level3 = [];
    let level4 = [];
    let level5 = [];
    let level6 = [];
    let level7 = [];
    let level8 = [];
    let data = {};
    do {
      labels.push(currDate.clone().format("DD MMM"));
      dates.push(currDate.clone().format("YYYY-MM-DD"));
      level1.push(0);
      level2.push(0);
      level3.push(0);
      level4.push(0);
      level5.push(0);
      level6.push(0);
      level7.push(0);
      level8.push(0);
    } while (currDate.add(1, "days").diff(lastDate) <= 0);

    if (labels.length > 31) {
      notification.warn({
        message: "Range Warning",
        description: "Please select date range less than or equal to 31 days!",
      });
      return;
    }

    const params = {
      startDate: dateRange[0].format("YYYY-MM-DD"),
      endDate: dateRange[1].format("YYYY-MM-DD"),
    };

    ajax("post", urlLinkPath.ftChannelRes, params).then(
      ({ result }) => {
        result.forEach((item) => {
          const data = item;
          const index = dates.indexOf(
            moment(data.createdAt).format("YYYY-MM-DD")
          );
          if (index !== -1) {
            switch (data.hierarchyLevel) {
              case 1:
                level1[index] = level1[index] + 1;
                break;
              case 2:
                level2[index] = level2[index] + 1;
                break;
              case 3:
                level3[index] = level3[index] + 1;
                break;
              case 4:
                level4[index] = level4[index] + 1;
                break;
              case 5:
                level5[index] = level5[index] + 1;
                break;
              case 6:
                level6[index] = level6[index] + 1;
                break;
              case 7:
                level7[index] = level7[index] + 1;
                break;
              case 8:
                level8[index] = level8[index] + 1;
                break;
              default:
                break;
            }
          }
        });
        let isLinkDisabled = [];
        data = {
          level1,
          level2,
          level3,
          level4,
          level5,
          level6,
          level7,
          level8,
        };
        for (let key in data) {
          isLinkDisabled.push(
            data[key].some((item) => {
              return item != 0;
            })
          );
        }
        if (isLinkDisabled.some((item) => item == true)) {
          const link = strigifyQuery({
            authorization: this.authorization,
            startDate: dateRange[0].format("YYYY-MM-DD"),
            endDate: dateRange[1].format("YYYY-MM-DD"),
          });
          this.setState({
            downloadLink: `${apiBaseURL}/dashboard/download/ft-channel-registrations${link}`,
          });
        }
        this.setState({
          loading: false,
          labels: labels,
          data: data,
          isLinkDisabled: !isLinkDisabled.some((item) => item == true),
        });
      },
      (err) => {
        notification.error({
          message: "Error",
          description: err && err.msg,
        });
      }
    );
  };

  render() {
    const { dateRange, labels, data, downloadLink, isLinkDisabled } =
      this.state;
    const barData = getData(labels, data);
    return (
      <div className={"txn"}>
        <Card
          className={"txn-card"}
          title={"Visual Report"}
          extra={
            <RangePicker
              value={dateRange}
              format={"DD/MM/YYYY"}
              separator={"to"}
              onChange={this.dateRangeChange}
            />
          }
        >
          <h2>All Channel Level Users</h2>
          <Bar options={options} data={barData} />
        </Card>
        <div className={"spacer"} />
        <a href={downloadLink} target={"_blank"} disabled={isLinkDisabled}>
          <Button>Download Report</Button>
        </a>
      </div>
    );
  }
}

export default Txn;
