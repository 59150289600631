import React, { Component } from "react";
import { notification, Button, Table, Tag, Drawer, Select } from "antd";
import moment from "moment";
import ajax from "../../../services/ajax";
import InvoiceAction from "./InvoiceAction";
import { urlLinkPath } from "../../../services/urlPath";

const { Option } = Select;
class InvoicesComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: [],
      referrerList:[],
      viewUser: false,
      loading: true,
      selectedUser: {},
      level: "",
      invoiceType: "FTMember",
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }

  componentWillMount() {
    this.getInvoices();
    this.getTableColumn();
  }
  getTableColumn = (invoiceType = this.state.invoiceType) => {
    this.columns = [
      {
        title: "Name",
        width: 200,
        dataIndex: "name",
        key: "name",
        render: (text, record) =>
          invoiceType == "FTMember"
            ? `${record.userInfo.firstName} ${record.userInfo.lastName}`
            : `${record.firstName} ${record.lastName}`,
      },
      {
        title: "Phone",
        width: 100,
        dataIndex: "phone",
        key: "phone",
        render: (text, record) =>
          invoiceType == "FTMember"
            ? `${record.userInfo.phone}`
            : record.phone,
      },
      {
        title: "Plan Status",
        width: 100,
        dataIndex: "planStatus",
        key: "planStatus",
        render: (text) =>
          text ? (
            <Tag color={"#34b7e3"}>ACTIVE</Tag>
          ) : (
            <Tag color={"#ff443f"}>INACTIVE</Tag>
          ),
      },
      {
        title: "Plan Exoiry",
        width: 100,
        dataIndex: "planExpiry",
        key: "planExpiry",
      },
      {
        title: "Created on",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        render: (text) => moment(text).format("DD-MM-YYYY"),
      },
      {
        title: "Action",
        key: "operation",
        width: 250,
        render: (text, record) => (
          <Button
            onClick={() => {
              this.showUserDetails(record);
            }}
          >
            View
          </Button>
        ),
      },
    ];
    var startIndex = this.columns
      .map((column) => {
        return column.key;
      })
      .indexOf("planStatus");
    if (invoiceType != "FTMember") {
      this.columns.splice(
        startIndex,
        2,
        {
          title: "Referral Count",
          dataIndex: "referralCount",
          key: "referralCount",
          render: (text) => text,
        },
        {
          title: "Real Cash",
          dataIndex: "realCash",
          key: "realCash",
          render: (text) => text,
        }
      );
    }
  };
  showUserDetails = (user) => {
    this.setState({
      viewUser: true,
      selectedUser: user,
    });
  };

  closeViewMode = () => {
    this.setState({
      viewUser: false,
      selectedUser: {},
    });
  };

  getInvoices = () => {
    let { pagination, level } = this.state;
    ajax("get", urlLinkPath.userMembers, {
      page: pagination.current,
      level,
    }).then(
      ({ result }) => {
        pagination.total = result.count;
        this.setState({
          viewUser: false,
          pagination: pagination,
          list: result.list.filter((user) => user.userInfo),
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          viewUser: false,
          list: [],
          loading: false,
        });
      }
    );
  };
  getReferrersInvoices = () => {
    let { pagination, level } = this.state;
    ajax("get", urlLinkPath.referrers, {
      page: pagination.current,
      level,
    }).then(
      ({ result }) => {
        pagination.total = result.count;
        this.setState({
          viewUser: false,
          pagination: pagination,
          referrerList: result.list,
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          viewUser: false,
          list: [],
          loading: false,
        });
      }
    );
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
    this.onHandleOnChange(this.state.invoiceType)
    );
  };

  onHandleOnChange = (val) => {
    if (val == "FTMember") {
      this.getInvoices();
    } else {
      this.getReferrersInvoices();
    }
    this.setState(
      {
        invoiceType: val,
      },
      this.getTableColumn(val)
    );
  };

  render() {
    const { list, loading, pagination, viewUser, selectedUser, invoiceType ,referrerList} =
      this.state;
    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>Invoices</div>
        </div>
        <div>
          <Select 
          defaultValue="FTMember"
            style={{ width: 150 }}
            onChange={this.onHandleOnChange}
          >
            <Option value="FTMember">FT Member</Option>
            <Option value="Referrers">Referrers</Option>
          </Select>
        </div>
        <Table
          loading={loading}
          rowKey={"id"}
          columns={this.columns}
          pagination={pagination}
          dataSource={invoiceType=='FTMember'?list:referrerList}
        />

        {viewUser && (
          <Drawer width={520} visible={viewUser} onClose={this.closeViewMode}>
            <InvoiceAction
              user={selectedUser}
              invoiceType={invoiceType}
              updateUserList={this.getUsers}
            />
          </Drawer>
        )}
      </div>
    );
  }
}

export default InvoicesComponent;
