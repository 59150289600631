import React, { Component } from "react";
import { Form, Input, Button, notification,InputNumber } from "antd";
import { phoneRules, otpRules } from "../../../../services/validationRules";
import ajax from "../../../../services/ajax";
import { urlLinkPath } from "../../../../services/urlPath";

class CreditUserWallet extends Component {
  state = {
    loading: false,
    otp: false,
  };

  phone = "";

  verify = (ev) => {
    ev.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.setState({
        loading: true,
      });
      ajax("post", urlLinkPath.creditWalletAmount, values).then(
        ({ msg }) => {
          notification.success({
            message: "Success",
            description: msg,
          });
          this.setState({
            loading: false,
          });
          this.props.form.resetFields();
        },
        ({ msg }) => {
            
          notification.error({
            message: "Error",
            description: msg,
          });
          this.setState({
            loading: false,
          });
        }
      );
    });
  };

  render() {
    const {  loading } = this.state;
    const { getFieldDecorator } = this.props.form;
    return (
      <>
        <div className={"sub-title"}>Credit User Wallet</div>
        <Form onSubmit={this.verify}>
          <Form.Item label={"User Phone Number"} colon={false}>
            {getFieldDecorator("phone", {
              initialValue: this.props.user ? this.props.user.phone : null,
              rules: phoneRules,
            })(<Input size={"large"} placeholder={"897 XXX XXXX"} />)}
          </Form.Item>
          <Form.Item label={"Credit Amount"} colon={false}>
            {getFieldDecorator("amount", {
              rules: [{
                required: true,
                message: 'Please input amount to credit'
              }]
            })(<InputNumber size={"large"} placeholder={"0"} />)}
          </Form.Item>
          <div className={"btn-container"}>
            <Button
              loading={loading}
              type={"primary"}
              size={"large"}
              block
              htmlType={"submit"}
            >
              {"Credit User Wallet"}
            </Button>
          </div>
        </Form>
      </>
    );
  }
}

export default Form.create()(CreditUserWallet);
