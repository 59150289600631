import React, { Component } from "react";
import {
  Row,
  Col,
  Form,
  Input,
  InputNumber,
  Select,
  Button,
  notification,
  message,
  Modal,
  Popconfirm,
} from "antd";

import ajax from "../../../services/ajax";
import { urlLinkPath, urlPath } from "../../../services/urlPath";

const { confirm } = Modal;

class PlanAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      DELETE_KEY: "",
      loading: false,
      states: [],
    };
  }

  componentDidMount() {
    this.getStates();
  }

  handleSuccess = (msg) => {
    this.setState({
      loading: false,
    });
    notification.success(msg);
    this.props.updatePlanList();
  };

  handleError = (msg) => {
    this.setState({
      loading: false,
    });
    notification.error({
      message: "Error",
      description: msg,
    });
  };

  submitForm = (ev) => {
    ev.preventDefault();
    const action = {
      ADD: this.addPlan,
      EDIT: this.updatePlan,
    }[this.props.mode];
    action();
  };

  addPlan = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true,
      });
      ajax("post",urlPath.subscriptionPlan, values).then(
        (res) => {
          this.handleSuccess({
            message: "Success",
            description: "Plan added!",
          });
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );
    });
  };

  updatePlan = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;

      this.setState({
        loading: true,
      });
      values.id = this.props.plan.id;
      ajax("put", urlPath.subscriptionPlan, values).then(
        (res) => {
          this.handleSuccess({
            message: "Success",
            description: "Plan updated!",
          });
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );
    });
  };

  handleVisibility = () => {
    this.setState({
      loading: true,
    });

    const params = {
      id: this.props.plan.id,
      status: !this.props.plan.visible,
    };
    ajax("post",urlLinkPath.subscriptionPlanVisibility, params).then(
      (res) => {
        this.handleSuccess({
          message: "Success",
          description: "Plan status updated!",
        });
      },
      ({ msg }) => {
        this.handleError(msg);
      }
    );
  };

  confirmDeletePlan = () => {
    confirm({
      title: "Are you sure delete this SUBSCRIPTION PLAN?",
      content: (
        <div>
          <Form.Item label={"Verify Subscription Plan Name"}>
            <Input
              onChange={(ev) => {
                this.setState({ DELETE_KEY: ev.target.value });
              }}
            />
          </Form.Item>
        </div>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        if (this.state.DELETE_KEY === this.props.plan.name) {
          this.deletePlan();
        } else {
          message.error("Name doesnt match!");
        }
      },
    });
  };

  deletePlan = () => {
    this.setState({
      loading: true,
    });

    const params = {
      id: this.props.plan.id,
    };
    ajax("delete", urlPath.subscriptionPlan, params).then(
      (res) => {
        this.handleSuccess({
          message: "Success",
          description: "Subscription Plan deleted!",
        });
      },
      ({ msg }) => {
        this.handleError(msg);
      }
    );
  };

  getStates = (val = "") => {
    const params = {
      search: val,
    };
    ajax("get", urlLinkPath.subscriptionPlanList, params).then(
      (res) => {
        this.setState({
          states: res.list,
        });
      },
      ({ msg }) => {
        this.handleError(msg);
      }
    );
  };

  render() {
    const { loading, states } = this.state;
    const { plan, mode, masterCategories } = this.props;
    const { getFieldDecorator } = this.props.form;

    const masterCategoryOptions = masterCategories.map((role, index) => {
      return (
        <Select.Option key={index} value={role.id}>
          {role.name}
        </Select.Option>
      );
    });
    let stateOptions = [];
    stateOptions = states.map((state) => {
      return (
        <Select.Option key={state.id} value={state.id}>
          {state.name}
        </Select.Option>
      );
    });
    stateOptions.unshift(
      <Select.Option key={0} value={0}>
        ALL
      </Select.Option>
    );
    return (
      <Form onSubmit={this.submitForm} className='form'>
        <h2>Subscription Plan Details</h2>
        <Form.Item label={"Plan Name"} colon={false}>
          {getFieldDecorator("name", {
            initialValue: plan && plan.name,
            rules: [
              {
                required: true,
                message: "Please input name for plan!",
              },
            ],
          })(<Input size={"large"} />)}
        </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item label={"Price"} colon={false}>
              {getFieldDecorator("fee", {
                initialValue: plan && plan.fee,
                rules: [
                  {
                    required: true,
                    message: "Please input price for plan!",
                  },
                ],
              })(<InputNumber size={"large"} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={"Max Wallet Cashback"} colon={false}>
              {getFieldDecorator("maxWalletCashback", {
                initialValue: plan && plan.maxWalletCashback,
                rules: [
                  {
                    required: true,
                    message: "Please input Max Wallet Cashback for plan!",
                  },
                ],
              })(<InputNumber size={"large"} />)}
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={12}>
            <Form.Item label={"IOS Fee"} colon={false}>
              {getFieldDecorator("iosPrice", {
                initialValue: plan && plan.iosPrice,
                rules: [
                  {
                    required: true,
                    message: "Please input ios fee for plan!",
                  },
                ],
              })(<InputNumber size={"large"} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={'Store Id (Product Id)'} colon={false}>
              {
                getFieldDecorator('storeId', {
                  initialValue: plan && plan.storeId,
                  rules: [{
                    required: true,
                    message: 'Please input Store Id for plan!'
                  }]
                })(<Input size={'large'}/>)
              }
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={"State"} colon={false}>
              {getFieldDecorator("stateId", {
                initialValue: plan && plan.stateId,
                rules: [
                  {
                    required: true,
                    message: "State is required!",
                  },
                ],
              })(
                <Select
                  size={"large"}
                  onSearch={this.getStates}
                  showSearch
                  filterOption={false}
                >
                  {stateOptions}
                </Select>
              )}
            </Form.Item>
        <Row>
          <Col span={12}>
            <Form.Item label={"Period"} colon={false}>
              {getFieldDecorator("period", {
                initialValue: plan && plan.period,
                rules: [
                  {
                    required: true,
                    message: "Please input period for plan!",
                  },
                ],
              })(<InputNumber size={"large"} />)}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label={"Period Type"} colon={false}>
              {getFieldDecorator("periodType", {
                initialValue: plan && plan.periodType,
                rules: [
                  {
                    required: true,
                    message: "Please select Period Type!",
                  },
                ],
              })(
                <Select size={"large"}>
                  <Select.Option value={"DAY"}>DAY</Select.Option>
                  <Select.Option value={"MONTH"}>MONTH</Select.Option>
                  <Select.Option value={"YEAR"}>YEAR</Select.Option>
                </Select>
              )}
            </Form.Item>
          </Col>
        </Row>
        <Form.Item label={"Master Business Category"} colon={false}>
          {getFieldDecorator("masterBusinessCategoryId", {
            initialValue: plan && plan.masterBusinessCategoryId,
            rules: [
              {
                required: true,
                message: "Please select Master Business Category!",
              },
            ],
          })(<Select size={"large"}>{masterCategoryOptions}</Select>)}
        </Form.Item>
        <Row>
          <Col span={8}>
            <Form.Item label={"Max Text Post"} colon={false}>
              {getFieldDecorator("maxTextPost", {
                initialValue: plan && plan.maxTextPost,
                rules: [
                  {
                    required: true,
                    message: "Please input max Text posts for plan!",
                  },
                ],
              })(<InputNumber size={"large"} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={"Max Photo Post"} colon={false}>
              {getFieldDecorator("maxPhotoPost", {
                initialValue: plan && plan.maxPhotoPost,
                rules: [
                  {
                    required: true,
                    message: "Please input max Photo posts for plan!",
                  },
                ],
              })(<InputNumber size={"large"} />)}
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item label={"Max Video Post"} colon={false}>
              {getFieldDecorator("maxVideoPost", {
                initialValue: plan && plan.maxVideoPost,
                rules: [
                  {
                    required: true,
                    message: "Please input max Video posts for plan!",
                  },
                ],
              })(<InputNumber size={"large"} />)}
            </Form.Item>
          </Col>
        </Row>
        <div>
          <Button
            htmlType={"submit"}
            size={"large"}
            type={"primary"}
            hidden={mode !== "ADD"}
            loading={loading}
          >
            Add Plan
          </Button>
          <Button
            htmlType={"submit"}
            size={"large"}
            type={"primary"}
            hidden={mode !== "EDIT"}
          >
            Update Plan
          </Button>
        </div>

        <div
          className={"btn-grp opposite drawer-footer"}
          hidden={mode === "ADD"}
        >
          <Button
            size={"large"}
            onClick={this.handleVisibility}
            type={plan && plan.visible ? "danger" : "primary"}
            loading={loading}
          >
            {plan && plan.visible ? "Block Plan" : "Activate Plan"}
          </Button>
          <Popconfirm
            placement={"topRight"}
            title={"Are you sure you wish to delete this SUBSCRIPTION PLAN?"}
            onConfirm={this.confirmDeletePlan}
            okText={"YES! Delete Subscription Plan"}
            okType={"danger"}
            cancelText={"NO!"}
          >
            <Button size={"large"} type={"danger"} loading={loading}>
              Delete Plan
            </Button>
          </Popconfirm>
        </div>
      </Form>
    );
  }
}

export default Form.create()(PlanAction);
