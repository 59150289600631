import React, { Component } from "react";
import {
  Form,
  Input,
  message,
  Button,
  notification,
  Select,
  DatePicker,
  InputNumber,
  Switch,
} from "antd";
import ImageUpload from "../../_common/ImageUpload/ImageUpload";
import ajax from "../../../services/ajax";
import { urlLinkPath, urlPath } from "../../../services/urlPath";
import moment from "moment";

class AdAction extends Component {
  adPicture = null;

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      states: [],
      cities: [],
      businesses: [],
      disableDateList: [],
      fixedAd: false,
    };
  }

  handleSuccess = (msg) => {
    this.setState({
      loading: false,
    });
    notification.success(msg);
    this.props.updateAdList();
  };

  handleError = (msg) => {
    this.setState({
      loading: false,
    });
    notification.error({
      message: "Error",
      description: msg,
    });
  };

  submitForm = (ev) => {
    ev.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) return;
      if (!this.adPicture) {
        message.error("Upload Ad Picture!");
        return;
      }
      this.setState({
        loading: true,
      });

      this.adPicture.set("title", values.title);
      this.adPicture.set("subtitle", values.subtitle);
      this.adPicture.set("info", values.info);
      this.adPicture.set("link", values.link);
      this.adPicture.set("business", values.business.key);
      this.adPicture.set("startDate", values.startDate);
      this.adPicture.set("period", values.period);
      this.adPicture.set("periodType", values.periodType);
      this.adPicture.set("fixed", values.fixed);
      const states = values.states;
      let stateData = [];
      if (states && states.length > 0) {
        for (let i = 0; i < states.length; i++) {
          stateData.push(states[i].key);
        }
      }

      const cities = values.states;
      let cityData = [];
      if (cities && cities.length > 0) {
        for (let i = 0; i < cities.length; i++) {
          cityData.push(cities[i].key);
        }
      }

      this.adPicture.set("states", JSON.stringify(stateData));
      this.adPicture.set("cities", JSON.stringify(cityData));

      ajax("post", urlPath.ad, this.adPicture, {}).then(
        (res) => {
          this.handleSuccess({
            message: "Success",
            description: "Ad added!",
          });
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );
    });
  };

  updateAd = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;

      this.setState({
        loading: true,
      });
      values.id = this.props.ad.id;
      ajax("put", urlPath.ad, values).then(
        (res) => {
          this.handleSuccess({
            message: "Success",
            description: "Ad updated!",
          });
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );
    });
  };

  getBusinesses = (search) => {
    ajax("get", urlLinkPath.adBusinesses, { search: search }).then(
      ({ businesses }) => {
        this.setState({
          businesses: businesses,
        });
      },
      ({ msg }) => {
        this.setState({
          businesses: [],
        });
      }
    );
  };

  getStates = (search) => {
    ajax("get", urlLinkPath.adState, { search: search }).then(
      ({ result }) => {
        this.setState({
          states: result,
        });
      },
      ({ msg }) => {
        this.setState({
          states: [],
        });
      }
    );
  };

  getCities = (search) => {
    ajax("get", urlLinkPath.adCity, { search: search }).then(
      ({ result }) => {
        this.setState({
          cities: result,
        });
      },
      ({ msg }) => {
        this.setState({
          cities: [],
        });
      }
    );
  };
  getAllAds = () => {
    ajax("get", urlPath.ad).then(
      ({ result }) => {
        let disabledDates = [];
        for (let i = 0; i < result.length; i++) {
          if (result[i].fixed) {
            disabledDates.push({
              startDate: result[i].startDate,
              endDate: result[i].endDate,
            });
            this.setState({
              disableDateList: disabledDates,
            });
          }
          this.setState({
            loading: false,
          });
        }
      },
      ({}) => {
        notification.error({
          message: "Error",
          description: "msg",
        });
        this.setState({
          loading: false,
        });
      }
    );
  };

  getAdPicture = (formData) => {
    this.adPicture = formData;
  };

  handleGetBusiness = (v) => {
    this.getBusinesses(v);
  };

  handleGetStates = (v) => {
    this.getStates(v);
  };

  handleGetCities = (v) => {
    this.getCities(v);
  };

  componentWillMount() {
    this.getBusinesses("");
    this.getAllAds();
  }
  disabledDate = (current) => {
    const format = "YYYY-MM-DD";
    const { disableDateList, fixedAd } = this.state;
    return disableDateList.some(
      (date) =>
        (fixedAd
          ? current.isBetween(
              moment(date["startDate"], format),
              moment(date["endDate"], format).endOf("day")
            )
          : fixedAd) ||
        (current <= moment().startOf("day") && current < moment().endOf("day"))
    );
  };
  handleSwitch = () => {
    this.setState({
      fixedAd: true,
    });
  };
  render() {
    const { loading, businesses, states, cities, fixedAd } = this.state;
    const { ad } = this.props;
    const { getFieldDecorator } = this.props.form;

    let optionsBusiness = [];
    if (businesses.length > 0) {
      optionsBusiness = businesses.map((business) => {
        return <Select.Option key={business.id}>{business.name}</Select.Option>;
      });
    }

    let optionsStates = [];
    if (states.length > 0) {
      optionsStates = states.map((state) => {
        return <Select.Option key={state.id}>{state.name}</Select.Option>;
      });
    }

    let optionsCities = [];
    if (cities.length > 0) {
      optionsCities = cities.map((city) => {
        return <Select.Option key={city.id}>{city.name}</Select.Option>;
      });
    }

    return (
      <Form onSubmit={this.submitForm}>
        <h2>Ad Details</h2>
        <Form.Item label={"Title"} colon={false}>
          {getFieldDecorator("title", {
            initialValue: ad && ad.title,
            rules: [
              {
                required: true,
                message: "Please input title for ad!",
              },
            ],
          })(<Input size={"large"} />)}
        </Form.Item>
        <Form.Item label={"Subtitle"} colon={false}>
          {getFieldDecorator("subtitle", {
            initialValue: ad && ad.subtitle,
            rules: [
              {
                required: true,
                message: "Please input subtitle for ad!",
              },
            ],
          })(<Input size={"large"} />)}
        </Form.Item>
        <Form.Item>
          <ImageUpload shareFile={this.getAdPicture} uploadType="BANNER" />
        </Form.Item>
        <Form.Item label={"Fixed"} colon={false}>
          {getFieldDecorator("fixed", {
            initialValue: ad && ad.fixed,
          })(<Switch size={"large"} onClick={this.handleSwitch} />)}
        </Form.Item>
        <Form.Item label={"Start Date & Time"} colon={false}>
          {getFieldDecorator("startDate", {
            initialValue: ad && ad.startDate,
            rules: [
              {
                required: true,
                message: "Please input start date for ad!",
              },
            ],
          })(
            <DatePicker
              size={"large"}
              disabledDate={this.disabledDate}
              showTime
            />
          )}
        </Form.Item>

        <Form.Item label={"Period"} colon={false}>
          {getFieldDecorator("period", {
            initialValue: ad && ad.period,
            rules: [
              {
                required: true,
                message: "Please input period for plan!",
              },
            ],
          })(<InputNumber size={"large"} />)}
        </Form.Item>
        <Form.Item label={"Period Type"} colon={false}>
          {getFieldDecorator("periodType", {
            initialValue: ad && ad.periodType,
            rules: [
              {
                required: true,
                message: "Please select Period Type!",
              },
            ],
          })(
            <Select size={"large"}>
              <Select.Option value={"HOUR"}>HOUR</Select.Option>
              <Select.Option value={"DAY"}>DAY</Select.Option>
              <Select.Option value={"MONTH"}>MONTH</Select.Option>
            </Select>
          )}
        </Form.Item>

        <Form.Item label={"Info"} colon={false}>
          {getFieldDecorator("info", {
            initialValue: ad && ad.info,
          })(<Input.TextArea size={"large"} />)}
        </Form.Item>

        <Form.Item label={"Link"} colon={false}>
          {getFieldDecorator("link", {
            initialValue: ad && ad.link,
          })(<Input size={"large"} />)}
        </Form.Item>

        <Form.Item label={"Business"} colon={false}>
          {getFieldDecorator("business", {
            initialValue: ad && ad.business ? ad.business : "",
            rules: [
              {
                required: true,
                message: "Please select Business!",
              },
            ],
          })(
            <Select
              showSearch
              labelInValue
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              size={"large"}
              placeholder={"Select Business"}
              allowClear
              onSearch={this.handleGetBusiness}
            >
              {optionsBusiness}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={"States"} colon={false}>
          {getFieldDecorator("states", {
            rules: [
              {
                required: true,
                message: "Please select State!",
              },
            ],
          })(
            <Select
              showSearch
              labelInValue
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              size={"large"}
              mode={"multiple"}
              placeholder={"Select States"}
              allowClear
              onSearch={this.handleGetStates}
            >
              {optionsStates}
            </Select>
          )}
        </Form.Item>

        <Form.Item label={"Cities"} colon={false}>
          {getFieldDecorator("cities", {
            // initialValue: (ad && ad.business) ? ad.business : false,
          })(
            <Select
              showSearch
              labelInValue
              defaultActiveFirstOption={false}
              showArrow={false}
              filterOption={false}
              size={"large"}
              mode={"multiple"}
              placeholder={"Select Cities"}
              allowClear
              onSearch={this.handleGetCities}
            >
              {optionsCities}
            </Select>
          )}
        </Form.Item>
        <div>
          <Button
            htmlType={"submit"}
            size={"large"}
            type={"default"}
            loading={loading}
          >
            Add Ad
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create()(AdAction);
