import React, {Component} from 'react';
import { Row, Col, Divider } from 'antd';
import DescriptionItem from '../../_common/DescriptionItem';
import moment from 'moment';

class TransactionAction extends Component{

  constructor(props) {
    super(props);
  }

  render() {

    const { txn } = this.props;

    return (
      <div>
        <h2>Transaction Details</h2>
        <Row>
          <Col span={12}>
            <DescriptionItem
              title={'Txn ID'}
              content={txn.txnid}
            />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'Time'} content={moment(txn.updatedAt).format('DD MMM YYYY HH:SS')} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'Plan'} content={txn.subscriptionPlan.name} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'Status'} content={txn.status} />
          </Col>
          <Col span={24}>
            <DescriptionItem title={'Business'} content={txn.business && txn.business.name} />
          </Col>
          <Col span={24}>
            <DescriptionItem title={'User'} content={txn.user && (txn.user.firstName + ' ' + txn.user.lastName)} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title={'Amount'} content={txn.amount} />
          </Col>
          <Col span={24}>
            <DescriptionItem title={`IGST (${txn.igst})`} content={txn.taxAmount} />
          </Col>
          <Col span={24}>
            <DescriptionItem title={`Total`} content={txn.totalAmount} />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={12}>
            <DescriptionItem title={'Error Code'} content={txn.errorCode} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={'Mode'} content={txn.mode} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={`PayU Money ID`} content={txn.payuMoneyId} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={`mihpayid`} content={txn.mihpayid} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={`Bank Code`} content={txn.bankCode} />
          </Col>
          <Col span={12}>
            <DescriptionItem title={`Bank Ref`} content={txn.bankRefNum} />
          </Col>
        </Row>
        <Divider />

      </div>
    )
  }
}

export default TransactionAction;
