import React, { useState } from "react";
import { Collapse, Row, Col } from "antd";
import FtTreeNodeDescendant from "./FtTreeNodeDescendant";
import FtTreeHOC from "./FtTreeHOC";
import { MailOutline } from "@ant-design/icons";

const { Panel } = Collapse;
const FtTreeNode = (props) => {
  const genExtra = () => (
    <Row gutter={16}>
      <Col span={8}>
        <MailOutline
          onClick={(event) => {
            event.stopPropagation();
          }}
        />
      </Col>
    </Row>
  );
  const {
    parentID,
    getDescendants,
    onExpand,
    descendants,
    info,
    showNoData,
    reachedMaxLevel,
  } = props;
  return (
    <Collapse
      className="site-collapse-custom-collapse"
      onChange={onExpand}
      bordered={false}
    >
      <Panel
        header={`${info.firstName} ${info.lastName}`}
        extra={info.childrenCount == 0 ? genExtra() : null}
        key={parentID}
      >
        {descendants.map((descendant, index) => (
          <FtTreeNodeDescendant
            info={descendant.userInfo}
            getDescendants={getDescendants}
            key={`ftchild_${index}`}
            parentID={descendant.id}
          />
        ))}
        {reachedMaxLevel ? (
          <div>User reached max Level</div>
        ) : (
          <>{showNoData && <div>Has no children</div>}</>
        )}
      </Panel>
    </Collapse>
  );
};

export default FtTreeHOC(FtTreeNode);
