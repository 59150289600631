import React, {Component} from 'react';
import {Card, Form, Input, Button, notification } from 'antd';
import {Link} from 'react-router-dom';
import {path} from '../../../config';
import VerifyPhone from './VerifyPhone';
import { urlLinkPath } from '../../../services/urlPath';
import ajax from '../../../services/ajax';
class SetCredentials extends Component {

  componentWillMount() {
    localStorage.clear();
  }

  handlePasswordReset = (ev) => {
    ev.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.setState({
          loading: true
        });
        ajax('post',urlLinkPath.resetPassword, values).then(({ msg }) => {
          notification.success({
            message: 'Success',
            description: msg
          });
          this.props.goToLogin();
        }, ({ msg }) => {
          notification.error({
            message: 'Error',
            description: msg
          });
          this.setState({
            loading: false
          });
        });
      }
    });
  };

  render() {

    const { getFieldDecorator } = this.props.form;

    return (
      <>
        <div className={'sub-title'}>Reset your Password</div>
        <Form onSubmit={this.handlePasswordReset}>
          <Form.Item label={'Mobile'} colon={false}>
            {
              getFieldDecorator('phone', {
                initialValue: this.props.phoneNumber
              })(
                <Input size={'large'} placeholder={'897 546 3767'} readOnly={true}/>
              )
            }
          </Form.Item>
          <Form.Item label={'Password'} colon={false}>
            {
              getFieldDecorator('password',{
                rules:[{
                  require: true,
                  message: 'Please input Password!'
                }]
              })(<Input.Password size={'large'} placeholder={'******'}/>)
            }
          </Form.Item>
          <div className={'btn-container'}>
            <Button type={'primary'} block size={'large'} htmlType={'submit'}>Submit</Button>
          </div>
        </Form>
      </>
    )
  }
}

const SetCredentialsForm = Form.create()(SetCredentials);

class ResetPassword extends Component {
  state = {
    step : 1,
    phoneNumber: null
  };

  nextStep = (phoneNumber) => {
    this.setState({
      phoneNumber: phoneNumber,
      step: 2
    })
  };

  prevStep = () => {
    this.setState({
      phoneNumber: null,
      step: 1
    })
  };

  goToLogin = () => {
    this.props.history.push(path.login);
  }

  render() {

    const { step, phoneNumber } = this.state;

    return (

      <div className={'reset'}>
        <Card>
          <div className={'title'}>Password Recovery</div>
          {
            step === 1
              ? <VerifyPhone nextStep={this.nextStep}/>
              : <SetCredentialsForm phoneNumber={phoneNumber} prevStep={this.prevStep} goToLogin={this.goToLogin}/>
          }

          <div className={'link'}>Remember Password? <Link to={path.login}>Login</Link></div>
        </Card>
      </div>
    )
  }
}

export default ResetPassword;