import React, {Component} from 'react';
import {notification, Button, Table, Tag, Drawer} from 'antd';
import moment from 'moment';
import ajax from '../../../services/ajax';
import {color} from '../../../config';
import BusinessAction from "./BusinessAction";
import { urlPath } from '../../../services/urlPath';
import { USER_TYPE } from "../../../services/constants";
import SearchBox from "../../_common/SearchBox";

class BusinessComponent extends Component{
  constructor(props){
    super(props);

    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Reg. No',
        dataIndex: 'regNo',
        key: 'regNo',
      },
      {
        title: 'City',
        dataIndex: 'city',
        key: 'city',
        render: (text, record) => record.city && `${record.city.name}`
      },
      {
        title: 'Category',
        dataIndex: 'businessCategory',
        key: 'businessCategory',
        render: (text, record) => record.businessCategory && `${record.businessCategory.name}`
      },
      {
        title: 'Status',
        dataIndex: 'visible',
        key: 'visible',
        render: (text) => text ? <Tag color={'#34b7e3'}>ACTIVE</Tag>: <Tag color={'#ff443f'}>BLOCKED</Tag>
      },
      {
        title: 'Subscription',
        dataIndex: 'planStatus',
        key: 'planStatus',
        render: (text) => ({
          NONE: <Tag color={color.disabled}>NONE</Tag>,
          ACTIVE: <Tag color={color.success}>ACTIVE</Tag>,
          EXPIRED: <Tag color={color.danger}>EXPIRED</Tag>,
        }[text])
      },
      {
        title: 'Created on',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) =>  moment(text).format('DD-MM-YYYY')
      },
      {
        title: 'Action',
        key: 'operation',
        render: (text, record) => <Button onClick={() => { this.showBusinessDetails(record)}} >View</Button>,
      },
    ];

    this.state = {
      list: [],
      loading: true,
      viewBusiness: false,
      selectedBusiness: {},
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    };
  }

  componentWillMount() {
    this.getBusinesses();
  }

  showBusinessDetails = (business) => {
    this.setState({
      viewBusiness: true,
      selectedBusiness: business
    })
  };

  closeViewMode = () => {
    this.setState({
      viewBusiness: false,
      selectedBusiness: {}
    })
  }

  getBusinesses = () => {
    let { pagination } = this.state;
    ajax('get', urlPath.business,{ page: pagination.current}).then(({result}) => {
      pagination.total = result.count;
      this.setState({
        viewBusiness: false,
        pagination: pagination,
        list: result.list,
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        viewBusiness: false,
        list: [],
        loading: false
      })
    })
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getBusinesses)
  };
  handleCallback = (searchList) => {
    this.setState({ list: searchList });
  };
  render(){

    const {list, loading, pagination, viewBusiness, selectedBusiness} = this.state;

    return(
      <div>
        <div className={'header'}>
          <div className={'page-title'}>Business</div>
        </div>
        <SearchBox
          pagination={pagination}
          userType={USER_TYPE.BUSINESS}
          searchBoxCallback={this.handleCallback}
        />
        <Table
          rowKey={'id'}
          loading={loading}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />

        {
          viewBusiness && (
            <Drawer
              width={520}
              visible={viewBusiness}
              onClose={this.closeViewMode}
            >
              <BusinessAction business={selectedBusiness} updateBusinessList={this.getBusinesses} />
            </Drawer>
          )
        }

      </div>
    )
  }
}

export default BusinessComponent;
