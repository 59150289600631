import React, {Component} from 'react';
import {Form, Input, Select, Button, notification} from "antd";
import ajax from '../../../../services/ajax';
import { urlLinkPath } from '../../../../services/urlPath';

class BusinessDetails extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      businessCategory: []
    }
  }

  fetchBusinessCategory = (search) => {
    ajax('get', urlLinkPath.businessCategorySearch, {search: search}).then(({list}) => {
      this.setState({
        businessCategory: list
      });
    }, ({msg}) => { })
  }

  registerBusiness = (ev) => {
    ev.preventDefault();

    this.props.form.validateFields((err, values) => {
      if (err) {
        return;
      }
      this.setState({
        loading: true
      });
      values.businessCategoryId = values.businessCategory.key;
      values.userId = this.props.user ? this.props.user.id : null;

      ajax('post', urlLinkPath.registerBusiness, values).then(({business, businessCategory, msg}) => {
        notification.success({
          message: 'Success',
          description: msg
        });
        this.setState({
          loading: false
        });
        this.props.nextStep(business, businessCategory);
      }, ({msg}) => {
        notification.error({
          message: 'Error',
          description: msg
        });
        this.setState({
          loading: false
        });
      })
    });
  };

  componentWillMount() {
    this.fetchBusinessCategory();
  }

  render() {

    const { getFieldDecorator } = this.props.form;
    const { businessCategory, loading } = this.state;

    const businessCategoryOptions = businessCategory.map((businessCategory, index) => {
      return (
        <Select.Option key={businessCategory.id} >{businessCategory.name}</Select.Option>
      )
    });
    return (
      <>
        <div className={'sub-title'}>Add Business Details</div>
        <Form onSubmit={this.registerBusiness}>
          <Form.Item label={'Name of Business'} colon={false}>
            {
              getFieldDecorator('name', {
                rules: [{
                  required: true,
                  message: 'Please input Name of Business!'
                }]
              })(<Input size={'large'}/>)
            }
          </Form.Item>
          <Form.Item label={'Est Reg Number'} colon={false}>
            {
              getFieldDecorator('regNo', {
                rules: [{
                  required: true,
                  message: 'Please input Registration Number!'
                }]
              })(<Input size={'large'}/>)
            }
          </Form.Item>
          <Form.Item label={'Business Category'} colon={false}>
            {
              getFieldDecorator('businessCategory', {
                rules: [{
                  required: true,
                  message: 'Please select Business Category!'
                }]
              })(
                <Select
                  placeholder={'Search Business Category...'}
                  size={'large'}
                  showSearch
                  labelInValue
                  filterOption={false}
                  onSearch={this.fetchBusinessCategory}
                >
                  {businessCategoryOptions}
                </Select>
              )
            }
          </Form.Item>
          <Form.Item label={'Office Address'}>
            {
              getFieldDecorator('address', {
                rules: [{
                  required: true,
                  message: 'Please input Office Address!'
                }]
              })(<Input.TextArea size={'large'}/>)
            }
          </Form.Item>
          <Form.Item label={'Website'} colon={false}>
            {
              getFieldDecorator('website')(<Input size={'large'}/>)
            }
          </Form.Item>
          <Form.Item label={'Office Contact'} colon={false}>
            {
              getFieldDecorator('phone',{
                initialValue: this.props.user ? this.props.user.phone : ''
              })(<Input size={'large'}/>)
            }
          </Form.Item>
          <Form.Item label={'Office Email ID'} colon={false}>
            {
              getFieldDecorator('emailId', {
                initialValue: this.props.user ? this.props.user.emailId : ''
              })(<Input size={'large'}/>)
            }
          </Form.Item>

          <div className={'btn-grp opposite'}>
            <Button htmlType={'button'} type={'default'} size={'large'} disabled={loading} onClick={this.props.goBack}>Change Customer Details</Button>
            <Button htmlType={'submit'} type={'primary'} size={'large'} loading={loading}>Next</Button>
          </div>
        </Form>
      </>
    )
  }
}

export default Form.create()(BusinessDetails);