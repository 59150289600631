import React, {Component} from 'react';
import {notification, Button, Table, Drawer} from 'antd';
import ajax from '../../../../services/ajax';

import ConfigurationAction from './ConfigurationAction';
import { urlPath } from '../../../../services/urlPath';

class ConfigurationComponent extends Component{

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Key',
        dataIndex: 'key',
        key: 'key',
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
      },
      {
        title: 'Action',
        key: 'operation',
        render: (text, record) => <Button onClick={() => { this.updateConfigurationDetails(record)}} type={'default'}>Edit Configuration</Button>,
      },
    ];

    this.state = {
      list: [],
      loading: true,
      configurationAction: false,
      mode: 'ADD',
      configurationSelected: {}
    }
  }

  componentWillMount() {
    this.getConfigurationList();
  }

  getConfigurationList = () => {
    ajax('get', urlPath.configuration,{}).then(({list}) => {
      this.setState({
        list: list,
        configurationAction: false,
        configurationSelected: {},
        loading: false,
        mode: 'ADD'
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        list: [],
        configurationAction: false,
        configurationSelected: {},
        loading: false,
        mode: 'ADD'
      })
    })
  }

  addConfiguration = () => {
    this.setState({
      configurationAction: true,
      configurationSelected: {},
      mode: 'ADD'
    })
  };

  updateConfigurationDetails = (record) => {
    this.setState({
      configurationAction: true,
      configurationSelected: record,
      mode: 'EDIT'
    });
  };

  closeViewMode = () => {
    this.setState({
      configurationAction: false,
      configurationSelected: {}
    })
  }

  render(){

    const {list, loading, mode, configurationAction, configurationSelected} = this.state;

    return(
      <div className={'admin-module'}>
        <div className={'header'}>
          <div className={'page-title'}>Configurations</div>
        </div>

        <div className={'admin-module-body'}>
          <Button type={'primary'} size={'large'} onClick={this.addConfiguration}>Add Configuration</Button>
          <Table
            rowKey={'id'}
            loading={loading}
            columns={this.columns}
            pagination={false}
            dataSource={list}/>
        </div>

        {
          configurationAction && (
            <Drawer
              width={800}
              visible={configurationAction}
              onClose={this.closeViewMode}
            >
              <ConfigurationAction configuration={configurationSelected} mode={mode} updateConfigurationList={this.getConfigurationList}/>
            </Drawer>
          )
        }

      </div>
    )
  }
}

export default ConfigurationComponent;
