import React, {Component} from 'react';
import {Form, Input, Button, notification} from 'antd';
import { urlLinkPath } from '../../../services/urlPath';
import { phoneRules, otpRules } from '../../../services/validationRules'
import ajax from "../../../services/ajax";

class VerifyPhone extends Component {

  state = {
    otp: false,
    loading: false
  };

  phone = '';

  componentWillMount() {
    localStorage.clear();
  }

  verify = (ev) => {
    ev.preventDefault();
    const { preRegister } = this.props;

    this.props.form.validateFields((err, values) => {
      if(err){
        return;
      }

      this.setState({
        loading: true
      });

      if(!this.state.otp){ // step 1
        this.phone = values.phone;
        this.requestOTP(preRegister);
      }else{ // step 2
        ajax('post',urlLinkPath.verifyOtp, values).then(({ token, msg }) => {
          notification.success({
            message: 'Success',
            description: msg
          });
          localStorage.setItem('token', token);
          this.setState({
            loading: false
          });
          this.props.nextStep(values.phone);
        }, ({ msg }) => {
          notification.error({
            message: 'Error',
            description: msg
          });
          this.setState({
            loading: false
          });
        });
      }
    });
  };

  requestOTP = (preRegister) => {
    ajax('post',`/auth/${preRegister ? 'pre-register': 'request-otp'}`, {phone: this.phone}).then(({ msg }) => {
      notification.success({
        message: 'Success',
        description: msg
      });
      this.setState({
        otp: true,
        loading: false
      });
    }, ({ msg }) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        loading: false
      });
    });
  };

  render() {

    const { getFieldDecorator } = this.props.form;
    const { otp, loading } = this.state;

    return (
      <>
        <div className={'sub-title'}>Verify your Phone Number</div>
        <Form onSubmit={this.verify}>
          <Form.Item label={'Phone Number'} colon={false}>
            {
              getFieldDecorator('phone', {
                rules: phoneRules
              })(<Input size={'large'} placeholder={'897 XXX XXXX'}/>)
            }
          </Form.Item>
          {
            otp ? (
              <>
                <Form.Item label={'OTP Code'} colon={false}>
                  {
                    getFieldDecorator('otp', {
                      rules: otpRules
                    })(<Input.Password size={'large'} placeholder={'*** ***'}/>)
                  }
                </Form.Item>
                <div className={'link'}>Not received OTP yet? <a onClick={ () => this.requestOTP()}>Resend OTP!</a></div>
              </>
            ) : null
          }
          <div className={'btn-container'}>
            <Button loading={loading} type={'primary'} size={'large'} block htmlType={'submit'}>
              { !otp ? 'Verify Phone Number' : 'Confirm Phone Number' }
            </Button>
          </div>
        </Form>
      </>
    )
  }
}

export default Form.create()(VerifyPhone);