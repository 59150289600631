import React, { Component } from "react";
import {
  Form,
  Input,
  Modal,
  Button,
  notification,
  message,
  Popconfirm,
} from "antd";

import ajax from "../../../../services/ajax";
import Wysiwyg from "../../../_common/wysiwyg";
import JoEditor from "../../../_common/JoEditor";
import { urlPath } from "../../../../services/urlPath";

const { confirm } = Modal;
class ConfigurationAction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      DELETE_KEY: "",
    };
  }
  submitButton = React.createRef();

  handleSuccess = (msg) => {
    this.setState({
      loading: false,
    });
    notification.success(msg);
    this.props.updateConfigurationList();
  };

  handleError = (msg) => {
    this.setState({
      loading: false,
    });
    notification.error({
      message: "Error",
      description: msg,
    });
  };

  submitForm = (ev) => {
    ev && ev.preventDefault();
    const action = {
      ADD: this.addConfiguration,
      EDIT: this.updateConfiguration,
    }[this.props.mode];
    action();
  };

  addConfiguration = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true,
      });
      ajax("post", urlPath.configuration, values).then(
        (res) => {
          this.handleSuccess({
            message: "Success",
            description: "Configuration added!",
          });
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );
    });
  };

  updateConfiguration = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      console.log(values);
      if (
        values.key === "FTMEMBER_CONTRACT" ||
        values.key === "ENLIST_CONTRACT" ||
        values.key === "SERVICE_ORDER_EMAIL"
      ) {
        values.value.replaceAll(/\\n/g, "").trim();
      }
      this.setState({
        loading: true,
      });
      values.id = this.props.configuration.id;
      ajax("put", urlPath.configuration, values).then(
        (res) => {
          this.handleSuccess({
            message: "Success",
            description: "Configuration updated!",
          });
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );
    });
  };

  confirmDeleteConfiguration = () => {
    confirm({
      title: "Are you sure delete this CONFIGURATION?",
      content: (
        <div>
          <Form.Item label={"Verify Configuration KEY"}>
            <Input
              onChange={(ev) => {
                this.setState({ DELETE_KEY: ev.target.value });
              }}
            />
          </Form.Item>
        </div>
      ),
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk: () => {
        if (this.state.DELETE_KEY === this.props.configuration.key) {
          this.deleteConfiguration();
        } else {
          message.error("Key doesnt match!");
        }
      },
    });
  };

  deleteConfiguration = () => {
    this.setState({
      loading: true,
    });

    const params = {
      id: this.props.configuration.id,
    };
    ajax("delete", urlPath.configuration, params).then(
      (res) => {
        this.handleSuccess({
          message: "Success",
          description: "Configuration deleted!",
        });
      },
      ({ msg }) => {
        this.handleError(msg);
      }
    );
  };

  setHtml = (html) => {
    const { setFieldsValue } = this.props.form;
    setFieldsValue({
      value: html,
    });
  };

  render() {
    const { loading } = this.state;
    const { configuration, mode } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.submitForm}>
        <h2>Configuration Details</h2>
        <Form.Item label={"Key"} colon={false}>
          {getFieldDecorator("key", {
            initialValue: configuration && configuration.key,
            rules: [
              {
                required: true,
                message: "Please input Key for Configuration!",
              },
            ],
          })(<Input readOnly={mode === "EDIT"} size={"large"} />)}
        </Form.Item>
        {configuration &&
        (configuration.key == "FTMEMBER_CONTRACT" ||
          configuration.key == "ENLIST_CONTRACT" ||
          configuration.key === "SERVICE_ORDER_EMAIL" ||
          configuration.key == "INVOICE_CONTENT" ||
          configuration.key === "REDEEM_REWARD_EMAIL" ||
          configuration.key === "ENQUIRY_RESPONSE_EMAIL" ||
          configuration.key === "TERMINATION_NOTICE" ||
          configuration.key === "SUCCESSFUL_REGISTRATION_EMAIL") ? (
          <Form.Item label={"Value"} colon={false}>
            {getFieldDecorator("value", {
              initialValue: configuration && configuration.value,
              rules: [
                {
                  required: true,
                  message: "Please input Value for Configuration!",
                },
              ],
            })(
              <JoEditor setHtml={this.setHtml} submitForm={this.submitForm} />
            )}
          </Form.Item>
        ) : (
          <Form.Item label={"Value"} colon={false}>
            {getFieldDecorator("value", {
              initialValue: configuration && configuration.value,
              rules: [
                {
                  required: true,
                  message: "Please input Value for Configuration!",
                },
              ],
            })(<Input size={"large"} />)}
          </Form.Item>
        )}

        <div className={"btn-grp opposite drawer-footer"}>
          <div>
            <Button
              htmlType={"submit"}
              size={"large"}
              type={"default"}
              hidden={mode !== "ADD"}
              loading={loading}
            >
              Add Configuration
            </Button>
            <Button
              htmlType={"submit"}
              size={"large"}
              type={"default"}
              hidden={mode !== "EDIT"}
              ref={this.submitButton}
            >
              Update Configuration
            </Button>
          </div>
          <Popconfirm
            placement={"topRight"}
            title={"Are you sure you wish to delete this CONFIGURATION?"}
            onConfirm={this.confirmDeleteConfiguration}
            okText={"YES! Delete Configuration"}
            okType={"danger"}
            cancelText={"NO!"}
            hidden={mode === "ADD"}
          >
            <Button size={"large"} type={"danger"} loading={loading}>
              Delete Configuration
            </Button>
          </Popconfirm>
        </div>
      </Form>
    );
  }
}

export default Form.create()(ConfigurationAction);
