import React, {Component} from 'react';
import {Form, Input, InputNumber, Modal, Button, notification, message, Popconfirm} from 'antd';

import ajax from '../../../../services/ajax';
import { urlPath } from '../../../../services/urlPath';

const { confirm } = Modal;
class ModuleAction extends Component{

  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      DELETE_KEY: ''
    }
  }

  handleSuccess = (msg) => {
    this.setState({
      loading: false
    });
    notification.success(msg);
    this.props.updateModuleList();
  }

  handleError = (msg) => {
    this.setState({
      loading: false
    });
    notification.error({
      message: 'Error',
      description: msg
    });
  }

  submitForm = (ev) => {
    ev.preventDefault();
    const action = {
      ADD: this.addModule,
      EDIT: this.updateModule
    }[this.props.mode];
    action();
  };

  addModule = () => {
    this.props.form.validateFields((err, values) => {
      if(err) return;
      this.setState({
        loading: true
      });
      ajax('post', urlPath.module,values).then((res) => {
        this.handleSuccess({
          message: 'Success',
          description: 'Module added!'
        })
      }, ({msg}) => {
        this.handleError(msg);
      })
    })
  }

  updateModule = () => {
    this.props.form.validateFields((err, values) => {
      if (err) return;

      this.setState({
        loading: true
      });
      values.id = this.props.module.id;
      ajax('put', urlPath.module, values).then((res) => {
        this.handleSuccess({
          message: 'Success',
          description: 'Module updated!'
        });
      }, ({msg}) => {
        this.handleError(msg);
      })
    });
  };

  confirmDeleteModule = () => {
    confirm({
      title: 'Are you sure delete this MODULE?',
      content: (
        <div>
          <Form.Item label={'Verify Module KEY'}>
            <Input onChange={(ev)=> {this.setState({DELETE_KEY: ev.target.value})}}/>
          </Form.Item>
        </div>
      ),
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk: () => {
        if(this.state.DELETE_KEY === this.props.module.key){
          this.deleteModule()
        }else{
          message.error('Key doesnt match!')
        }
      }
    });
  }

  deleteModule = () => {

    this.setState({
      loading: true
    });

    const params = {
      id: this.props.module.id
    };
    ajax('delete',urlPath.module,params).then((res) => {
      this.handleSuccess({
        message: 'Success',
        description: 'Module deleted!'
      });
    }, ({msg}) => {
      this.handleError(msg);
    })
  }

  render() {

    const { loading } = this.state;
    const { module, mode } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.submitForm}>
        <h2>Module Details</h2>
        <Form.Item label={'Name'} colon={false}>
          {
            getFieldDecorator('title', {
              initialValue: module && module.title,
              rules: [{
                required: true,
                message: 'Please input name for Module!'
              }]
            })(<Input size={'large'}/>)
          }
        </Form.Item>
        <Form.Item label={'Key'} colon={false}>
          {
            getFieldDecorator('key', {
              initialValue: module && module.key,
              rules: [{
                required: true,
                message: 'Please input Key for Module!'
              }]
            })(<Input readOnly={mode === 'EDIT'} size={'large'}/>)
          }
        </Form.Item>
        <Form.Item label={'Sort Order'} colon={false}>
          {
            getFieldDecorator('sortOrder', {
              initialValue: module && module.sortOrder,
              rules: [{
                required: true,
                message: 'Please input Sort Order for Module!'
              }]
            })(<InputNumber size={'large'}/>)
          }
        </Form.Item>
        <div>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !=='ADD'}
            loading={loading}
          >
            Add Module
          </Button>
          <Button
            htmlType={'submit'}
            size={'large'}
            type={'default'}
            hidden={mode !== 'EDIT'}
          >
            Update Module
          </Button>
        </div>

        <div className={'btn-grp opposite drawer-footer'} hidden={mode==='ADD'}>
          <Popconfirm
            placement={'topRight'}
            title={'Are you sure you wish to delete this MODULE?'}
            onConfirm={this.confirmDeleteModule}
            okText={'YES! Delete Module'} okType={'danger'}
            cancelText={'NO!'}
          >
            <Button size={'large'} type={'danger'} loading={loading}>Delete Module</Button>
          </Popconfirm>
        </div>
      </Form>
    )
  }
}

export default Form.create()(ModuleAction)