import React, {Component} from 'react';
import {DatePicker, Card, notification} from 'antd';
import moment from 'moment';
import ajax from '../../../../../services/ajax';
import {Bar, Doughnut, Chart} from "react-chartjs-2";
import "chartjs-plugin-datalabels";
import { color } from "../../../../../config";
import {urlLinkPath} from "../../../../../services/urlPath"

const { RangePicker } = DatePicker;

Chart.pluginService.register({
  beforeDraw: function (chart) {
    if (chart.config.options.elements.center) {
      //Get ctx from string
      var ctx = chart.chart.ctx;

      //Get options from the center object in options
      var centerConfig = chart.config.options.elements.center;
      var fontStyle = centerConfig.fontStyle || 'Arial';
      var txt = centerConfig.text;
      var color = centerConfig.color || '#000';
      var sidePadding = centerConfig.sidePadding || 20;
      var sidePaddingCalculated = (sidePadding/100) * (chart.innerRadius * 2)
      //Start with a base font of 30px
      ctx.font = "30px " + fontStyle;

      //Get the width of the string and also the width of the element minus 10 to give it 5px side padding
      var stringWidth = ctx.measureText(txt).width;
      var elementWidth = (chart.innerRadius * 2) - sidePaddingCalculated;

      // Find out how much the font can grow in width.
      var widthRatio = elementWidth / stringWidth;
      var newFontSize = Math.floor(30 * widthRatio);
      var elementHeight = (chart.innerRadius * 2);

      // Pick a new font size so it will not be larger than the height of label.
      var fontSizeToUse = Math.min(newFontSize, elementHeight);

      //Set font settings to draw it correctly.
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      var centerX = ((chart.chartArea.left + chart.chartArea.right) / 2);
      var centerY = ((chart.chartArea.top + chart.chartArea.bottom) / 2);
      ctx.font = fontSizeToUse+"px " + fontStyle;
      ctx.fillStyle = color;

      //Draw text in center
      ctx.fillText(txt, centerX, centerY);
    }
  }
});

const barOptions = {
  responsive: true,
  animation: false,
  tooltips: {
    mode: 'index',
      intersect: false,
  },
  hover: {
    mode: 'nearest',
      intersect: true
  },
  scales: {
    xAxes: [{
      ticks: {
        padding: 12,
        maxRotation: 0,
        minRotation: 0,
      },
      gridLines: {
        color: "#F5F5F5",
        drawBorder: false,
        drawTicks: false
      }
    }],
    yAxes: [{
      type: 'linear', // only linear but allow scale type registration. This allows extensions to exist solely for log scale for instance
      display: true,
      position: 'left',
      ticks: {
        padding: 12,
        precision: 0
      },
      gridLines: {
        color: "#F5F5F5",
        drawBorder: false,
        drawTicks: false
      }

    }],
  },
  plugins: {
    datalabels: false
  }
};


const getBarData = (labels, dataset1, dataset2) => {
  return {
    labels: labels,
    datasets: [{
      label: 'Referral Code',
      backgroundColor: color.primary,
      borderColor: color.primary,
      data: dataset1,
      fill: false,
    }, {
      label: 'Referral Dashboard',
      fill: false,
      backgroundColor: color.accentDark,
      borderColor: color.accentDark,
      data: dataset2,
    }]
  }
};


const getCountBarData = ({labels, data}) => {

  return {
    labels: labels,
    datasets: [{
      backgroundColor: color.primary,
      borderColor: color.primary,
      data: data,
      label: '',
      fill: false,
    }]
  }
};



const getDoughnutData = (data) => {
  return {
    data : {
      datasets: [{
        data: [data.referralCode, data.referralDashboard],
        backgroundColor: [
          color.primary, color.accentDark
        ]
      }],
      labels: [
        'Referral Code', 'Referral Dashboard'
      ]
    },
    options: {
      responsive: true,
      legend: false,
      title: false,
      animation: false,
      elements: {
        center: {
          text: (data.referralCode + data.referralDashboard),
          color: '#36A2EB', //Default black
          fontStyle: 'Roboto, sans-serif', //Default Arial
          sidePadding: 80 //Default 20 (as a percentage)
        }
      },
      plugins: {
        datalabels: {
          backgroundColor: function(context) {
            return context.dataset.backgroundColor;
          },
          borderColor: 'white',
          borderRadius: 25,
          borderWidth: 2,
          color: 'white',
          display: function(context) {
            const dataset = context.dataset;
            return dataset.data[context.dataIndex];
          },
          font: {
            weight: 'bold'
          },
          formatter: Math.round
        }
      }
    }
  }
}

class Referral extends Component {

  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      referralDashboard: [],
      referralCode: [],
      adminReferralCounts: {},
      labels: [],
      counts: {
        referralDashboard: 0,
        referralCode: 0,
      },
      dateRange: [moment().subtract(6,'d'), moment()]
    }
  }


  componentWillMount() {
    this.getReferrals();

    this.ROLE = '';

    try{
      const adminJSON = localStorage.getItem('admin');
      if(adminJSON){
        let admin = JSON.parse(adminJSON);
        const { adminRole } = admin
        this.ROLE = {
          'ROOT': 'Chief Coordinator',
          'ADMIN': 'Chief Coordinator',
          'CHIEF_CO': 'Coordinator',
          'CO': 'Marketing Executive'
        }[adminRole.role];
      }
    }catch (e) {
      console.error(e)
    }
  }

  dateRangeChange = (range) => {
    if(!(range[0] && range[1])){
      range = [moment().subtract(7,'days'), moment()];
    }
    this.setState({
      dateRange: range
    }, this.getReferrals)
  };

  getReferrals = () => {

    const { dateRange } = this.state;

    const currDate = dateRange[0].startOf('day').clone();
    const lastDate = dateRange[1].startOf('day').clone();

    let dates = [];
    let labels = [];
    let dataReferralCode = [];
    let dataReferralDashboard = [];
    let adminReferralCounts = {
      labels: [],
      data: []
    };
    let counts = {
      referralDashboard: 0,
      referralCode: 0,
    }
    do{
      labels.push(currDate.clone().format('DD MMM'));
      dates.push(currDate.clone().format('YYYY-MM-DD'));
      dataReferralCode.push(0);
      dataReferralDashboard.push(0);
    }while(currDate.add(1, 'days').diff(lastDate) <= 0);

    if(labels.length > 31){
      notification.warn({
        message: 'Range Warning',
        description: 'Please select date range less than or equal to 31 days!'
      });
      return;
    }

    const params = {
      startDate: dateRange[0].format('YYYY-MM-DD'),
      endDate: dateRange[1].format('YYYY-MM-DD'),
    };

    ajax('post', urlLinkPath.marketDashboardReferrals, params).then(({result}) => {
      const { referralCode ,referralDashboard, referralCounts } = result;
      for(let i = 0; i < referralCode.length; i++){
        const data = referralCode[i];
        const index = dates.indexOf(data.referralDate);
        if(index !== -1 ){
          dataReferralCode[index] = data.count;
          counts.referralCode += data.count;
        }
      }
      for(let i = 0; i < referralDashboard.length; i++){
        const data = referralDashboard[i];
        const index = dates.indexOf(data.referralDate);
        if(index !== -1 ){
          dataReferralDashboard[index] = data.count;
          counts.referralDashboard += data.count;

        }
      }

      let referralCountLength = referralCounts.length;
      if(referralCountLength > 0){
        for(let i =0 ; i < referralCounts.length; i++){
          const data = referralCounts[i];
          adminReferralCounts.labels.push(`${data.firstName} (${data.referralCode})`)
          adminReferralCounts.data.push(data.count);
        }

        if(referralCounts.length < 10) {
          let padding = 10 - referralCounts.length;
          for(let i =0 ; i < padding; i++){
            adminReferralCounts.labels.push(' ');
            adminReferralCounts.data.push(0);
          }
        }
      }

      this.setState({
        loading: false,
        referralCode: dataReferralCode,
        referralDashboard: dataReferralDashboard,
        adminReferralCounts: adminReferralCounts,
        counts: counts,
        labels: labels,
      })
    }, (err) => {
      notification.error({
        message: 'Error',
        description: err && err.msg
      });
    })
  }

  render() {

    const { dateRange, labels, referralCode, adminReferralCounts, referralDashboard, counts } = this.state;

    const barData = getBarData(labels,referralCode,referralDashboard);

    const dataDoughnut = getDoughnutData(counts);

    const countBarData = getCountBarData(adminReferralCounts);

    return (
      <div className={'txn'} >
        <Card className={'txn-card'} title={'Referrals'} extra={(
          <RangePicker
            value={dateRange}
            format={'DD/MM/YYYY'}
            separator={'to'}
            onChange={this.dateRangeChange}
          />
        )}>

          <div className={'line'}>
            <h2>Referrals Distributions</h2>
            <Bar width={1200} height={420} data={barData} options={barOptions}/>
          </div>
          <div className={'doughnut'}>
            <h2>Total Referrals</h2>
            <Doughnut width={420} height={420} data={dataDoughnut.data} options={dataDoughnut.options}/>
          </div>
          {
            adminReferralCounts && adminReferralCounts.labels && adminReferralCounts.labels.length > 0 && (
              <div className={'bar'}>
                <h2>Referral by {this.ROLE}</h2>
                <Bar width={1200} height={420} data={countBarData} options={barOptions}/>
              </div>
            )
          }
        </Card>
      </div>
    )
  }
}

export default Referral;