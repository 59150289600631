import React, { Component } from "react";
import {
  Form,
  Input,
  Button,
  notification,
  Select,
  Checkbox,
  AutoComplete,
} from "antd";
import ImageUpload from "../../_common/ImageUpload/ImageUpload";
import ajax from "../../../services/ajax";
import { urlLinkPath, urlPath } from "../../../services/urlPath";

class PushNotificationAction extends Component {
  pushPicture = null;
  pushNotification = null;

  constructor(props) {
    super(props);
    this.state = {
      list: [],
      loading: false,
      checked: false,
    };
  }

  componentWillMount() {
    this.getCities();
  }
  getCities = () => {
    ajax("get", urlPath.city).then(
      ({ result }) => {
        this.setState({
          list: result.list,
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          list: [],
          loading: false,
        });
      }
    );
  };

  handleSuccess = (msg) => {
    this.setState({
      loading: false,
    });
    notification.success(msg);
    this.props.updateNotificationList();
  };

  handleError = (msg) => {
    this.setState({
      loading: false,
    });
    notification.error({
      message: "Error",
      description: msg,
    });
  };

  submitForm = (ev) => {
    ev.preventDefault();
    console.log(this.pushPicture);
    this.props.form.validateFields((err, values) => {
      this.pushNotification = values;
      if (err) return;
      this.setState({
        loading: true,
      });

      const city = this.state.list.find((city) => values.city == city.name);
      values.city = city.id;
      if (this.pushPicture) {
        this.pushPicture.set("title", values.title);
        this.pushPicture.set("description", values.description);
        this.pushPicture.set("city", city.id);
        this.pushPicture.set("notificationType", values.notificationType);

        ajax("post", urlPath.pushNotification, this.pushPicture, {}).then(
          (res) => {
            this.handleSuccess({
              message: "Success",
              description: "Notification Sent!",
            });
          },
          ({ msg }) => {
            this.handleError(msg);
          }
        );
      } else {
        ajax("post",urlLinkPath.pushNotificationPlain, values).then(
          (res) => {
            this.handleSuccess({
              message: "Success",
              description: "Notification Sent!",
            });
          },
          ({ msg }) => {
            this.handleError(msg);
          }
        );
      }
    });
  };

  getPushPicture = (formData) => {
    this.pushPicture = formData;
  };

  render() {
    const { loading, checked, list } = this.state;
    const { notification } = this.props;
    const { getFieldDecorator } = this.props.form;

    return (
      <Form onSubmit={this.submitForm}>
        <h2>Push Notification Details</h2>
        <Form.Item label={"Title"} colon={false}>
          {getFieldDecorator("title", {
            initialValue: notification && notification.title,
            rules: [
              {
                required: true,
                message: "Please input title for notification!",
              },
            ],
          })(<Input size={"large"} />)}
        </Form.Item>
        <Form.Item label={"Description"} colon={false}>
          {getFieldDecorator("description", {
            initialValue: notification && notification.description,
            rules: [
              {
                required: true,
                message: "Please input subtitle for notification!",
              },
            ],
          })(<Input.TextArea size={"large"} />)}
        </Form.Item>
        <Form.Item>
          <ImageUpload shareFile={this.getPushPicture} uploadType='BANNER' />
        </Form.Item>
        <Form.Item label={"City"} colon={false}>
          {getFieldDecorator("city", {
            initialValue: notification && notification.city,
          })(
            <AutoComplete
              size={"large"}
              dataSource={list.map((city) => city.name)}
              placeholder="Select a City"
              filterOption={(inputValue, option) =>
                option.props.children
                  .toUpperCase()
                  .indexOf(inputValue.toUpperCase()) !== -1
              }
            />
          )}
        </Form.Item>
        <Form.Item label={"Notification Type"} colon={false}>
          {getFieldDecorator("notificationType", {
            initialValue: notification && notification.notificationType,
            rules: [
              {
                required: true,
                message: "Please select Type!",
              },
            ],
          })(
            <Select size={"large"}>
              <Select.Option value={"NONE"}>NONE</Select.Option>
              <Select.Option value={"BUSINESS"}>BUSINESS</Select.Option>
              <Select.Option value={"CUSTOMER"}>CUSTOMER</Select.Option>
              <Select.Option value={"FTMEMBER"}>FTMEMBER</Select.Option>
              <Select.Option value={"FTPAYUSER"}>FTPAYUSER</Select.Option>
            </Select>
          )}
        </Form.Item>

        <Checkbox
          checked={checked}
          onChange={() => {
            this.setState({ checked: !checked });
          }}
        >
          All data is correct & its ready to show on user's app
        </Checkbox>
        <div className="spacer" />
        <div>
          <Button
            htmlType={"submit"}
            size={"large"}
            type={"default"}
            disabled={!checked}
            loading={loading}
          >
            Send Push Notification
          </Button>
        </div>
      </Form>
    );
  }
}

export default Form.create()(PushNotificationAction);
