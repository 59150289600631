import React, { Component } from "react";
import {
  Button,
  Row,
  Col,
  Divider,
  notification,
  Popconfirm,
  Tag,
  Form,
  InputNumber,
} from "antd";
import DescriptionItem from "../../_common/DescriptionItem";
import moment from "moment";
import ajax from "../../../services/ajax";
import { color, fileBaseURL } from "../../../config";
import { urlPath } from "../../../services/urlPath";

class AdView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleSuccess = (msg) => {
    this.setState({
      loading: false,
    });
    notification.success(msg);
    this.props.updateAdList();
  };

  handleError = (msg) => {
    this.setState({
      loading: false,
    });
    notification.error({
      message: "Error",
      description: msg,
    });
  };

  deleteAd = () => {
    this.setState({
      loading: true,
    });

    ajax("delete", `${urlPath.ad}/${this.props.ad.id}`).then(
      (res) => {
        this.handleSuccess({
          message: "Success",
          description: "Ad deleted!",
        });
      },
      ({ msg }) => {
        this.handleError(msg);
      }
    );
  };
  updateAdHits = (e) => {
    this.props.form.validateFields((err, values) => {
      if (err) return;
      this.setState({
        loading: true,
      });
      values.id = this.props.ad.id;
      ajax("put", urlPath.ad, values).then(
        (res) => {
          this.handleSuccess({
            message: "Success",
            description: "Ad Hits updated!",
          });
        },
        ({ msg }) => {
          this.handleError(msg);
        }
      );
    });


  };

  render() {
    const { loading } = this.state;
    const { ad, mode } = this.props;
    const { getFieldDecorator } = this.props.form;

    const startDate = moment(ad.startDate).unix();
    const endDate = moment(ad.endDate).unix();
    const expiry = moment(ad.expiry).unix();
    const now = moment().unix();

    let deleted = false;
    let tag = "";
    if (now >= startDate && now <= endDate && endDate === expiry) {
      tag = <Tag color={color.primary}>ACTIVE</Tag>;
    } else if (now < startDate && now < endDate && endDate === expiry) {
      tag = <Tag color={color.disabled}>PENDING</Tag>;
    } else if (now > startDate && now > endDate && endDate === expiry) {
      deleted = true;
      tag = <Tag color={color.accentDark}>EXPIRED</Tag>;
    } else if (now > expiry) {
      deleted = true;
      tag = <Tag color={color.danger}>DELETED</Tag>;
    } else {
      tag = <Tag color={color.disabled}>PENDING</Tag>;
    }

    let cityList = [];
    let stateList = [];

    if (ad.adLocations && ad.adLocations.length > 0) {
      for (let i = 0; i < ad.adLocations.length; i++) {
        let loc = ad.adLocations[i];

        if (loc.cityId) {
          cityList.push(loc.city.name);
        }
        if (loc.stateId) {
          stateList.push(loc.state.name);
        }
      }
    }

    return (
      <div className={""}>
        <Row>
          <Col span={24}>
            <div className={"drawer-title"}>
              <h2>Ad Details</h2>
            </div>
          </Col>
        </Row>

        <Divider />
        <div>{tag}</div>
        <Divider />

        <img src={`${fileBaseURL}/${ad.media}`} alt={"image"} />

        <Divider />

        <Row>
          <Col span={24}>
            <DescriptionItem title={"Title"} content={ad.title} />
          </Col>
          <Col span={24}>
            <DescriptionItem title={"Subtitle"} content={ad.subtitle} />
          </Col>
          <Col span={24}>
            <DescriptionItem title={"Info"} content={ad.info} />
          </Col>
          <Col span={24}>
            <DescriptionItem title={"Link"} content={ad.link} />
          </Col>

          {mode == "EDIT" ? (
            <Form.Item label={"View"} colon={false}>
              {getFieldDecorator("hits", {
                initialValue: ad && ad.hits,
                rules: [
                  {
                    required: true,
                    message: "Please input hits for plan!",
                  },
                ],
              })(<InputNumber size={"large"} min={ad.hits} />)}
            </Form.Item>
          ) : (
            <Col span={24}>
              <DescriptionItem title={"Views"} content={ad.hits} />
            </Col>
          )}
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem
              title={"Business"}
              content={ad.business && ad.business.name}
            />
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <DescriptionItem
              title={"Start Date"}
              content={moment(ad.startDate).format("DD-MM-YYYY HH:mm")}
            />
          </Col>
          <Col span={24}>
            <DescriptionItem
              title={"End Date"}
              content={moment(ad.endDate).format("DD-MM-YYYY HH:mm")}
            />
          </Col>
        </Row>
        <Divider />
        <Row>
          <Col span={24}>
            <DescriptionItem title={"States"} content={stateList.toString()} />
          </Col>
          <Col span={24}>
            <DescriptionItem title={"Cities"} content={cityList.toString()} />
          </Col>
        </Row>

        <div className={"btn-grp opposite drawer-footer"} hidden={deleted}>
          {mode == "EDIT" ? (
            <Button size={"large"} loading={loading}  onClick={this.updateAdHits}>
             
              Update
            </Button>
          ) : (
            <></>
          )}
          <Popconfirm
            placement={"topRight"}
            title={"Are you sure you wish to delete this Ad?"}
            onConfirm={this.deleteAd}
            okText={"YES! Delete Ad"}
            okType={"danger"}
            cancelText={"NO!"}
          >
            <Button size={"large"} type={"danger"} loading={loading}>
              Delete
            </Button>
          </Popconfirm>
        </div>
      </div>
    );
  }
}

export default Form.create()(AdView);
