module.exports = {
    USER_TYPE: {
        CUSTOMER: 'CUSTOMER',
        BUSINESS: 'BUSINESS',
        FTMEMBER: 'FTMEMBER',
        FTPAYUSER: 'FTPAYUSER'
    },
    REPORT_NAME: {
        ADPUBLICATION: 'ADPUBLICATION',
        BUSINESS: 'BUSINESS',
        FTMEMBER: 'FTMEMBER',
        FTPAYUSER: 'FTPAYUSER',
        TOPUPS:'TOPUPS',
    },
    REPORT_TYPE: {
        INVOICECLEARANCE: 'INVOICECLEARANCE',
        REVENUEGENERATION: 'REVENUEGENERATION',
       
    },
    CONFIGURATION_KEY:{
        TDS_DEDUCTION: 'TDS_DEDUCTION',
    },
    MAX_FT_HIERARCHY_LEVEL: 8,
    MIN_FT_HIERARCHY_LEVEL: 1,

   
}