export const phoneRules = [{
  required: true,
  message: 'Please input Mobile Number!'
},{
  pattern: /^([789])[0-9]{9}$/,
  message: 'Please enter valid 10 digit Indian Phone Number!'
}];

export const passwordRules = [{
  required: true,
  message: 'Please input Password!'
},{
  min: 8,
  message: 'Password length must be more than 8 characters!'
}];

export const otpRules = [{
  required: true,
  message: 'Please input OTP!'
},{
  min: 6,
  max: 6,
  message: 'OTP length must be 6 characters!'
}];
