import React, { Component } from "react";
import { notification, Button, Table, Tag, Drawer } from "antd";
import moment from "moment";
import InquiryAction from "./InquiryAction";
import ajax from "../../../services/ajax";
import { urlPath } from "../../../services/urlPath";
import { color } from "../../../config";
import { USER_TYPE } from "../../../services/constants";

class InquiriesComponent extends Component {
  constructor(props) {
    super(props);
    this.columns = [
      {
        title: "Name",
        width: 200,
        dataIndex: "name",
        key: "name",
        render: (text) => text,
      },
      {
        title: "Phone",
        width: 100,
        dataIndex: "phone",
        key: "phone",
        render: (text) => text,
      },
      {
        title: "Email Id",
        width: 100,
        dataIndex: "emailId",
        key: "emailId",
        render: (text) => text,
      },
      {
        title: "Status",
        width: 100,
        dataIndex: "status",
        key: "status",
        render: (text) =>
          ({
            PENDING: <Tag color={color.disabled}>PENDING</Tag>,
            COMPLETED: <Tag color={color.success}>COMPLETED</Tag>,
            PROCESSING: <Tag color={color.primary}>PROCESSING</Tag>,
          }[text]),
      },
      {
        title: "Enquiry",
        width: 100,
        dataIndex: "enquiry",
        key: "enquiry",
      },
      {
        title: "Created on",
        dataIndex: "createdAt",
        key: "createdAt",
        width: 200,
        render: (text) => moment(text).format("DD-MM-YYYY"),
      },
      {
        title: "Action",
        key: "operation",
        width: 250,
        render: (text, record) => (
          <Button
            type={"default"}
            disabled={record.status === "COMPLETED"}
            onClick={() => {
              this.viewInquiry(record);
            }}
          >
            Reply
          </Button>
        ),
      },
    ];

    this.state = {
      list: [],
      viewInquiry: false,
      loading: true,
      selectedInquiry: {},
      pagination: {
        current: 1,
        pageSize: 25,
        total: 0,
        onChange: this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`,
      },
    };
  }

  componentWillMount() {
    this.getInquiry();
  }

  viewInquiry = (inquiry) => {
    this.setState({
      viewInquiry: true,
      selectedInquiry: inquiry,
    });
  };
  closeViewMode = () => {
    this.setState({
      viewInquiry: false,
      selectedInquiry: {},
    });
  };

  getInquiry = () => {
    let { pagination } = this.state;
    ajax("get", urlPath.inquiries, {
      page: pagination.current,
    }).then(
      ({ result }) => {
        pagination.total = result.count;
        this.setState({
          viewInquiry: false,
          pagination: pagination,
          list: result.list,
          loading: false,
        });
      },
      ({ msg }) => {
        notification.error({
          message: "Error",
          description: msg,
        });
        this.setState({
          viewUser: false,
          list: [],
          loading: false,
        });
      }
    );
  };

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState(
      {
        pagination: pagination,
        list: [],
      },
      this.getInquiry
    );
  };

  render() {
    const { list, loading, pagination, viewInquiry, selectedInquiry } =
      this.state;

    return (
      <div className={"admin-module"}>
        <div className={"header"}>
          <div className={"page-title"}>Inquiries</div>
        </div>

        <Table
          loading={loading}
          rowKey={"id"}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />

        {viewInquiry && (
          <Drawer
            width={520}
            visible={viewInquiry}
            onClose={this.closeViewMode}
          >
            <InquiryAction inquiry={selectedInquiry} onClose={this.closeViewMode}/>
          </Drawer>
        )}
      </div>
    );
  }
}

export default InquiriesComponent;
