import React, {Component} from 'react';
import {notification, Popconfirm, Button, Table, Drawer} from 'antd';
import ajax from '../../../../services/ajax';

import MasterCategoryAction from './MasterCategoryAction';
import { urlPath } from '../../../../services/urlPath';

class MasterBusinessCategoryComponent extends Component{

  constructor(props) {
    super(props);

    this.columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Action',
        key: 'operation',
        render: (text, record) => (
          <div className={'btn-grp'}>
            <Button type={'default'} onClick={()=> {this.editMasterCategory(record)}}>Edit</Button>

            <Popconfirm
              title={'Are you sure?'}
              okText={'YES'}
              okType={'danger'}
              onConfirm={() => { this.deleteMasterCategory(record)}}
            >
              <Button type={'danger'}>Delete</Button>
            </Popconfirm>
          </div>
        )
      },
    ];

    this.state = {
      list: [],
      loading: true,
      mode: 'ADD',
      selectedCategory: {},
      viewCategory: false,
    }
  }

  componentWillMount() {
    this.getMasterCategoryList();
  }

  getMasterCategoryList = () => {
    ajax('get', urlPath.masterBusinessCategory,{}).then(({list}) => {
      this.setState({
        list: list,
        mode: 'ADD',
        selectedCategory: {},
        viewCategory: false,
        loading: false
      })
    }, (err) => {
      notification.error({
        message: 'Error',
        description: err && err.msg
      });
      this.setState({
        list: [],
        mode: 'ADD',
        selectedCategory: {},
        viewCategory: false,
        loading: false
      })
    })
  }

  deleteMasterCategory = (masterCategory) => {
    ajax('delete', urlPath.masterBusinessCategory,{id: masterCategory.id}).then(() => {
      notification.success({
        message: 'Success',
        description: 'App Version deleted!'
      })
      this.getMasterCategoryList();
    }, (err) => {
      notification.error({
        message: 'Error',
        description: err && err.msg
      });
    })
  }

  addMasterCategory = () => {
    this.setState({
      viewCategory: true,
      selectedCategory: {},
      mode: 'ADD'
    })
  };

  editMasterCategory = (category) => {
    this.setState({
      viewCategory: true,
      mode: 'EDIT',
      selectedCategory: category
    })
  };


  closeAddMode = () => {
    this.setState({
      viewCategory: false,
    })
  };

  render(){

    const {list, loading, viewCategory, selectedCategory, mode} = this.state;

    return(
      <div className={'admin-module'}>
        <div className={'header'}>
          <div className={'page-title'}>Master Business Categories</div>
        </div>

        <div className={'admin-module-body'}>
          <Button type={'primary'} size={'large'} onClick={this.addMasterCategory}>Add Master Business Category</Button>
          <Table
            rowKey={'id'}
            loading={loading}
            columns={this.columns}
            pagination={false}
            dataSource={list}/>
        </div>
        {
          viewCategory && (
            <Drawer
              width={520}
              visible={viewCategory}
              onClose={this.closeAddMode}
            >
              <MasterCategoryAction
                mode={mode}
                category={selectedCategory}
                updateMasterCategoryList={this.getMasterCategoryList}
              />
            </Drawer>
          )
        }
      </div>
    )
  }
}

export default MasterBusinessCategoryComponent;