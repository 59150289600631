import React, {Component} from 'react';
import {notification, Button, Table, Drawer} from 'antd';
import moment from 'moment';
import ajax from '../../../services/ajax';
import PostAction from "./PostAction";
import { urlPath } from '../../../services/urlPath';

class PostComponent extends Component{
  constructor(props){
    super(props);

    this.columns = [
      {
        title: 'Business',
        dataIndex: 'business',
        key: 'business',
        render: (text, {business}) => business?.name??text
      },
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        render: (text) =>  moment(text).format('DD-MM-YYYY')
      },
      {
        title: 'Action',
        key: 'operation',
        render: (text, record) => <Button onClick={() => { this.showPostDetails(record)}} >View</Button>,
      },
    ];

    this.state = {
      list: [],
      viewPost: false,
      loading: true,
      selectedPost: {},
      pagination: {
        current : 1,
        pageSize : 25,
        total : 0,
        onChange : this.changePage,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total}`
      }
    };
  }

  componentWillMount() {
    this.getPosts();
  }

  showPostDetails = (post) => {
    this.setState({
      viewPost: true,
      selectedPost: post
    })
  };

  closeViewMode = () => {
    this.setState({
      viewPost: false,
      selectedPost: {}
    })
  }

  getPosts = () => {
    let { pagination } = this.state;
    ajax('get', urlPath.post,{ page: pagination.current}).then(({result}) => {
      pagination.total = result.count;
      this.setState({
        viewPost: false,
        pagination: pagination,
        list: result.list,
        loading: false
      })
    }, ({msg}) => {
      notification.error({
        message: 'Error',
        description: msg
      });
      this.setState({
        viewPost: false,
        list: [],
        loading: false
      })
    })
  }

  changePage = (page, pageSize) => {
    let pagination = this.state.pagination;
    pagination.current = page;
    this.setState({
      pagination: pagination,
      list: []
    }, this.getPosts)
  };

  render(){

    const {list, loading, pagination, viewPost, selectedPost} = this.state;

    return(
      <div className={'admin-module post'}>
        <div className={'header'}>
          <div className={'page-title'}>Posts</div>
        </div>
        <Table
          rowKey={'id'}
          loading={loading}
          columns={this.columns}
          pagination={pagination}
          dataSource={list}
        />

        {
          viewPost && (
            <Drawer
              width={520}
              visible={viewPost}
              onClose={this.closeViewMode}
            >
              <PostAction post={selectedPost} updatePostList={this.getPosts} />
            </Drawer>
          )
        }
      </div>
    )
  }
}

export default PostComponent;